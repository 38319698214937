@import '../../../../../styles/main';

.history-card {
  flex: 1 0 100%;

  .card {
    display: flex;
    flex: 0 0 calc(50% - 20px);
    align-items: stretch;
    border: 1px solid $color-light-blue-gray;
    border-radius: 8px;
    box-shadow: none;
    margin-bottom: .5em;
    //height: 212px;

    @media #{$tabletDesktop} {
      border-radius: 12px;
      margin-bottom: .75em;
    }

    .history-card-image {
      flex-grow: 0;
      flex-shrink: 0;
      flex-wrap: nowrap;
      width: 100%;
      max-width: unset;

      @media #{$tabletDesktop}{
        max-width: 300px;
      }
      
      img {
        display: none;
        border-radius: 0;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
  
        @media #{$tabletDesktop}{
          display: inherit;
          width: 100%;
          height: auto;
          max-height: 236px;
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
        }
      }
    }
    
    .card-middle {
      flex-direction: column;
      width: auto;
      font-size: 0.875rem;
      padding: 0 20px 20px;
      font-size: 0.875rem;
      margin: 0;

      > div {
        width: auto;
      }

      .top {
        flex-direction: column;
        margin-bottom: 15px;

        .name {
          h3 {
            font-weight: 700;
            margin-bottom: 0;
          }
        }

        .resume-line {

          .dot {
            color: #707a85;
            margin-left: .2em;
            font-size: 1em;
            font-weight: 700;

            @media #{$tabletDesktop}{
              margin-left: .5em;
            }
          }

          .label-text {
            font-size: 0.875rem;
            color: #707a85;
            margin-right: .2em;

            @media #{$tabletDesktop}{
              margin-right: .5em;
            }
          }

          .more {
            cursor: pointer;
            color: $color-primary;
            font-size: 0.875rem;
            font-weight: 400;
            text-decoration: underline;
          }
        }

        .delivered {

          .card-label {
            font-weight: 700;
            margin-right: .5em;
          }

          .card-value {

          }
        }
      }

      .phc-offers {
        ul {
          list-style: none;
          padding-left: 0;
          margin-bottom: 0;

          li {
            display: flex;
            align-items: center;
            font-size: 0.9375rem;
            margin-bottom: .5em;
            line-height: .5em;
          }

          .dot {
            margin-left: 7px;
            margin-bottom: 0;
          }

          .phc-item-quantity {
            border: 1px solid $color-light-blue-gray;
            padding: .6em .7em;
            margin-right: 1.2em;
            line-height: .5rem;
            font-size: 1em;
          }

          .phc-offer-detail {
            display: flex;
            flex-direction: column;
            flex: 1 1 100%;
            line-height: 1.4rem;

            .main {
              margin: 0;
              line-height: 1em;
              font-size: 1em;
            }

            .secondary {
              margin: 0;
              color: $color-battleship-grey;
              font-weight: $font-extra-light;
              font-size: 1em;
              line-height: 1.5em;
            }
          }
        }
      }
    }
  }
}