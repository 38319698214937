@import "../../../styles/main";

.h1-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding: 0.5em;
  & > h1 {
    margin: 0 !important;
    font-weight: $font-semi-bold;
    font-size: large;
    letter-spacing: -.005em;
    
    @media #{$tabletDesktop} {
      font-size: xx-large;
    }
  }
  @media #{$tabletDesktop} {
    //padding: 1em;
    padding: 2.5em 1em 1em;
  }
}

.skeleton-row {
  margin-left: 1.5em;
  max-width: calc(100% - 32px);
  margin: 0 auto;
  
  .restaurants-skeleton {
    &__row {
      .MuiSkeleton-root {
        min-width: 277.5px;
        @media #{$desktop} {
          min-width: unset;
        }
      }
    }
  }
}

.search-wrap {
  display: flex;
  justify-content: center;
  padding: 1em;
}
.address-wrap {
  display: flex;
  justify-content: center;
  padding: 1em 1em 0 1em;
}

.separator {
  border-bottom: 1px solid lightgray;
  width: 100%;
  height: 1px;
}

.restaurant-carousels-list {
  .wrap {
    box-sizing: border-box;
    max-width: 100%;
  }

  .container-fluid {
    padding-right: 0;
    padding-left: 0;
    max-width: calc(100% - 0);
    margin-bottom: 1.25em;
    
    @media #{$tablet} {
      padding-right: 1.125em;
      padding-left: 1.125em;
    }
  }

  .scroll-fluid {
    //padding-right: 1.125em;
    //padding-left: 1.125em;
    padding-right: 0;
    padding-left: 0;
    max-width: calc(100% - 0);
    margin-bottom: 1.25em;
  }

  .not-found-container {
    display: flex;
    justify-content: flex-start;
    column-gap: 1em;

    h2 {
      font-weight: $font-semi-bold;
      color: $color-gunmetal-two;
      margin-bottom: 5px;
    }

    h3 {
      margin-top: 12px;
      @media #{$tabletDesktop} {
        margin-top: 18px;
      }
      color: $color-light-blue-gray;
    }
  }

  .not-found-wrapper {
    padding: 2em 0;
    display: flex;
    justify-content: center;
    align-self: center;
  }

  .default_page_message {
    margin: 1.5em;

    h2{
      color: $color-gunmetal-two;
      text-align: center;
      font-weight: $font-semi-bold;
    }

    span{
      text-align: left;
      color: $color-gunmetal-two;
    }
  }
}

.restaurants-skeleton {
  //max-width: 1200px;
  margin: 0 auto;
  &__header {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
    &__title {
      display: flex;
      flex-direction: column;
    }
    &__subtitle {
      display: flex;
      justify-content: space-between;
      margin-top: 0.5em;
    }
    &__nav {
      display: flex;
      gap: 0.5em;
      span {
        border-radius: 50%;
      }
    }
  }
  &__row {
    display: flex;
    gap: 30px;
    margin-top: 0.5em;
  }
}

.infinite-scroll-component {
  overflow: hidden !important;
  overflow-y: auto;
}

//* Mui extra styles
.MuiSkeleton-wave {
  border-radius: 8px;

  @media #{$tablet} {
    border-radius: 12px;
  }
}
