$gutter: 5px;

// 1-Layout:

// 1.1-Topbar:
$topbar-height: 80px;
$footer-height: 64px;
$menu-height: 42px;

// 2-Form:
$control-height: 39px;
$button-height: 38px;
