@import '../../../../../styles/main';

.resend-order-dialog {
  font-size: 1rem;
  padding: 2em;

  .line {
    border: none;
    border-top: 1px solid #c2d1d9;
    height: 2px;
    width: 100%;
    margin: 1em 0 .5em;
  }

  .rod-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__close {
      position: absolute;
      right: 1em;
      line-height: 0;
      transform: translateX(30%);

      img {
        width: 16px;
      }
    }
  }

  .rod-body {
    margin: 1em;

    .rest-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;

      .nameRestaurant {
        display: flex;
        align-items: center;
        font-size: 18px;
        margin-bottom: 3px;
        font-weight: bold;
      }
    }

    .total-price {
      .h2 {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .bigTotalPrice {
          font-size: 24px;
          line-height: 32px;
          font-weight: $font-bold;
          margin-bottom: 5px;
        }
      }
    }

    .orders-items {
      ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
          font-size: 0.9375rem;
          margin-bottom: 10px;
        }

        li:last-child {
          margin-left: 7px;
          margin-bottom: 0;
        }

        .item-quantity {
          border: 1px solid $color-light-blue-gray;
          padding: 2px 9px;
          margin-right: 10px;
        }
      }
    }
  }

  .rod-footer {
    position: relative;
    display: flex;
    padding: 0 1em 1em 1em;

    button {
      width: 100% !important;
      font-size: 1.5em;

      @media #{$tabletDesktop} {
        font-size: $font-size-base;
      }
    }
  }
}