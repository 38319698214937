@import "../../styles/main";

.Twilio-ChatItem-Avatar > img {
  width: 24px !important;
  height: 24px !important;
}

.Twilio-MainContainer {
  z-index: 200 !important;
}

.Twilio-EntryPoint {
  z-index: 200 !important;
}

.MessageCanvasTrayContent > {
  margin: 0;
  padding: 10px;
}

.MessageCanvasTrayContent > h6 {
  padding-left: 10px;
  padding-right: 10px;
}

.MessageCanvasTrayContent > p {
  margin: 0 !important;
  padding-left: 10px;
  padding-right: 10px;
}

.MessageCanvasTrayContent > a {
  margin: 0;
  padding: 10px 10px 20px 10px;
  color: white;
}

.MessageCanvasTrayContent > a:visited {
  color: white;
}

.MessageCanvasTrayContent > a:hover {
  color: white;
}

.MessageCanvasTrayContent > a:active {
  color: white;
}

.Twilio-EntryPoint-Tagline {
  text-transform: capitalize !important;
}

.Twilio-DynamicForm {
  &:first-child {
    font-size: 15px !important;
  }
  label {
    font-size: 13px !important;
  }
  input {
    font-size: 14px;
  }
} 