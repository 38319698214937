%dropdown--opened {
  opacity: 1;
  height: auto;
}

.dropdown {

  &__switch {
    visibility: hidden;
    opacity: 0;
    display: none;
  }

  &__switch:checked ~ &__body,
  &--open ~ &__body {
    @extend %dropdown--opened;
  }

  &__header {
    position: relative; // (1)
    z-index: depth(1); // (1)
    display: block;
    @include hover;
  }

  &__body {
    opacity: 0; // DF
    height: 0; // DF
    margin: 0;
    overflow: hidden;
  }
}

// 1: TRICK, Small trick, in items below (maybe because of the scroll),
// it was a bit buggy to reveal an item.
