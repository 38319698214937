@import "../../../styles/main";

$max-width: 300px;
$max-width-2: $max-width * 1.5;
$height: 45px;
$padding-li: 1em * 2; // 1.5em is the padding of all <li> in Topbar

.bu {
  -webkit-transition: flex-grow 500ms;
  -moz-transition: flex-grow 500ms;
  transition: flex-grow 500ms;
}

.bt {
  max-width: unset;
  @media #{$tabletDesktop} {
    max-width: $max-width-2;
  }
}

.bs {
  -webkit-box-flex: 0;
  flex-grow: 0;
}

.af {
  position: relative;
}

.d8 {
  -webkit-box-flex: 1;
  flex-grow: 1;
}

.button-search {
  display: flex;
  justify-content: left;
  align-items: center;
  border: none;
  max-width: unset;
  @media #{$tabletDesktop} {
    max-width: $max-width;
  }

  background: white;
  @media #{$tabletDesktop} {
    background: transparent;
  }

  width: 100%;
  padding: 0.35em 0.5em;
  letter-spacing: normal !important;

  img {
    margin-right: o;
    @media #{$tabletDesktop} {
      margin-right: 5px;
    }
  }

  span {
    display: none;
    @media #{$tabletDesktop} {
      display: inherit;
    }
  }

  div {
    background: transparent;

    flex-grow: 1;

    text-overflow: ellipsis;
    line-height: 24px;
    text-transform: none !important;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
  }
}

.separator {
  border-bottom: 1px solid lightgray;
  width: 100%;
  height: 1px;
}

.business-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.6em 0.6em;

  &__name {
    font-size: small;
    font-weight: bolder;
    padding-top: 0.6em;
    line-height: 1;
  }
  &__avatar {
    border-radius: 50%;
    overflow: hidden;
    height: 65px;
    width: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 100%;
      width: auto;
    }
  }
}

.business-detail-item {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  gap: .6em;
  margin: .6em 0;

  &__header {
    display: flex;
    border-radius: 5px;

    &:hover {
      background-color: #f6f6f6 !important;
      cursor: pointer !important;
    }
  }

  &__name {
    display: flex;
    font-weight: bold;
    line-height: 1.4;
    font-size: 1.3em;
  }

  &__avatar {
    border-radius: 50%;
    overflow: hidden;
    height: 55px;
    width: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 100%;
      width: auto;
    }
  }

  &__title-div {
    display: flex;
  }

  &__title {
    margin-left: 1em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__more {
    display: flex;


    &__item {
      display: flex;
      gap: 0.3em;
      margin-right: 1em;
      &__text {
        font-size: 1em !important;
        padding-left: 5px;
      }
      // &::after {
      //   content: "~";
      // }
    }
  }

}

.general-placeholder {
  color: #8F8F8F;
  margin: 2em 0;
}

.item-offer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;

  &__image {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 0.5em;
  }
  &__name {
    font-weight: bold;
    font-size: 1.1em;
    line-height: 1;
    text-align: left;
  }
  &__price {
    color: #565656;
    font-size: small;
    &::before {
      content: '$';
    }
  }
}

.swipper-actions {
  display: none;
}
@media #{$desktop} {
  .swipper-actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 0;
    transform: translateY(-40px);

    button {
      width: 38px;
      height: 38px;
    }
  }
}

