.label {
    &-icon {
        display: flex;

        >*:first-child {
            margin-right: 5px;
        }
    }
    &-error {
        font-size: .8em;
        line-height: 1.8;
        font-style: italic;
        margin: .1em .5em;
        color: red;
    }
    &-success {
        font-size: .8em;
        line-height: 1.8;
        font-style: italic;
        margin: .1em .5em;
        color: green;
    }
    &-info {
        font-size: .8em;
        line-height: 1.8;
        font-style: italic;
        margin: .1em .5em;
        color: blue;
    }
}