@import "../../../styles/main";

.toasts-container {
  position: fixed;
  overflow: hidden;
  z-index: 999999999999;
  max-height: calc(100vh - 10px);
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .toast-distribution {
    max-width: 300px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  
}
