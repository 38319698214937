/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/

.select--editable {
    &__container {}

    &__control {
        color: $color-gunmetal;
        border: 1px solid $color-light-blue-gray  !important;
        background-color: $color-white-two !important;
        border-radius: 0 !important;
        min-height: $control-height !important;
    }

    &__input {
        input {
            height: 18px !important;
        }
    }

    &__value-container {
        padding: 0px 7px !important;
    }

    &__indicators {}

    &__menu {
        &-list {
            text-align: left;
        }
    }

    &__option {}
}