%button {
  max-width: 300px;
  display: inline-block;
  padding: $gutter $gutter-2;
  text-transform: uppercase;
  text-align: center;
  height: $button-height;

  cursor: pointer;
}

button,
.button {
  @extend %button;

  // &--block { @include block; }

  &--short {
    width: auto;
    padding: $gutter-1-2 $gutter-3-2;
    text-transform: capitalize;
  }
}

.button-group {
  @include flex(flex, center, center);
  padding: $gutter;

  .button,
  button {
    &:first-child:last-child {
      margin-right: 0;
      margin-left: auto;
    }
  }

  &--double {
    justify-content: space-between;
  }

  button,
  .button {
    margin-right: $gutter-1-2;
  }

  .button + .button,
  button + button {
    margin-right: 0;
    margin-left: $gutter-1-2;
  }
}

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase;
}

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-active;
  color: $color-white-two;
  border: 1px solid $color-active;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;

  background: $color-primary;
  border-color: $color-primary;

  &.MuiSvgIcon-root {
    fill: $color-white-two !important;
  }

  &:hover,
  &:focus {
    background: $color-primary-dk;
    border-color: $color-primary-dk;
  }

  &:disabled {
    background: lightgrey;
    border-color: lightgrey;
  }

  &-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-primary;
    color: $color-white-two;
    border: 1px solid $color-primary;
    border-radius: 4px;
    padding: 5px 10px;

    color: $color-primary;
    background: $color-white-two;
    border: 1px solid $color-primary;

    > svg {
      fill: $color-primary;
    }

    &:hover,
    &:focus {
      color: $color-white-two;
      background: $color-primary-dk;
      border-color: $color-primary-dk;

      > svg {
        fill: $color-white-two !important;
      }
    }

    &-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: $color-white-two;
      color: $color-primary;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px;

      img {
        fill: $color-primary;
      }

      &:hover,
      &:focus {
        background: $color-primary-dk;
        color: $color-white-two;

        img {
          fill: $color-white-two;
        }
      }

      span {
        padding: 0 0.3em;
      }

      @media #{$tabletDesktop} {
        width: auto;
      }
    }
  }
}
.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-black;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important;
  
  &--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important;
  }

  &:hover,
  &:focus {
    color: $color-white-two !important;
    background-color: $color-primary !important;
  }
}

.button--primary--blue {
  background: $color-primary;
  border-color: $color-primary;

  &:disabled {
    background: lightgrey;
    border-color: lightgrey;
  }
}

.button-like.button--primary {
  background: $color-primary;
  border-color: $color-primary;
  color: $color-white !important;

  &:hover,
  &:focus {
    background: $color-black;
    border-color: $color-black;
  }
}

.button--outline {
  background: transparent;
  color: $color-gunmetal;
  border-radius: 4px;
  border: 1px solid $color-gunmetal;
  text-transform: capitalize !important;

  &:hover,
  &:focus {
    background: $color-back-blue;
  }
}

.button--transparent {
  background: transparent;
  color: $color-gunmetal;
  border: none;
  text-transform: capitalize !important;

  &:hover,
  &:focus {
    background: $color-back-blue;
    img {
      color: $color-black;
    }
  }
}

.button--round {
  border-radius: 50%;
}

.button--large {
  height: 52px;
}

.button--medium {
  height: 39px;
}

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px;
}

.button--max {
  width: 100%;
  max-width: unset;
}

.max-w-260 {
  max-width: 260px;
}

.max-w-380 {
  max-width: 380px;
}

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px;
}
