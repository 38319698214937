@import "../../../../../styles/main";


#cuisine-section {
  color: $color-black;
  margin: 0;
  @media #{$tabletDesktop} {
    margin: 2.5em auto 0;
  }
  padding: 0 1em !important;
}

.avatar {
  border-radius: 50%;
  overflow: hidden;
}

.cuisine-skeleton {
  &__row {
    display: flex;
  }
  &__column {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    padding: 0 40px 0 40px;
    & > :first-child {
      border-radius: 50%;
      margin: 1em 0;
    }
  }
}

.cuisine-type {
  color: $color-black;

  &__header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__close {
      margin-right: 1em;
      position: absolute;
      right: 1em;
      line-height: 0;
      transform: translateX(30%);

      img {
        width: 16px;
      }
    }
  }

  &__search-info {
    text-align: left;
    font-size: 18px;
    line-height: 20px;
    padding: 16px 4px 4px 4px;
    font-weight: 600;

    p {
      margin: 0;
    }
  }

  &__body {
    /*padding: 1em 0 !important;
    @media #{$tabletDesktop} {
      padding: 1em 0 2.15em !important;
    }
    margin: 0 !important;
    display: flex;
    width: auto;
    overflow-x: auto;
    @media #{$tabletDesktop} {
      overflow-x: hidden;
      &:hover {
        overflow-x: auto;
        padding: 1em 0 1em !important;
      }
    }*/
    position: relative;
    width: 100%;
    display: flex;
    background-color: white;
    overflow-X: auto;

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-self: center;
      padding: 1.5em;
      cursor: pointer;
      // width: 130px !important;
      height: auto;
      @media #{$tablet} {
        width: 11em;
        height: auto;
      }
      @media #{$desktop} {
        width: 11em;
        height: auto;
      }

      &--selected {
        //background: rgba(0, 0, 0, 0.1);
        background: rgba(194, 209, 217, 0.2);
        border: 5px solid white;
        border-radius: 8px;

        @media #{$tablet} {
          border-radius: 12px;
        }
      }

      .crop-image {
        width: 70px;
        height: 40px;
        position: relative;
        overflow: hidden;
        margin-bottom: 1em;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: auto;
          height: 100%;
        }
      }

      span {
        white-space: nowrap;
        text-align: center;
        font-size: 14px;
        font-weight: $font-medium;
        line-height: 16px;
        // padding-left: 8px;
      }
    }
  }
}

.swiper-wrapper {
  //padding-left: 10px;
  .swiper-slide {
    position: relative;
    width: 82px;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
}

.swiper-container {
  gap: 0;
  width: 100%;
  height: auto;
  overflow: hidden;

  .swiper-button-prev,
  .swiper-button-next {
    border: none;
    border-radius: 50%;

    position: absolute;
    top: 50%;
    width: 35px;
    height: 35px;
    margin-top: -22px !important;
    z-index: 10;
    cursor: pointer;
    background-size: 50px 50px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    display: none;
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 0;
    right: auto;
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 0;
    left: auto;
  }

  .swiper-button-prev.swiper-button-white,
  .swiper-container-rtl .swiper-button-next.swiper-button-white {
  }

  .swiper-button-next.swiper-button-white,
  .swiper-container-rtl .swiper-button-prev.swiper-button-white {
  }

  .swiper-button-prev.swiper-button-black,
  .swiper-container-rtl .swiper-button-next.swiper-button-black {
  }

  .swiper-button-next.swiper-button-black,
  .swiper-container-rtl .swiper-button-prev.swiper-button-black {
  }

  .swiper-button-lock {
    display: none;
  }

  // .swiper-pagination {
  //   position: absolute;
  //   text-align: center;
  //   transition: 300ms opacity;
  //   transform: translate3d(0, 0, 0);
  //   z-index: 10;

  //   &.swiper-pagination-hidden {
  //     opacity: 0;
  //   }
  // }
}
