@import "../../../styles/main";

.address {
  min-height: 80vh;
  font-size: 1rem;
  padding: 0 1em;

  @media #{$tabletDesktop} {
    padding: 0 1em;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 1em 0;

    button {
      font-size: .9em;
      font-weight: 500;
      text-transform: none;
      border: 1px solid $color-primary;
      box-shadow: none;
      padding: 5px 15px;

      > svg {
        fill: $color-primary !important;
        margin-right: 0;

        @media #{$tabletDesktop} {
          margin-right: 7px;
        }
      }

      &:hover,
      &:focus {
        > svg {
          fill: $color-white-two !important;
        }
      }

      > span {
        display: none;
        font-size: 1em;
        margin-right: 3px;

        @media #{$tabletDesktop} {
          display: inline-block;
          font-size: 1.125em;
        }
      }
    }
  }

  &-data {
    li {
      display: grid;
      grid-template-columns: 1fr 48px 48px;
      //box-shadow: 0 5pt 15pt rgba(0, 0, 0, 0.1);
      background: $color-white-two;
      grid-template-columns: 1fr 48px 48px;
      border: 1px solid $color-light-blue-gray;
      border-radius: 8px;
      margin-bottom: 1em;
      padding: 1em;

      @media #{$tabletDesktop} {
        border-radius: 12px;
        padding: 1em 1em 1em 1.5em;
      }

      & > * {
        &:first-child {
          grid-row: 1/1;
          grid-column: 1/1;
          text-align: left;
          font-size: .9em;
          color: $color-battleship-grey;
        }

        &:nth-child(2) {
          grid-row: 2/2;
          grid-column: 1/1;
          text-align: left;
          font-size: 1em;
          color: #2a2a2a;
        }

        &:nth-child(3) {
          grid-row: 1/3;
          grid-column: 2/2;
          align-self: center;
          justify-self: center;
        }

        &:nth-child(4) {
          grid-row: 1/3;
          grid-column: 3/3;
          align-self: center;
          justify-self: center;
        }
      }
      
      svg {
        &.MuiSvgIcon-root {
          fill: $color-primary !important;
        }
      }
    }
  }
}

.address-section {
  padding: 2em 0 !important;

  @media #{$tabletDesktop} {
    padding: 2em 1em !important;
  }
}

.address-form {
  box-shadow: none !important;
  border: 1px solid $color-light-blue-gray;
  border-radius: 8px !important;
  margin: 0 1em;

  @media #{$tabletDesktop} {
    border-radius: 12px !important;
  }

  @media #{$desktop} {
    max-width: 50%;
    margin: 2em auto;
  }

  &-header {
    //padding: 1em 0.8em;
    position: relative;
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid $color-light-blue-gray;

    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1em;

    @media #{$tabletDesktop} {
      padding: 1em 4em;
    }

    h3 {
      max-width: 80%;
    }

    &__close {
      position: absolute;
      height: 44px;
      width: 44px;
      top: 1em;
      right: 1.15em;
      line-height: 0;
      transform: translateX(30%);

      img {
        width: 16px;
      }
    }

    @media #{$tabletDesktop} {
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  &-body {
    padding: 0;

    @media #{$tabletDesktop} {
      padding: 1em 2.5em;
    }
  }
}

.MuiOutlinedInput-root fieldset {
  border-radius: 5px !important;
}

.makeStyles-root-36 {
  margin-bottom: 1em !important;
}

.Connect\(FormAddAddress\)-caption-16 {
  margin-left: 0;
}
