// NEW COLORS
$color-light-navy-blue: #304582 !default;
$color-cherry-red: #e50914 !default;
$color-boring-green: #2c2c2c !default;
$color-fern-green: #428d44 !default;
$color-metallic-blue: #476184 !default;
$color-dark-grey-blue: #34465d !default;
$color-blue-berry: #533478 !default;
$color-violet: #693478 !default;
$color-coral: #ff4949 !default;
$color-green-blue: #00d091 !default;
$color-of-white: #f1f9f0 !default;
$color-very-light-pink: #f8f2ed !default;

// Homogenize Colors
$color-black: #1d1d1f !default; //#2a2a2a
$color-darkest-grey: #313133 !default;
$color-dark-grey: #565656 !default;
$color-primary: #1e4acc !default; 
$color-primary-dk: #0D35AB !default;

// OLD COLORS THAT ARE STILL IN USE
$color-gunmetal: $color-black !default;
$color-battleship-grey: #707a85 !default;
$color-light-gray-blue: #a1aeb7 !default;
$color-light-blue-gray: #c2d1d9 !default;
$color-very-light-blue: #e5eaed !default;
$color-light-gray: #efefef !default;

$color-dead-black: #000000 !default;
$color-white: #f8f8f8 !default;
$color-white-two: #ffffff !default;

// MATERIAL COLORS
$color-material-violet: #693478;

// THEME COLORS
$color-active: $color-black;

// BLACK & WHITE:

$color-gunmetal-two: $color-black !default;

$color-back-blue: #f2f5f7 !default;
$color-sick-green: #9abe29 !default;

// GREYS:
$colors-greys: (
  "low": #f5f5ed,
  "mid": #c6c5c0,
  "high": #999e98,
) !default;

// FUNCTIONS to access colors:
@function color-grey($key) {
  @if (map-has-key($colors-greys, $key)) {
    @return map_get($colors-greys, $key);
  } @else {
    @error 'Color `grey` '+$key+' not found.';
  }
}
