@import "../../../styles/main";

.customer-satisfaction {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;

  .cs-form {
    margin: 1em;

    @media #{$desktop} {
      margin: 2em 20em;
    }

    .cs-restaurant {
      background: $color-white-two;
      border-radius: 3px;
      margin-bottom: 1em;

      .cs-image-box {
        height: 12em;

        .cs-image-box-background {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-size: cover;
          overflow: hidden;
        }

        .image-caption {
          background: rgba(255, 255, 255, 0.8);
          padding: 10px;
          display: flex;
          flex-direction: column;
          max-width: 70%;
          min-width: 60%;
          margin: auto;
        }
      }

      .cs-restaurant-info {
        padding: 1em 0;
        display: flex;
        justify-content: left;
        text-align: left;

        @media #{$tabletDesktop}{
          padding: 1em 1em 1em 3em;
        }

        .cs-title {
          font-family: inherit;
          font-size: 18px;
          font-weight: 700 !important;
          line-height: 1em;
          color: #3e3e3e;
          margin: 0.5em 0;
          width: 100%;
        }

        .cs-label span {
          margin: .2em 0;
        }
      }
    }

    .cs-form-subtitle {
      font-size: 1.4em;
      color: $color-gunmetal;
      font-weight: $font-bold;
      padding: 1em 0;
      border-bottom: 1px solid $color-light-gray-blue;
    }

    .cs-review {
      text-align: center;
      margin: 1em 0;

      .cs-score-label {
        margin: 1em 0 0 0;
        color: $color-active;
        font-weight: $font-bold;
      }

      .cs-score {
        align-items: center;
        margin: 0 0 1em 0;
        color: $color-active;

        .rate {
          text-align: center;

          .icons {
            width: 1.2em;
            height: 1.2em;

            @media #{$tabletDesktop} {
              width: 1.8em;
              height: 1.8em;
            }
          }
        }

        .cs-tag {
          margin: 1em 0;
          color: $color-active;
          height: 2em;

          .arrow-left {
            width: 0;
            height: 0;
            border-top: 1.6em solid transparent;
            border-bottom: 1.6em solid transparent;
            border-right: 1.6em solid $color-active;

            @media #{$tabletDesktop}{
              width: 0;
              height: 0;
              border-top: 1.2em solid transparent;
              border-bottom: 1.2em solid transparent;
              border-right: 1.2em solid $color-active;
            }
          }

          .arrow-content {
            justify-content: center;
            text-align: center;
            width: 7em;
            height: 2.8em;
            display: flex;
            align-items: center;
            padding: .2em;
            background: $color-active;
            color: $color-white-two;

            @media #{$tabletDesktop} {
              width: 12em;
              height: 2em;
            }
          }
        }
      }

      .cs-input-detail {
        margin: 1em 0;
        width: 100% !important;
      }
    }

    .cs-message {

    }
  }

  .cs-register-message {

    .cs-container {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      max-width: 1366px;
      padding: 4rem 20px 20px;
      margin: 0 auto;
    }

    @media (max-width: 767px) {
      .account-container {
        padding: 4.5rem 10px 10px;
      }
    }

    .fixed-block {
      flex: 0 0 auto;
    }

    .expanded-block {
      flex: 1 1 auto;
    }

    .basic-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      left: auto;
      right: auto;
      height: auto;

      text-align: center;
      border: 1px solid #d3dce6;
      background: white;

      box-shadow: none;
      padding: 1.85rem;
      margin: 0;
    }

    .basic-card.small {
      max-width: 320px;
      width: auto;
    }

    .basic-card.medium {
      max-width: 540px;
      width: auto;
    }

    .basic-card.large {
      max-width: 750px;
      width: auto;
    }

    .basic-card img.card-icon {
      align-self: center;
      width: 80px;
      height: auto;
      max-height: 80px;

      margin: 1rem 0;
      padding: 0;
    }

    .basic-card h4 {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.5rem;
      margin-top: 0;
      margin-bottom: 12px;
    }

    .basic-card p {
      font-size: 0.9375rem;
      font-weight: 400;
      line-height: 1.125rem;
      margin: 0;
    }

    .basic-card .button--primary {
      min-width: 142px;
    }

    .basic-card .resume {
      font-size: 0.85rem; /* 15px; */
      padding-top: 10px;
    }

    .basic-card .resume table {
      width: 100%;
    }

    .basic-card .resume tr {
      vertical-align: top;
    }

    .basic-card .resume tr td {
      text-align: left;
    }

    .basic-card .resume tr td:first-child {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      color: #707a85;
      padding-right: 20px;
    }

    .basic-card .resume tr td:last-child {
      text-align: right;
    }

    .d-flex {
      display: flex;
    }

    .flex-column {
      flex-direction: column;
    }

    .justfy-content-center {
      justify-content: center;
    }

    .mt-50 {
      margin-top: 50px;
    }

    .mb-20 {
      margin-bottom: 20px;
    }

   /* margin: 1em;
    display: flex;
    text-align: center;
    line-height: 3em;

    @media #{$desktop}{
      margin: 5em 20em;
    }

    .message {
      display: flex;
      padding: 1em;
      font-weight: $font-bold;
      color: $color-gunmetal;
      font-size: $font-size-twice;
    }

    .action {
      display: block;
      margin-top: 3em;
      text-align: center;

      button {
        padding: .5em 2.5em;
      }
    }*/
  }
}