@import "../../styles/main.scss";

.group-address-controls {
  display: flex;

  .button--plus {
    width: 48px;
    height: 48px;
    margin-left: 5px;
  }
}

.modal-select__container {
  padding-top: 5px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  
  height: 440px;

  .group-address-controls {
    max-width: unset !important;
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: stretch;
    padding: 0 2em;
    height: 100%;

    .searchbar {
      flex: 1;
    }

    button {
      height: 49px;
    }
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
}

//-------------------------- DELIVERY ADDRESS SELECT -----------------------------------//
.address-select {
  &__custom {
    border-bottom: 1px solid $color-light-blue-gray;

    &_option_header {
      border-bottom: 1px solid $color-light-blue-gray;
      padding: 0.5em 1.5em;
      align-items: center;

      span {
        margin: 0 !important;
        font-weight: $font-semi-bold;
        color: $color-gunmetal-two;
      }

      a {
        float: right;
        font-weight: $font-semi-bold;
        color: $color-light-blue-gray;
      }

      a:hover,
      a:focus {
        cursor: pointer;
      }
    }

    &_option_body {
      border: 1px solid $color-gunmetal;
      margin: 0.5em 1.5em;
      text-align: center;

      a {
        display: block;
        padding: 0.35em 1.5em;
        text-decoration: none;
        color: $color-gunmetal;
      }

      a:hover,
      a:focus {
        background: $color-active;
        color: $color-white-two;
        cursor: pointer;
      }
    }

    &_option_body:hover,
    &_option_body:focus {
      border: 1px solid $color-active;
      background: $color-active;
    }

    &_form_header {
      border-bottom: 1px solid $color-light-blue-gray;
      padding: 0.5em 1.5em;
      display: flex;
      align-items: center;

      span {
        margin: 0 !important;
        font-weight: $font-semi-bold;
        color: $color-gunmetal-two;
      }

      img {
        border: 1px solid $color-light-blue-gray;
        border-radius: 0.3em;
        padding: 0.5em;
        margin-right: 0.8em;
      }

      img:hover,
      img:focus {
        cursor: pointer;
        background: color-grey(low);
      }
    }

    &_form_body {
      margin: 0.5em 1.5em;
      display: flex;
      flex-direction: row;

      input {
        margin: 0 !important;
        flex-grow: 2;
        border-right: none !important;
        border-bottom-left-radius: 0.3em !important;
        border-top-left-radius: 0.3em !important;
      }

      input:focus {
        outline: none;
      }

      button {
        height: 2.7em;
        border: 1px solid $color-light-blue-gray;
        border-top-right-radius: 0.3em;
        border-bottom-right-radius: 0.3em;
        background: $color-white-two;

        img {
          padding: 0 !important;
        }
      }
    }

    &_distance {
      display: flex;
      align-items: center;
      text-align: center;

      span {
        padding: 0.5em 1.5em;
        font-weight: $font-semi-bold;
        color: $color-gunmetal-two;
      }
    }

    &_form_footer {
      margin: 1em 13em;
      text-align: center;

      a {
        text-transform: none !important;
        display: block;
        padding: 0.35em 1.5em;
        text-decoration: none;
        background-color: $color-gunmetal;
        color: $color-white;
      }

      a:hover,
      a:focus {
        cursor: pointer;
      }
    }
  }

  &_items {
    // border-bottom: 1px solid $color-light-blue-gray;
    padding: 1em;
    &:hover {
      cursor: pointer;
      background-color: #f6f6f6;
    }

    &_content {
      padding: 0 0 0 0.5em !important;

      .info-name {
        color: $color-gunmetal-two;
        font-weight: $font-regular;
        margin: 0 0.3em;
        font-size: 16px;
      }

      .info-address {
        margin: 0 0.3em;
        display: flex;
        flex-direction: row;

        span {
          margin: 0 !important;
          flex-grow: 2;
          color: $color-light-gray-blue;
          text-align: left;
        }

        input {
          width: 1.2em;
          height: 1.2em;
          margin: 0.5em 0.5em;
          padding: 0.5em 1em;
        }
      }
    }

    &_actions {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

//-------------------------- DELIVERY ADDRESS DIALOG FORM -----------------------------------//
.delivery-address-dialog {
  font-size: 1rem;
  padding: 2em;

  &__header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &.MuiDialogTitle-root {
      padding: 1.5em 2em 1.5em 2em !important;
    }

    &__close {
      position: absolute;
      right: 2em;
      line-height: 0;
      transform: translateX(30%);

      img {
        width: 16px;
      }
    }
  }

  &__body {
    padding: 0.5em !important;
    margin: 0 !important;
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .button--primary,
    .button--outline {
      margin: auto;
      padding: 1em 4em;
    }
  }
}

.MuiInputLabel-outlined {
  background: white;
}

.date_verification_dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1rem;

  .verification_dialog_header {
    width: 20em;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .verification_dialog_body {
    width: 20em;
    font-size: 1rem;
  }
}

.verification_dialog_header {
  width: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .MuiTypography-h6 {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.35;
  }
}

.verification_dialog_body {
  display: flex;
  justify-content: center;
  padding: 0 2em !important;

  h3 {
    font-weight: 400;
  }
}

.verification_dialog_actions {
  padding: 1.5em 2em !important;

  button {
    width: calc(50% - 10px);
    margin: 0 auto;
  }
}

.MuiDialogTitle-root {
  padding: 1.5em 2em 0 2em !important;
}

.MuiDialogActions-root {
  justify-content: center !important;
  padding: 8px 2em 2em 2em;
}
