@import '../../../styles/main';

.form_container {
  flex-grow: 1;
  font-size: .8em;
  padding: 2em 1.5em;

  @media #{$tabletDesktop} {
    padding: 1em !important;
  }

  &__body {
    overflow: visible !important;

    &__fields {
      display: block !important;
      padding: .5em 0;

      @media #{$tabletDesktop} {
        display: block !important;
        padding: .5em;
      }

      button {
        width: 100%;
      }

      .margin-left {
        margin-left: .3em;
      }

      .margin-right {
        margin-right: .3em;
      }

      > div:first-child {
        margin-bottom: 1em !important;
      }
    }
  }

  .form_actions {
    position: relative;
    display: flex;
    padding: 2.8em 0 1.5em 0;

    @media #{$tabletDesktop} {
      padding: 2.8em .5em 1.5em .5em;
    }

    button {
      width: 100% !important;
      max-width: none;
      font-size: 1.2em;

      @media #{$tabletDesktop} {
        font-size: $font-size-base;
      }
    }

    .button_align_left {
      padding-left: .6em;
    }

    .button_align_right {
      padding-right: .6em;
    }

    .button_align_center {
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
}