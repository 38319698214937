@import "../../../styles/main";

.purchase {
  min-height: 80vh;
  font-size: 1rem;

  .page-section {
    padding: 1em 0;

    .wrap {
      box-sizing: border-box;
      max-width: 1200px;
      margin: 0 auto;
    }

    .container-fluid {
      padding-right: 1em;
      padding-left: 1em;

      @media #{$desktop}{
        padding-right: 0;
        padding-left: 0;
      }
    }

    .history-header {

      .divider-green {
        width: 32px;
        margin: 0.5em 0;
        border-bottom: 2px solid $color-primary;
      }

      h2, .h2 {
        font-size: 28px;
        font-weight: bold;
        line-height: 38px;
      }

      h2.headline {
        text-align: center;
        margin-bottom: 40px;
      }

      h2.headline.left {
        text-align: left;
        margin-bottom: 25px;
      }

      p {
        margin-bottom: .5em;
      }
    }

    .history-body {
      padding: 1em 0;
    }
  }

  .not-found-container {
    display: flex;
    justify-content: flex-start;
    column-gap: 1em;

    h2 {
      font-weight: $font-semi-bold;
      color: $color-gunmetal-two;
      margin-bottom: 5px;
    }

    h3 {
      margin-top: 12px;
      @media #{$tabletDesktop} {
        margin-top: 18px;
      }
      color: $color-light-blue-gray;
    }
  }

  .not-found-wrapper {
    padding: 2em 0;
    display: flex;
    justify-content: center;
    align-self: center;
  }
}

.infinite-scroll-component {
  overflow: hidden !important;
  overflow-y: auto;
}