@import "../../../styles/main";

.scrollable_list {
  border: none;
  margin-bottom: 1em;
}

.cart {
  font-size: 1rem;
  padding: 2em 2em 1em;

  &__header {
    padding: 1em 1.5em;

    &__title {
      font-size: 22px;
      font-weight: $font-extra-bold;
    }

    &__close {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px !important;
      width: 35px !important;
    }

    .MuiSvgIcon-root {
      font-size: 1.35rem;
    }
  }

  &__list {
    //margin: 3% 5% 1% 7%;
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;

    border: 1px solid transparent;
  }

  &__total {
    display: flex;
    flex-direction: row;
    margin: 2em .4em 1.5em .6em;
    margin-left: 70px;

    .cart-item-column {
      width: 100%;
    }

  }
  .subtotal > span:last-child, .total {
    
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      vertical-align: text-bottom;
    }

    &__info {
      display: grid;
      grid-template-columns: min-content 1fr min-content;
      width: 100%;

      .cart-item-actions {
        display: flex;
        align-items: center;

        .spinner-grid {
          font-weight: $font-medium;
          scale: 90%;

          span {
            min-width: 20px;
          }

          @media #{$tabletDesktop} {
            scale: 100%;

            span {
              min-width: 20px;
            }
          }
        }
      }

      .cart-item-column {
        display: flex;
        align-items: center;

        margin-left: 0;
        padding: 0 .5em;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .title {
          word-break: break-all;
          width: 100%;
        }

        .variant {
          display: flex;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 0.8em;
          font-weight: $font-extra-light;
          color: $color-gunmetal;
        }

        .details {
          display: flex;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 0.8em;
          font-weight: $font-extra-light;
          color: $color-light-gray-blue;
        }
      }

      .cart__item__price {
        display: flex;
        justify-content: flex-end;
      }
    }

    &__price {
      .price {
        display: flex;
        align-items: center;
        margin: 0 0.4em;
      }
    }
  }

  .cart-action {
    justify-content: center;
    text-align: center;
    display: flex;

    button {
      font-size: 1.125em;
      text-transform: none;
      min-height: 52px;
      background: $color-primary;
      padding: 12px 32px !important;
      &:hover,
      &:focus {
        background: $color-primary-dk;
      }
    }

    a {
      width: 70%;
      justify-content: center;
      text-align: center;
      display: flex;

      @media #{$tabletDesktop} {
        width: 60%;
      }
    }

    span {
      margin: 0 .2em;
    }
  }

  .subtotal,
  .cost {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    span:first-child {
      font-size: 1em;
    }
  }

  .total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: $font-bold;
    margin-bottom: 1em;
  }

  .subtotal,
  .cost, .total {
    height: 39.39px;
  }

  .spacer {
    margin: 1em 0;
  }

  .button--primary {
    position: relative;

    .total {
      position: absolute;
      right: 10px;
      transform: translateY(-50%);
      top: 50%;
      color: #ffffff;
      font-size: 1.2em;
    }
  }

  .button--primary,
  .button--outline {
    margin: 0.4em 0;
  }
}

.car {
  position: relative;

  &_section {
    //background: $color-white;
    background: rgba(194, 209, 217, 0.1);
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
    height: 100vh;

    //* Oculta todo el sidebar
    display: none !important;

    @media #{$desktop} {
      //display: block;
    }

    .delivery-switch {
      background: rgb(239, 239, 239);
      font-size: 0.8em;
      color: $color-light-blue-gray;
      display: flex;
      justify-content: center;
      padding: 3.5%;

      div {

        &> :first-child,
        &> :last-child {
          margin: 0 5px;
        }
      }

      .active-label {
        color: $color-black;
      }
    }

    h3 {
      font-weight: $font-bold;
      color: $color-black;
      text-align: center;
      margin: 0.7em 0;
    }

    .cart {
      padding: 0;

      &__list {
        padding: 3% 0;
        max-height: calc(100vh - 350px);
      }

      .spacer {
        margin: 0.5em 0;
      }
    }

    .cart__item__info>* {
      margin-right: 0;
    }
  }
}