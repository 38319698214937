@import "../tokens/variables.scss";

html {
  display: block;
  @include share(width, height, 100%);
  overflow: auto;
}

html,
body {
  height: 100%;
  @include nunito;
}

body {
  @include share(width, max-width, height, max-height, 100%);
  @include share(margin, padding, 0);
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.app,
.app__inner {
  height: 100%;
}

#root {
  height: 100vh;
  //width: calc(100vw - 3px);
  width: 100vw;
  overflow: hidden;
  overflow-y: auto; // overlay
  background: white;
}

.full-height {
  min-height: calc(100vh - #{$topbar-height} - #{$footer-height});
}

#main {
  @extend .full-height;

  @media #{$desktop} {
    box-sizing: border-box;
    //max-width: 1366px;
    max-width: 1920px;
    margin: 0 auto;
  }
}

body {
  font-size: $font-size-base;
}

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;

  /*@media #{$tabletDesktop} {
    width: 90%;
  }*/

  &--half {
    text-align: center;
    width: 50%;
  }

  &--half-2 {
    text-align: center;
    width: 60%;
  }

  &.med-container {
    width: 1140px;
  }

  &.small-container {
    width: 750px;
  }
}

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: $color-boring-green !important;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }
}

.link-more {
  text-decoration: underline;
  padding-right: 0.5em;
}

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600;
  .underline {
    background: $color-primary;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0;
  }
}

.section {
  font-size: 0.7rem;
  @media #{$desktop} {
    font-size: 0.8rem;
  }
  color: $color-gunmetal-two;

  padding: 2em 2em;
  // @media #{$tabletDesktop} {
  //   padding: 3em 3.8em 7em;
  // }

  // background: $color-white-two;

  &-divider {
    border-top: 1px solid $color-light-blue-gray;
    width: 100%;
    height: 1px;
  }

  h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left;
  }
}

h1,
h2,
h3,
h4 {
  .divider {
    width: 50px;
    margin: 0.5em auto;
    border-bottom: 2px solid $color-active;
    &--left {
      margin-left: 0;
    }
  }
}
