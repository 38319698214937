@import "tokens/tokens";
@import "page/page";
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap");
@import "tokens/fonts.scss";

* {
  @include nunito;
  max-width: 100vw;
  //letter-spacing: .75px;
  letter-spacing: 0;
}

ul,
ol {
  list-style: none;
  @include share(margin, padding, 0);
}

a:visited,
a:focus {
  color: inherit;
}

*:focus {
  outline: 0;
}

.icon {
  @include circle(2em);

  &--mini {
    @include circle(1em);
  }

  &--big {
    @extend .icon--mini;
    @include circle(5em);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-light;
}

.capitalize {
  text-transform: capitalize;
}
