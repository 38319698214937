.menu {
  width: 100%;
  
  &__item {
    @include flex(flex, center, center);
    height: $menu-height;
    margin-bottom: $gutter;
    
    & > a {
      text-decoration: none;
    }
  }
}