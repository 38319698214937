$depths: (
  1: 100,
  2: 200,
  3: 300,
  4: 400,
  5: 500,
  6: 600,
  7: 700,
  8: 800,
  9: 900,
);

@function depth($index) {
  @if (map-has-key($depths, $index)) {
    @return map_get($depths, $index);  
  } @else {
    @error 'Depth ' + $index + ' not found.';
  }
}