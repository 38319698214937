@import '../../../styles/main';

.cart_dialog {
  // font-size: 1rem;
  padding: 0 !important;
  margin: 0 !important;

  &__header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .mobile-edit-purchase {
      position: absolute;
      left: 3%;
      top: 20%;
      width: 2.5em;
      height: 2.2em;
      padding-top: .6em;
      display: block;
      align-items: center;
      text-align: center;
      color: $color-white-two;
      background: $color-active;
    }

    &__title {
      cursor: pointer;
    }

    &__close {
      position: absolute;
      right: 5%;
      line-height: 0;
      transform: translateX(30%);
      height: 35px;

      img {
        width: 16px;
      }
    }
  }

  .button--primary,
  .button--outline {
    margin: .4em 0;
  }
}

.MuiDialog-container {
  .MuiDialog-paper {
    padding: 0 !important;
    margin: 0 !important;
  }
}