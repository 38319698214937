// Various shortcuts, attribute's mode:

// FLEX:
[f-mid-mid] {
  @include flex(flex, center, center);
}

[f-start-mid] {
  @include flex(flex, start, center);
}

[f-start-end] {
  @include flex(flex, start, end);
}

// MARGIN / PADDING:
@mixin margins-paddings-to-zero() {
  @include share(margin, padding, 0);
}

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0;
}

[margin-bottom-zero] {
  margin-bottom: 0;
}

// COLORS:
[color-white] {
  color: white;
}

[color-black] {
  color: black;
}

[color-inherit] {
  color: inherit;
}

// STATES:
[hover] {
  &:hover {
    cursor: pointer;
  }
}

// TEXT:

// Text align:
[text-center] {
  text-align: center;
}

[text-left] {
  text-align: left;
}

[text-right] {
  text-align: right;
}

// Text transform:
[text-capitalize] {
  text-transform: capitalize;
}

[text-uppercase] {
  text-transform: uppercase;
}

[text-lowercase] {
  text-transform: lowercase;
}

// POSITIONS:

// Position types:
[relative] {
  position: relative;
}

[absolute] {
  position: absolute;
}

// Offsets:
[top] {
  top: 0;
}

[right] {
  right: 0;
}

[bottom] {
  bottom: 0;
}

[left] {
  left: 0;
}

.grid {
  &--two-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &--three-columns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &--four-columns {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  &--five-columns {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }

  &--six-columns {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }

  &--seven-columns {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }

  &--eight-columns {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
  }

  &--nine-columns {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
  }

  &--ten-columns {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
  }

  &--eleven-columns {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
  }

  &--twelve-columns {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }

  &--thirdteen-columns {
    display: grid;
    grid-template-columns: repeat(13, 1fr);
  }

  &--fourteen-columns {
    display: grid;
    grid-template-columns: repeat(14, 1fr);
  }

  &--sidebar {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 1em;
    @media #{$desktop} {
      grid-template-columns: 67% 33%;
    }
  }

  &--small-form {
    display: grid;
    grid-template-columns: 65% 30%;
  }

  &--form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.8em;

    .colspan-2 {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}

.margin-auto {
  margin: auto;
}

.display-block {
  display: block !important;
}

.center-all {
  justify-self: center;
  align-self: center;
}

.text-center {
  text-align: center !important;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
