@import "../../styles/main.scss";

$zindex-base: 500;
$height: 45px;

.tab-container {
  margin: 1em 0;
  width: 70%;
}

.autosuggest-container {
  display: flex;
  width: 100%;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: $zindex-base + 2;
  margin-left: auto;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, white 50%, white 100%);
  padding: 4em 0 0.5em 0;

  
  & > div {
    flex-grow: 1;
    max-width: unset;
    & > input {
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
      background-color: #f6f6f6 !important;
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #5d5d5d !important;
        opacity: 1; /* Firefox */
      }
    }
  }
  & > button {
    border: none;
    border-bottom: 1px solid #f6f6f6;
    background-color: #f6f6f6 !important;
    height: $height;
    display: flex;
    align-items: center;
    justify-content: center;
    &:last-child {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
}

.modal-select__container {
  padding-top: 5px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  
  height: 440px;

  .group-address-controls {
    max-width: unset !important;
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: stretch;
    padding: 0 2em;
    height: 100%;

    .searchbar {
      flex: 1;
    }

    button {
      height: 49px;
    }
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
}

.MuiInputBase-input {
  padding: 0 25px;
}

.MuiInputLabel-outlined {
  background: white;
}

.MuiDialogTitle-root {
  padding: 1.5em 2em 0 2em !important;
}

.MuiDialogActions-root {
  justify-content: center !important;
  padding: 8px 2em 2em 2em;
}

.skeleton-row {
  margin: 0 !important;
  max-width: calc(100% - 32px);
  display: flex;
  justify-content: flex-start;
  
  .bc-skeleton {
    margin-right: 1em;
    &__avatar {
      margin: 5px 0;
    }
    &__title {}
    &__row {
      .MuiSkeleton-root {
        min-width: 277.5px;
        @media #{$desktop} {
          min-width: unset;
        }
      }
    }
  }

  &__business {
    display: flex;
    flex-direction: column;

    
    .bc-skeleton {
      &__business {
        flex-direction: column;
        display: flex;
        align-items: flex-start;
        border-bottom: 1px solid lightgray;
        margin: 0 !important;
        padding: 1em 0;
        
        &__header {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        
        &__offers {
          display: flex;
          gap: 10px;
          flex-direction: row;
          justify-content: flex-start;
          margin-top: 1em;
        }
      }
    }
  }
}

.swiper-wrapper {
  padding-left: 10px;

  .swiper-slide {
    position: relative;
    width: 82px;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
}

.swiper-container {
  gap: 0;
  width: 100%;
  height: auto;
  overflow: hidden;

  .swiper-button-prev,
  .swiper-button-next {
    border: none;
    border-radius: 50%;

    position: absolute;
    top: 50%;
    width: 35px;
    height: 35px;
    margin-top: -22px !important;
    z-index: 10;
    cursor: pointer;
    background-size: 50px 50px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    display: none;
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 0;
    right: auto;
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 0;
    left: auto;
  }

  .swiper-button-prev.swiper-button-white,
  .swiper-container-rtl .swiper-button-next.swiper-button-white {
  }

  .swiper-button-next.swiper-button-white,
  .swiper-container-rtl .swiper-button-prev.swiper-button-white {
  }

  .swiper-button-prev.swiper-button-black,
  .swiper-container-rtl .swiper-button-next.swiper-button-black {
  }

  .swiper-button-next.swiper-button-black,
  .swiper-container-rtl .swiper-button-prev.swiper-button-black {
  }

  .swiper-button-lock {
    display: none;
  }

  // .swiper-pagination {
  //   position: absolute;
  //   text-align: center;
  //   transition: 300ms opacity;
  //   transform: translate3d(0, 0, 0);
  //   z-index: 10;

  //   &.swiper-pagination-hidden {
  //     opacity: 0;
  //   }
  // }
}

.swiper-wrapper {
  padding-left: 0;
} 

.swiper-slide {
  border-radius: 5px;
  padding: 1em;
  &:hover {
    background-color: #f6f6f6 !important;
    cursor: pointer !important;
  }
}

.searchUberModal {
  
  .MuiOutlinedInput-input {
    padding-left: 2em;  
    
    &::placeholder {
      color: black !important;
    }
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 6px;
  }
  
  .mydialog__wrapper {
    @media #{$onlyMobile} {
      height: 100%;
    }
    .mydialog__dialog {
      @media #{$onlyMobile} {
        height: 100%;
      }
      &__content__caption {
        padding: 0;
      }
    }
  }
}
