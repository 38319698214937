@import "../../styles/main.scss";

.searchbar {
  div {
    button {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}

.list {
  padding: 1.3em 0 2em;
  overflow: hidden;
  overflow-y: auto;

  &__bottom {
    height: 37px;
    width: 100%;
    background: #F2F2F2;
    background: linear-gradient(to bottom, transparent, #F2F2F2);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .searchbar {
    flex: 1;
  }

  &_items {
    border-bottom: 1px solid lightgray;
    &:last-of-type {
      border-bottom: none;
    }

    &__container:hover {
      background-color: #F2F2F2;
    }
    &_content {
      padding: 1em 0 1em 2em;
      cursor: pointer;
      .field1 {
        font-size: medium;
        font-weight: bold;
      }
      .field2 {
      }
    }
    &_actions {
      cursor: pointer;
      padding: 1em 2em 1em 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}