// Font sizes:
$line-height: 1.5 !default;
$font-size: 16px !default;

$font-size-base: 0.9rem; // Meaning 16px;
$font-size-half: 1.5rem;
$font-size-twice: 2rem;

// Font weights:
$font-thin: 100 !default;
$font-extra-light: 200 !default;
$font-light: 300 !default;
$font-regular: 400 !default;
$font-medium: 500 !default;
$font-semi-bold: 600 !default;
$font-bold: 700 !default;
$font-extra-bold: 800 !default;
$font-black: 900 !default;

$font-weights: (
  "thin": $font-thin,
  "extra-light": $font-extra-light,
  "light": $font-light,
  "regular": $font-regular,
  "medium": $font-medium,
  "semi-bold": $font-semi-bold,
  "bold": $font-bold,
  "extra-bold": $font-extra-bold,
  "black": $font-black,
) !default;

$font-nunito: "San Francisco" !default;

[font-nunito],
.font-nunito {
  font-family: $font-nunito;
}

@mixin nunito {
  font-family: $font-nunito;
}
