@import "../../../styles/main";

$li-min-width: 130px;

.topbar,
.topbar-container {
  height: $topbar-height - 20;
  @media #{$desktop} {
    height: $topbar-height;
  }

  header {
    background-color: #fff;
    // border-bottom: 1px solid #c2d1d9;
    box-sizing: border-box;
    margin: 0 auto;
    position: relative;
    right: 0;
    left: 0;

    @media #{$desktop} {
      .container {
        box-sizing: border-box;
        //max-width: 1366px;
        max-width: 1920px;
        margin: 0 auto;
      }
    }
  }
}

.impersonation-block {
  background: #FF424D;
  color: white;
  font-size: medium;
  font-weight: 500;
  line-height: 2;
  display: flex;
  justify-content: flex-end;
  padding-right: .5em;
}

.topbar {
  position: relative;
  // top: 0;
  left: 0;
  right: 0;
  background: $color-white-two;
  font-size: 0.75rem;
  // border-bottom: 1px solid $color-light-blue-gray;
  z-index: depth(2);

  &--fixed {
    position: fixed;
    box-shadow: inset 0 1px #fff, 0 1px 3px rgba(34, 25, 25, 0.4);
    -moz-box-shadow: inset 0 1px #fff, 0 1px 3px rgba(34, 25, 25, 0.4);
    -webkit-box-shadow: inset 0 1px #fff, 0 1px 3px rgba(34, 25, 25, 0.4);
  }

  &-placeholder {
    height: 0;
    width: 100%;

    &--fixed {
      height: $topbar-height - 20;
      @media #{$desktop} {
        height: $topbar-height;
      }
    }
  }

  .cart-total {
    display: none;
    @media #{$tabletDesktop} {
      display: inline;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
    width: calc(100% - 1em);
    padding: 0 0.5em;

    @media #{$tabletDesktop} {
      width: 100%;
    }

    /*
    @media (max-width: 1199px) {
      width: 100%;
    }
    @media #{$onlyMobile} {
      width: calc(100% - 1em);
      padding: 0 0.5em;
    }
    */
  }

  &__logo-block {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    // to hide part of the icon
    width: 38px;
    padding-left: 0 !important;
    overflow: hidden;

    @media #{$tabletDesktop} {
      flex-grow: unset;
      padding: 0 2em;
      margin: 0 0 0 2em;
      width: auto;
    }

    @media #{$desktop} {
      margin: 0 0 0 1em;
    }

    img {
      margin: auto .5em;
    }

    @media #{$tabletDesktop} {
      margin: auto;
    }
  }

  &__navigation {
    flex-grow: 1;
    height: 100%;

    ul {
      height: 100%;
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
      align-items: center;

      .wide-link {
        min-width: $li-min-width;
      }

      li {
        width: fit-content;
        padding: 0.35em 0.8em;
        @media #{$tabletDesktop} {
          padding: 0.35em 1.5em;
        }

        a {
          margin: 0;
          @media #{$tabletDesktop} {
            margin: 0 2em;
          }
        }

        p,
        span {
          font-size: 16px !important;
          text-transform: none;
        }

        p {
          color: $color-active !important;
        }

        span {
          &.bullet {
            padding: 0 7px;
            color: $color-black;
            &__white {
              color: $color-white-two;
            }
          }
        }

        .MuiInputBase-input {
          height: 33.38px;
          min-width: $li-min-width;
          & > div:last-child > div {
            padding: 4px !important;
          }
        }
      }
    }
  }

  &__btn-link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 400;
    text-transform: none !important;
    line-height: 1.5;
    height: 42px;
    color: $color-white-two;
    background: $color-primary;
    border: 1px solid $color-primary !important;
    border-radius: 4px;
    margin: 0 1em !important;
    &:hover,
    &:focus {
      background: $color-primary-dk;
      border-color: $color-primary-dk !important;
    }

    span {
      color: $color-white-two;
    }

    a {
      padding: auto 1em;
      text-decoration: none;
      color: $color-white-two;
      &:hover,
      &:focus {
        background: $color-primary-dk !important;
      }
    }

    &--active {
      color: $color-primary;
      border: 1px solid $color-primary;
      cursor: pointer;

      &:hover,
      &:focus {
        background: $color-primary-dk;
        color: $color-white;
      }
    }
  }

  &__auth-btn {
    border: 1px solid $color-gunmetal;
    padding: 0 !important;

    a {
      text-transform: none !important;
      display: block;
      padding: 0.35em 1.5em;
      text-decoration: none;
      color: $color-black;
    }

    a:hover,
    a:focus {
      background: color-grey(low);
    }
  }

  &__flat-link {
    border: none;
    // border-right: 1px solid $color-light-blue-gray;
    // border-left: 1px solid $color-light-blue-gray;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    line-height: 2.5;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 0 !important;

    .menu-wrapper {
      height: 100%;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background: transparent;
      color: $color-black;
      height: 100%;

      padding: 0.35em 0.5em;
      @media #{$tabletDesktop} {
        padding: 0.35em 1.5em;
      }

      vertical-align: middle;

      &.btn-rounded {
        &--black {
          height: 38px;
          min-width: 65px;
          border-radius: 18px;
          padding: 8px 16px;
          margin: 0 1em;
          background: $color-black;
          color: $color-white-two;

          &:hover,
          &:focus {
            background: rgba(29, 29, 31, 0.91);
          }
        }

        &--primary {
          height: 38px;
          min-width: 65px;
          border-radius: 18px;
          padding: 8px 16px;
          margin: 0 1em;
          background: $color-primary;
          color: $color-white-two;

          &:hover,
          &:focus {
            background: $color-primary-dk;
          }
        }

        @media #{$tabletDesktop} {
          margin: 0 1.25em;
        }

        @media #{$desktop} {
          margin: 0 0 0 1.25em;
        }
      }

      span {
        align-self: center;
        padding-left: 0.5em;
        font-size: 1.1em;
        // @media (max-width: 768px) {
        //     display: none;
        // }
      }
    }

    &.cart-full {
      button {
        // background: $color-active;
        display: flex;

        span {
          // color: $color-white;
          align-self: center;
        }
      }
      @media #{$onlyMobile} {
        display: none;
      }
    }

    a {
      color: $color-black;
      text-decoration: none;
      text-transform: uppercase;

      padding: 0.35em 0.5em;
      @media #{$tabletDesktop} {
        padding: 0.35em 1.5em;
      }
    }

    &:hover,
    &:focus {
      cursor: pointer;
      // background: color-grey(low);
    }
  }

  &__nav-link {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 8%;
    padding: 0 !important;
    margin: 0 !important;

    color: $color-black;
    text-decoration: none;
    text-transform: uppercase;
    border-bottom: 3px solid transparent;
    cursor: pointer;

    a {
      margin-top: 2px;
      text-decoration: none;
      color: $color-black;
    }

    &:hover,
    &:focus {
      border: none;
      border-bottom: 3px solid $color-light-gray-blue;
    }

    &--active {
      border-bottom: 3px solid $color-active !important;
    }
  }

  .langPicker {
    height: 100%;

    .MuiSelect-root {
      height: 100%;
      display: flex;
      padding: 0 !important;
    }
  }
}

.nav-container {
  &:first-child {
    flex-grow: 1;
    li.logo-li {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-right: 37px !important;
    }
  }
}
.nav-mobile {
  flex-direction: column;
  & > li {
    display: flex;
    justify-content: center;
    width: 100% !important;
    max-width: unset !important;
    padding: 0.5em !important;

    & > * {
      flex-grow: 1 !important;
    }
  }
}

.header_btn {
  display: block;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  transition: all 0.2s linear;
  border-radius: 5px;
  background: transparent;
  border: 2px solid white;
  color: white;
  text-align: center;
}

.MuiListItemIcon-root {
  min-width: 25px !important;
}

.MuiMenuItem-root {
  width: auto;
  overflow: hidden;
  font-size: 0.8em;
  box-sizing: border-box;
  min-height: 36px;
  font-weight: 400;
  line-height: 1;
  padding-top: 4px;
  padding-bottom: 4px;
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {
  .logo {
    display: block;
    cursor: pointer;
    padding: 0.5rem 1rem;
  }

  .header_navigation {
    width: 100%;
    margin-top: 1rem;
  }

  .header_navigation ul {
    display: none;
  }

  .header_navigation ul.show {
    display: block;
  }

  .header_navigation ul li {
    display: block;
  }

  .header_navigation ul li a {
    display: block;
    padding: 0.5rem 0;
    transition: all 0.4s linear;
    border-radius: 5px;
  }

  .header_navigation ul li a:hover {
    background: #4a4a4a;
  }
}

.drawable-logo {
  padding: 0.5em 0px 1em;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .close-btn {
    cursor: pointer;
  }
}

.Component-list-18,
.Component-list-19 {
  padding: 1em 0 0 !important;
  a {
    &:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 42px;
      color: $color-white-two;
      background: $color-primary !important;
      border: none !important;
      border-radius: 21px;
      margin: 0 1em 1em;

      &:hover {
        background: $color-primary-dk !important;
      }
    }
  }

  .MuiListItem-button:hover + span {
    color: $color-primary;
  }

  .MuiListItem-button:focus {
    background-color: $color-primary-dk !important;
    opacity: 1;
  }
}

