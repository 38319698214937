@import "../../../styles/main";

.field-group-2 {
  color: $color-battleship-grey;
  text-align: left;
  margin: 1em 0;

  display: grid;
  grid-template-columns: 32px 1fr;

  .MuiInput-underline:before {
    border-bottom: none !important;
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }

  .label {
    grid-column: 2 / 2;
    grid-row: 1 / 1;
  }

  .front-icon {
    grid-column: 1 / 1;
    grid-row: 2 / 2;
    transform: translateY(20%);
  }

  input,
  .MuiTextField-root {
    grid-column: 2 / 2;
    grid-row: 2 / 2;
  }

  .label,
  .hint {
    font-size: 0.8em;
    line-height: 1.8;
    font-style: italic;

    &_error {
      color: red;
    }
  }

  .hint_alert {
    font-size: 0.8em;
    line-height: 1.8;
    font-style: italic;

    &_success {
      color: $color-sick-green;
    }

    &_error {
      color: red;
    }
  }
}

.small {
  .MuiFormControlLabel-label {
    font-size: 1em;
    text-align: left;
  }
}

.register,
.register > .section {
  background: $color-very-light-pink !important;
  padding: 2em 1em !important;
  display: flex;
  justify-content: center;
}

.register {
  font-size: 1rem;
  display: flex;
  justify-content: center;

  .container {
    max-width: 750px;
    padding: 5em 0;
    @media #{$desktop} {
      padding: 5em 3em;
    }

    .note {
      box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.1);
      width: 100%;
      background: $color-violet;
      color: $color-white-two;
      padding: 0 0.5em;
      margin-bottom: 1em;
      display: grid;
      grid-template-columns: 10% 80% 10%;
      align-items: center;
      border-radius: 0;
      @media #{$desktop} {
        grid-template-columns: 5% 90% 5%;
      }

      p {
        font-size: $font-size-base;
        font-weight: $font-extra-light;
        text-align: center;
        @media #{$desktop} {
          text-align: left;
        }
      }

      button {
        background: transparent;
        border: none;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .resend_code_form {
      box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.1);
      width: 100%;
      background: $color-white-two;
      padding: 2em 0 1em 0;
      margin: 1em 0;
      text-align: center;
      border-radius: 0;

      @media #{$desktop} {
        padding: 2em 0;
      }

      .resend_code_title {
        font-size: $font-size-base;
        font-weight: $font-bold;
        margin-bottom: 1em;
      }

      .resend_code {
        text-decoration: none;
        color: $color-active;
        font-size: $font-size-base;
        padding: 0.3em 1.5em 1em 1.5em;

        a {
          text-decoration: none;
        }

        @media #{$desktop} {
          padding: 0;
        }
      }

      .resend_code:hover {
        cursor: pointer;
      }
    }

    .form {
      box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.1);
      width: 100%;
      background: $color-white-two;
      padding: 2em 0;
      text-align: center;
      border-radius: 0;

      @media #{$desktop} {
        padding: 2em 0;
      }

      .title {
        font-weight: $font-bold;
      }

      .subtitle {
        font-size: $font-size-base;
        padding: 0.3em 2em 1em;
      }

      .fields {
        display: flex;
        justify-items: center;
        justify-content: center;
        padding: 1em 1.5em;

        .extra {
          display: grid;
          grid-template-columns: 40% 60%;
          align-items: center;
          font-size: 0.8em;
          margin: 0;
          padding: 0;

          &:first-child {
            text-align: left;
          }

          &:last-child {
            text-align: right;
            margin-right: 1em;
          }

          span {
            font-size: 1em;
          }
        }

        .action {
          //padding: 1.5em .6em 1.5em 1.1em;
        }

        .register-link {
          display: flex;
          justify-items: center;
          justify-content: center;
          align-items: center;
          font-size: 0.8em;

          p {
            font-size: 1em;
            padding-right: 1em;
          }
        }
      }
    }
  }

  &__header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-size: 1.7em;
      text-align: center !important;
      font-weight: $font-semi-bold;
      margin-bottom: 0.5em !important;
    }
  }

  &-data {
    background: $color-white-two;
    width: 100%;
    max-width: 30%;
    min-width: 300px;
    padding: 2em 4em;
    @media (max-width: 500px) {
      min-width: 100%;
      max-width: 100%;
    }
    -webkit-box-shadow: 0 5pt 15pt rgba(0, 0, 0, 0.1);
    box-shadow: 0 5pt 15pt rgba(0, 0, 0, 0.1);

    &__info {
      color: $color-black;
      font-size: 1.3em;
      text-align: center;

      a {
        color: $color-boring-green;
      }
    }
  }

  .form-container {
    display: flex;
    flex-direction: column;
    padding: 1em 0;
    @media #{$tabletDesktop} {
      padding: 2em 0;
    }
    width: 100%;

    & > * {
      flex-basis: 60px;
    }
  }
}

.MuiFormHelperText-root {
  font-size: 0.7rem !important;
  margin-top: 3px !important;
  min-height: 1em !important;
  text-align: right !important;
}

.MuiFormHelperText-contained {
  margin: 3px 14px 0 !important;
}

.ReactPasswordStrength-strength-desc {
  color: #2c2c2c;
  font-size: 0.7em;
  font-weight: 400;
  text-transform: none;
}
