@import '../../../../../styles/main';

.purchase-history-dialog {

  .phd-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: rgb(85, 28, 166);
    //background: rgba(72, 83, 182, 1);
    //background: -moz-linear-gradient(150deg, rgba(85, 28, 166, 1) 0%, rgba(72, 83, 182, 1) 100%);
    //background: -webkit-linear-gradient(150deg, rgba(85, 28, 166, 1) 0%, rgba(72, 83, 182, 1) 100%);
    //background: linear-gradient(150deg, rgba(85, 28, 166, 1) 0%, rgba(72, 83, 182, 1) 100%);
    //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#551ca6", endColorstr="#4853b6", GradientType=1);

    .phd-logo {
      left: 1em;
      display: flex;

      img {
        fill: $color-white-two !important;
      }

      .phd-close {
        background: transparent;
        border: none;
        position: absolute;
        top: 1em;
        right: .8em;
        transition: all 200ms;
        opacity: 100%;

        svg {
          fill: $color-white-two !important;
          opacity: 35%;

          &:hover,
          &:focus {
            opacity: 100%;
          }
        }

        img {
          width: 16px;
        }
      }
    }

    .phd-title {
      color: $color-white-two;
      padding: 1em 0 .5em;
      font-size: 1.3em;
      font-weight: $font-bold;
      line-height: 1.5em;
    }
  }

  .phd-order-type-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to bottom right, rgba(85, 28, 166, 1) 50%, rgba(72, 83, 182, 1) 0%, white 50%);

    img {
      width: 6em;
    }
  }

  .phd-body {

    .phd-rest-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1.5em;

      h3 {
        display: flex;
        align-items: center;
        font-size: 1.5em;
        margin-bottom: .2em;
        font-weight: $font-bold;
      }

      p {
        margin: 0 0 .5em;
        font-weight: $font-bold;
      }
    }

    .phd-total-price {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      font-size: 1.5em;
      line-height: 2em;
      font-weight: $font-bold;
      margin-bottom: .5em;
    }

    .phd-offers {
      ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
          display: flex;
          align-items: center;
          font-size: 0.9375rem;
          margin-bottom: 0;
          line-height: 1em;
        }

        .phd-offer-quantity {
          border: 1px solid $color-light-blue-gray;
          padding: .4em .7em;
          margin-right: .5em;
          line-height: 1rem;
          font-size: 1em;
        }

        .phd-offer-detail {
          display: flex;
          flex-direction: column;
          flex: 1 1 100%;
          line-height: 1em;

          .main {
            margin: 0 0 0 .35em;
            line-height: 1em;
            font-size: 1em;
          }

          .secondary {
            margin: 0;
            color: $color-battleship-grey;
            font-weight: $font-extra-light;
            font-size: .9em;
            line-height: 1.5em;
          }

          .tertiary {
            margin: 0;
            color: $color-light-gray-blue;
            font-weight: $font-extra-light;
            font-size: .9em;
            line-height: 1.5em;
          }
        }

        .phd-offer-price {
          text-align: end;
          width: 12em;
        }
      }
    }

    .phd-cost-resume {
      .item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        p {
          align-items: center;
          margin: 0;
          line-height: 2em;
        }
      }
    }

    .phd-pay-method {
      padding: .5em 0;

      .light{
        margin-left: .5em;
      }
    }
  }

  .phd-footer {
    display: flex;
    align-items: center;
    padding-bottom: 2.5em;

    button {
      min-width: 168px;
    }
  }

  .strong {
    font-weight: $font-bold;
  }

  .light {
    font-weight: $font-light;
  }

  .phd-divider-white {
    width: 32px;
    margin: 0.5em 0;
    border-bottom: 2px solid $color-white-two;
  }

  .phd-divider-green {
    width: 32px;
    margin: 0.5em 0;
    border-bottom: 2px solid $color-primary;
  }

  .phd-line {
    border: none;
    border-top: 1px solid #c2d1d9;
    height: 2px;
    width: 100%;
    margin: 1em 0 .5em;
  }

  > div {
    > div {
      margin: 0 !important;
      width: 100% !important;

      @media #{$tabletDesktop} {
        margin: 1em !important;
        width: auto;
      }
    }
  }
}

/* MUI adjust styles *
.MuiDialog-paper {
  margin: 0 !important;

  @media #{$tabletDesktop} {
    margin: 0 !important;
  }
}

.MuiDialog-paper.MuiDialog-paperFullWidth, 
.MuiDialog-paper.MuiDialog-paperScrollPaper {
  width: 100% !important;

  @media #{$tabletDesktop} {
    width: calc(100% - 1em);
  }
}
*/