@import "../../../styles/main";

footer {
  flex-shrink: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  height: auto;
  @media #{$tabletDesktop} {
    height: $footer-height;
  }
}

.page-footer {
  background-color: white;
  box-sizing: border-box;
  border-top: 1px solid #c2d1d9;
  font-size: 14px;
  padding-bottom: 0;
  overflow-x: hidden !important;

  padding: 0;

  .row {
    box-sizing: border-box;
    //padding: 0.7em 0;
    margin-right: var(--gutter-compensation, -0.5rem);
    margin-left: var(--gutter-compensation, -0.5rem);

    padding: 0;
  }
  
  .container-fluid {
    //padding-right: 20px;
    //padding-left: 20px;
    padding: 1.5em 1em;
  }
  
  .wrap {
    box-sizing: border-box;
    max-width: 1366px;
    margin: 0 auto;
  }
  
  .col-xs {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    box-sizing: border-box;
    padding-right: var(--half-gutter-width, 0.5rem);
    padding-left: var(--half-gutter-width, 0.5rem);
  }
  
  .start {
    text-align: center;
    p {
      line-height: 1em;
      margin: 0 0 .5em 0;
    }
  }
  
  .end {
    text-align: center;
    p {
      line-height: 1em;
      margin: 0;
    }
  }
  
  @media #{$desktop, $tabletDesktop} {
    .row {
      padding: 1.5em 0;
      box-sizing: border-box;
      display: flex;
      flex: 0 1 auto;
      flex-direction: row;
      flex-wrap: wrap;
      margin-right: var(--gutter-compensation, -0.5rem);
      margin-left: var(--gutter-compensation, -0.5rem);
    }

    .container-fluid {
      padding: 0 1em;
    }
  
    .start {
      text-align: start;
      p {
        margin: 0;
      }
    }
  
    .end {
      text-align: end;
      p {
        margin: -7px 0;
      }
    }
  }
  
  .end-xs {
    justify-content: flex-end;
    text-align: end;
  }
  
  .tag {
    color: #000;
    font-weight: normal;
    font-size: 14px !important;
  }
  
  a {
    text-decoration: none;
  }
  
  a:hover {
    color: #2c2c2c;
  }
}

.row {
  box-sizing: border-box;
  padding: 0.7em 0;
  margin-right: var(--gutter-compensation, -0.5rem);
  margin-left: var(--gutter-compensation, -0.5rem);
}

.container-fluid {
  padding-right: 20px;
  padding-left: 20px;
}

.wrap {
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
}

.col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  box-sizing: border-box;
  padding-right: var(--half-gutter-width, 0.5rem);
  padding-left: var(--half-gutter-width, 0.5rem);
}

.start {
  text-align: center;
}

.end {
  text-align: center;
}

@media #{$tabletDesktop} {
  .row {
    padding: 1.5em 2em;
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: var(--gutter-compensation, -0.5rem);
    margin-left: var(--gutter-compensation, -0.5rem);
  }

  .start {
    text-align: start;
  }

  .end {
    text-align: end;
  }
}

.end-xs {
  justify-content: flex-end;
  text-align: end;
}

.tag {
  color: #000;
  font-weight: normal;
  font-size: 14px !important;
}

a {
  text-decoration: none;
}

a:hover {
  color: #2c2c2c;
}
