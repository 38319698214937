$gutter: $gutter; // 15px

$gutter-1-2: $gutter / 2; // 7.5px
$gutter-1-3: $gutter / 3; // 5px
$gutter-1-4: $gutter / 4; // 3.75

$gutter-2-3: ($gutter / 3) * 2; // 10px

$gutter-3-2: ($gutter / 2) * 3; // 20px

$gutter-2: $gutter * 2; // 30px
$gutter-3: $gutter * 3; // 45px
$gutter-4: $gutter * 4; // 60px