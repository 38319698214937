@import "../../../styles/main";

.availability-validation {

  .account-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    max-width: 100%;
    padding: 2.3em 1em 0 1em;
    margin: 0 auto;
  }

  @media (max-width: 767px) {
    .account-container {
      padding: 4.5rem 10px 10px;
    }
  }

  .fixed-block {
    flex: 0 0 auto;
  }

  .expanded-block {
    flex: 1 1 auto;
  }

  .basic-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    left: auto;
    right: auto;
    height: auto;

    text-align: center;
    border: 1px solid #d3dce6;
    background: white;

    box-shadow: none;
    padding: 1.85rem;
    margin: 0;
  }

  .basic-card.small {
    max-width: 320px;
    width: auto;
  }

  .basic-card.medium {
    max-width: 540px;
    width: auto;
  }

  .basic-card.large {
    max-width: 750px;
    width: auto;
  }

  .basic-card.full {
    width: 80%;

    @media #{$tabletDesktop} {
      width: 90%;
    }
  }

  .basic-card img.card-icon {
    align-self: center;
    width: 80px;
    height: auto;
    max-height: 80px;

    margin: 1rem 0;
    padding: 0;
  }

  .basic-card h4 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.5rem;
    margin-top: 0;
    margin-bottom: 12px;
  }

  .basic-card p {
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.125rem;
    margin: 0;
  }

  .basic-card .button--primary {
    min-width: 142px;
  }

  .basic-card .resume {
    font-size: 0.85rem; /* 15px; */
    padding-top: 10px;
  }

  .basic-card .resume table {
    width: 100%;
  }

  .basic-card .resume tr {
    vertical-align: top;
  }

  .basic-card .resume tr td {
    text-align: left;
  }

  .basic-card .resume tr td:first-child {
    //text-transform: uppercase;
    font-size: 12px;
    //font-weight: 600;
    //color: #707a85;
    //padding-right: 20px;
  }

  .basic-card .resume tr td:last-child {
    //text-align: right;
  }

  .d-flex {
    display: flex;
  }

  .flex-column {
    flex-direction: column;
  }

  .justfy-content-center {
    justify-content: center;
  }

  .mt-50 {
    margin-top: 50px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .Flex {
    display: flex;
    align-items: flex-start;
  }

  .FlexColumn {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
  }

  .offerRow {
    display: flex;
    align-items: center;

    .icon-list {
      width: .3em;
    }
  }

  .info-text {
    font-size: 1.1em;
  }

  .info-text_2 {
    font-size: 1.1em;
    margin-top: 1em;
  }

  .dot {
    color: #707a85;
    margin-left: .2em;
    margin-right: .2em;
    font-size: 2em;

    @media #{$tabletDesktop}{
      margin-left: .1em;
      margin-right: .1em;
    }
  }

  .title {
    margin-left: .5em;
    font-weight: $font-bold;
  }

  .variant {
    display: flex;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1em;
    font-weight: $font-extra-light;
    color: $color-gunmetal;
  }

  .details {
    display: flex;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1em;
    font-weight: $font-extra-light;
    color: $color-light-gray-blue;
  }
}