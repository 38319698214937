@mixin share($props...) {
  @for $i from 1 to length($props) {
    #{nth($props, $i)}: nth($props, -1);
  }
}

@mixin circle($tam) {
  display: inline-block;
  @include tam-specified($tam);
  text-align: center;
  border-radius: 50%;
}

@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false) {
  @include pseudo($pos: $position);
  width: 0;
  height: 0;

  @if $round {
    border-radius: 3px;
  }

  @if $direction == down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
    margin-top: 0 - round( $size / 2.5 );
  } @else if $direction == up {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
    margin-bottom: 0 - round( $size / 2.5 );
  } @else if $direction == right {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    margin-right: -$size;
  } @else if  $direction == left {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    margin-left: -$size;
  }
}

@mixin pseudo($display: block, $position: absolute, $content: '') {
  content: $content;
  position: $position;
  display: $display;
}

@mixin push-auto {
  @include share(margin-left, margin-right, auto);
}

@mixin input-placeholder {
  &.placeholder                { @content; }
  &:-moz-placeholder           { @content; }
  &::-moz-placeholder          { @content; }
  &:-ms-input-placeholder      { @content; }
  &::-webkit-input-placeholder { @content; }
  &::placeholder               { @content; }
}

// Hide from both screenreaders and browsers
@mixin hidden {
	display: none;
	visibility: hidden;
}

@mixin unselectable {
	-webkit-touch-callout: none;
	user-select: none;
}

@mixin hover {
  &:hover {
    cursor: pointer;
    @content;
  }
}

@mixin states {
  &:active,
  &:focus,
  &:visited,
  &:hover {
    @content;
  }
}

@mixin backface-hidden {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@mixin block-vertical-center {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
}

@mixin block-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}