#root {
  font-family: San Francisco, sans-serif;
  text-align: center;
}

.switch-selector-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: San Francisco, sans-serif;
  text-align: center;
  color: #000000;

  max-width: 440px;
  min-width: 280px;
  height: 46px;
}

.react-switch-selector-wrapper {
  width: auto;
}

.react-switch-selector-option {
  border-radius: 24px;
}

.react-switch-selector-option-label p {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.2;
  margin: 2px 0 0 0;
  font-weight: 600;
  color: #000000;
  
  & > span {
    font-weight: 400;
    color: #747474;
  }
}

.react-switch-selector-option-label:has(> .disabled) {
  pointer-events:none;
  & > .disabled {
    color: darkgray;
    & > span {
      color: darkgray;
    }
  }
}
