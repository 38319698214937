@import "../../../styles/main";

.recover-password {
  background: $color-very-light-pink;
  display: flex;
  justify-items: center;
  justify-content: center;

  .container {
    max-width: 750px;
    padding: 5em 0;
    @media #{$desktop} {
      padding: 5em 3em;
    }

    .note {
      box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.1);
      width: 100%;
      background: $color-violet;
      color: $color-white-two;
      padding: 0 .5em;
      margin-bottom: 1em;
      display: grid;
      grid-template-columns: 10% 80% 10%;
      align-items: center;
      border-radius: 0;
      @media #{$desktop} {
        grid-template-columns: 5% 90% 5%;
      }

      p {
        font-size: $font-size-base;
        font-weight: $font-extra-light;
        text-align: center;
        @media #{$desktop} {
          text-align: left;
        }
      }

      button {
        background: transparent;
        border: none;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .form {
      box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.1);
      width: 100%;
      background: $color-white-two;
      padding: 2em 0;
      text-align: center;
      border-radius: 0;

      @media #{$desktop} {
        padding: 2em 0;
      }

      .title {
        font-weight: $font-bold;
      }

      .subtitle {
        font-size: $font-size-base;
        padding: .3em 2em 1em;
      }

      .fields {
        display: flex;
        justify-items: center;
        justify-content: center;
        padding: 1em 1.5em;

        .extra {
          display: grid;
          grid-template-columns: 40% 60%;
          align-items: center;
          font-size: .8em;
          margin: 0;
          padding: 0;

          &:first-child {
            text-align: left;
          }

          &:last-child {
            text-align: right;
            margin-right: 1em;
          }

          span {
            font-size: 1em;
          }
        }

        .action {
          //padding: 1.5em .6em 1.5em 1.1em;
        }

        .register-link {
          display: flex;
          justify-items: center;
          justify-content: center;
          align-items: center;
          font-size: .8em;

          p {
            font-size: 1em;
            padding-right: 1em;
          }
        }

      }
    }

    .resend_code_form {
      box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.1);
      width: 100%;
      background: $color-white-two;
      padding: 2em 0 1em 0;
      margin: 1em 0;
      text-align: center;
      border-radius: 0;

      @media #{$desktop} {
        padding: 2em 0;
      }

      .resend_code_title {
        font-size: $font-size-base;
        font-weight: $font-bold;
        margin-bottom: 1em;
      }

      .resend_code {
        text-decoration: none;
        color: $color-active;
        font-size: $font-size-base;
        padding: .3em 1.5em 1em 1.5em;

        a {
          text-decoration: none;
        }

        @media #{$desktop} {
          padding: 0;
        }
      }

      .resend_code:hover {
        cursor: pointer;
      }
    }
  }
}