@mixin placeholder {
  @include input-placeholder {
    color: $color-light-blue-gray;
  }
}

@mixin inputs {
  input[type="text"],
  input[type="date"],
  input[type="time"],
  input[type="number"],
  input[type="email"],
  input[type="password"] {
    @content;
  }
}

@include inputs {
  // margin-bottom: $gutter;
  padding: 0 $gutter-3-2;
  color: $color-black;
  // border: 1px solid $color-light-blue-gray;
  background-color: $color-white-two;
  border-radius: 0;
  height: $control-height;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em;

  &:focus {
    outline: none;
  }

  &[input--block] {
    display: block;
    width: 100%;
  }

  @include placeholder;
}

textarea {
  margin-bottom: $gutter;
  padding: $gutter $gutter-3-2;
  color: $color-black;
  border: 1px solid $color-light-blue-gray;
  width: 100%;
  box-sizing: border-box;

  @include placeholder;
}

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  // transform: translateY(-25%);
}

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem;

  &--block {
    display: flex;
  }

  & > span {
    order: 0;
    padding-bottom: $gutter-1-4;
    font-weight: $font-bold;
    font-size: 0.8em;
    @include nunito;
  }

  input[type="text"],
  input[type="date"],
  input[type="time"],
  input[type="number"],
  input[type="email"],
  input[type="password"],
  textarea {
    order: 1;
    padding-top: $gutter-1-2;

    &:focus {
      & ~ span {
        color: var(--color-primary);
      }
    }
  }
}

.input-flat {
  border: 1px solid $color-light-blue-gray;
}

.input--icon {
  padding-left: 35px !important;
}

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat
    scroll 7px 7px;
}

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat
    scroll 7px 7px;
}
