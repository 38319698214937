@import "../../../styles/main";

.alert {
  font-size: 1rem;
  padding: 5%;

  &__header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    h2 {
      font-family: $font-nunito;
      font-weight: $font-bold;
    }

    &__close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 5%;
      line-height: 0;
      transform: translateX(30%);
      height: 35px;
      width: 35px;

      img {
        width: 20px;
      }
    }
  }

  .button--primary,
  .button--outline {
    margin: .4em 0;
  }

  &__body {
    padding: 1em 2em;
  }

  &__footer {
    padding: 1em;
  }
}