@import "../../../styles/main";

.spinner-grid {
  display: flex;
  align-items: center;
  text-align: right;

  &-menu {
    float: right;
  }

  button {
    height: auto;
    padding: 0.2em 0.2em 0 0.2em;
    color: $color-active;
    border: 0.8px solid $color-light-blue-gray;
    border-radius: 2px;
    margin-left: 0;
    margin-right: 0;

    svg {
      color: $color-active;
      width: 20px;
      height: 20px;
    }

    &:hover,
    &:focus {
      color: $color-white-two;
      background: $color-primary-dk;

      & > svg {
        color: $color-white-two !important;
        fill: $color-white-two !important;
        background: $color-primary-dk;
      }
    }
  }

  span {
    font-weight: $font-bold;
    margin: 0.2em 0.4em;
    font-size: 18px;
    text-align: center;
  }

  & > * {
    margin: 0.5em;
    font-weight: $font-medium;
  }
}
