@import "../../../styles/main";

.login {
  @extend .full-height;
  display: flex;
  justify-items: center;
  justify-content: center;
  padding: 20px 0;

  @media #{$tabletDesktop} {
    padding: 64px;
  }

  .container {
    max-width: 500px;
    @media #{$tabletDesktop} {
      max-width: 700px;
    }

    .note {
      box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.1);
      width: 100%;
      background: $color-violet;
      color: $color-white-two;
      padding: 0 0.5em;
      margin-bottom: 1em;
      display: grid;
      grid-template-columns: 10% 80% 10%;
      align-items: center;
      border-radius: 0;
      @media #{$desktop} {
        grid-template-columns: 5% 90% 5%;
      }

      p {
        font-size: $font-size-base;
        font-weight: $font-extra-light;
        text-align: center;
        @media #{$desktop} {
          text-align: left;
        }
      }

      button {
        background: transparent;
        border: none;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .form {
      //box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.1);
      width: 100%;
      background: $color-white-two;
      padding: 1em 0.5em;
      text-align: center;
      border-radius: 0;

      @media #{$desktop} {
        padding: 2em 5em;
      }

      .title {
        font-family: "Nunito Sans";
        font-size: 2rem;
        font-weight: 300;
        line-height: 1.5rem;
        background-image: none;
        color: rgb(9, 66, 150);
        min-height: 55px;
        margin: 0 0 0 .5rem;

        @media #{$tabletDesktop} {
          font-size: 2rem;
          line-height: 2.375rem;
          background-image: none;
          min-height: 0;
          margin: initial;
        }

        span {
          display: block;
          margin: 15px auto 0;
          background: #e50914;
          width: 36px;
          height: 2px;
        }
      }

      .subtitle {
        font-family: "Nunito Sans";
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5rem;
        display: flex;
        padding: 30px 30px 20px;
        color: black;
      }

      .fields {
        display: flex;
        justify-items: center;
        justify-content: center;
        padding: 0.5em 0;
        
        &-error {
          display: block;
          max-width: 300px;
          width: 100%;
          text-align: left;
          font-size: 0.875rem;
          line-height: 1.5;
          font-family: "Nunito Sans";
          font-weight: 400;
          color: rgb(255, 66, 77);
          margin: 0;
          height: 0;
        }

        &-form-error {
          height: auto;
          text-align: center;
          display: block;
          background: red;
          color: white !important;
          padding: 0.5em;
          font-weight: bold !important;
          border-radius: 8px;
        }

        .PhoneInput {
          margin-bottom: 0.5em;
        }
        
        .captcha {
          display: block;
          margin: 0 auto;
          width: 304px;
          & > div:first-child {
            margin-bottom: 0.5em;
          }
          & + .fields-error {
            display: block;
            margin: 0 auto;
            width: 304px;
          }
        }

        .extra {
          display: grid;
          grid-template-columns: 40% 60%;
          align-items: center;
          font-size: 0.8em;
          margin: 0;
          padding: 0;

          &:first-child {
            text-align: left;
          }

          &:last-child {
            text-align: right;
            margin-right: 1em;
          }

          span {
            font-size: 1em;
          }
        }

        .action {
        }

        button {
          font-size: 1rem;
          font-weight: 400;
          text-transform: none !important;
          line-height: 1.5;
          height: 50px;
          margin: 0 auto;
          outline: 0px;
          border: 0px;
          font-family: "Nunito Sans";
          width: 100%;
          min-width: 64px;
          max-width: 295px;
          border-radius: 8px;
          color: rgb(255, 255, 255);
          background-color: rgb(30, 74, 204);
          box-shadow: rgba(0,0,0,0.2) 0px 3px 1px -2px, rgba(0, 0,0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
          font-weight: 700;
          transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }

        .register-link {
          display: flex;
          justify-items: center;
          justify-content: center;
          align-items: center;

          p {
            font-size: 0.85rem;
            font-weight: 400;
            line-height: 1.15rem;
          }
        }
      }
    }
  }
}

.PhoneInput {
  width: 100%;
  box-sizing: border-box;
  font-size: 1em;
  font-weight: 400;
  border: 1px solid #cecece;
  border-radius: 4px;
  margin: 2em 0 0;

  &Input {
    padding: 3px 7px;
    color: #2a2a2a;
    background-color: #ffffff;
    border-radius: 4px;
    height: 50px;
    border: none;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.38rem;
    outline: none;
  }
}

.PhoneInputCountrySelect::-ms-expand {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.PhoneInputCountry {
  margin-right: 0;
  margin-left: 12px;
}

.PhoneInputCountryIcon--border {
  box-shadow: none;
}
