@import "../../../styles/main";

.field-group-2 {
  color: $color-battleship-grey;
  text-align: left;
  margin: 1em 0;

  display: grid;
  grid-template-columns: 32px 1fr;

  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0px;
  }

  .label {
    grid-column: 2 / 2;
    grid-row: 1 / 1;
  }

  .front-icon {
    grid-column: 1 / 1;
    grid-row: 2 / 2;
    transform: translateY(20%);
    width: 1em;
  }

  input,
  .MuiSkeleton-root,
  .MuiTextField-root {
    grid-column: 2 / 2;
    grid-row: 2 / 2;
  }

  .label,
  .hint {
    font-size: 0.8em;
    line-height: 1.8;
    font-style: italic;

    &_error {
      color: red;
    }
  }

  .hint_alert {
    font-size: 0.8em;
    line-height: 1.8;
    font-style: italic;

    &_success {
      color: $color-sick-green;
    }

    &_error {
      color: red;
    }
  }

  svg {
    fill: $color-primary !important;
    margin-top: 4px;
  }
}

.profile {
  font-size: 1rem;
  padding: 1em;

  &__header {
    display: flex;
    flex-direction: column;
    padding: 0 0 1em 0;
    margin: auto;

    max-width: 45%;
    min-width: 450px;

    @media #{$tabletDesktop} {
      align-items: center;
      flex-direction: row;
      max-width: unset;
      min-width: unset;
      padding: 1em 0;
    }

    .button--outline {
      width: 12em;
      background: $color-white-two;
      margin-top: 1em;

      @media #{$tabletDesktop} {
        margin-top: 0;
      }
    }
  }

  &-data {
    //background: $color-white-two;
    background: transparent;
    width: 100%;
    max-width: 45%;
    min-width: 450px;

    @media (max-width: 500px) {
      min-width: 100%;
      max-width: 100%;
    }
    //-webkit-box-shadow: 0 5pt 15pt rgba(0, 0, 0, 0.1);
    //box-shadow: 0 5pt 15pt rgba(0, 0, 0, 0.1);
    margin: 1em auto;

    border: 1px solid $color-light-blue-gray;
    border-radius: 8px;

    @media #{$tabletDesktop} {
      border-radius: 12px;
    }

    > div {
      background: transparent;
      
      > div {
        background: transparent;
      }
    }

    &__header {
      .field-group-2 {
        .label {
          padding: 0 7.5px;
        }
      }
    }

    &__header,
    &__form {
      // width: 100%;
      padding: 0.5em;
      @media #{$tabletDesktop} {
        padding: 0.5em 4em;
      }
    }

    &__form {
      border-top: 0;

      margin: 1.5em 0 !important;
      & > * {
        margin-bottom: 1.5em !important;
        &:last-child {
          margin-bottom: 0 !important;
          grid-gap: 1em;
          width: 50%;
          justify-self: center;
          margin: 0 auto;
        }
      }
      // border-top: 1px solid $color-very-light-pink;
    }

    &__avatar-wrapper {
      border-radius: 50%;
      overflow: hidden;
      height: 100px;
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__avatar {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1em 0 0;

      .label-container {
        transform: translate(100%, -100%);
      }
    }

    &__name {
      &-holder {
        flex-grow: 1;
      }

      //height: 38px;
      display: flex;
      //justify-content: space-between;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 1em 0 .25em 0;

      &__label {
        font-size: 1.25em;
        font-weight: $font-bold;
        //padding-left: 64px;
        flex-grow: 1;
      }
    }
  }
}

.expansion-bottom-special {
  margin-bottom: -15px;
}
