.password-strength-meter {
  &__progress-container {
    display: flex;
    justify-content: space-between;

    p {
      color: rgba(0, 0, 0, 0.54);
      margin: 0;
      font-weight: 400;
      line-height: 1em;
      letter-spacing: 0.03333em;

      font-size: 0.7rem !important;
      margin-top: 3px !important;
      min-height: 1em !important;
      text-align: right !important;
    }
  }
  &__progress {
    -webkit-appearance: none;
    appearance: none;
    width: 250px;
    height: 0.7em;
    flex-grow: 1;
    padding: 0.2em 1em 0 0;

    &::-webkit-progress-bar {
      background-color: #eee;
      border-radius: 3px;
    }
    &::-webkit-progress-value {
      border-radius: 2px;
      background-size: 35px 20px, 100% 100%, 100% 100%;
    }
  }

  .strength-1::-webkit-progress-value {
    background-color: #f25f5c;
  }
  .strength-2::-webkit-progress-value {
    background-color: #ffe066;
  }
  .strength-3::-webkit-progress-value {
    background-color: #247ba0;
  }
  .strength-4::-webkit-progress-value {
    background-color: #70c1b3;
  }
}
