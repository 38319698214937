  @import "../../../styles/main";

  .selector {
    display: flex;
    justify-content: center;
    margin-bottom: 2em;
  }

  .field-group {
    color: $color-battleship-grey;
    
    &__half {
      @media #{$tabletDesktop} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.8em;
      }
    }
  }

  .steps {
    font-size: 0.9rem;
    padding: 1em;
    width: calc(100% - 2em);
    display: flex;
    justify-content: center;

    @media #{$desktop} {
      padding: 2em 1em;
      width: calc(100% - 2em);
    }

    >div {
      width: 100% !important;
    }

    &__header {
      margin-left: 1.5em;

      h1 {
        font-size: 1.2em;
        font-weight: $font-semi-bold;
        color: $color-gunmetal-two;
        margin-bottom: 0.6em;
      }

      .label-icon {
        font-size: 1em;
        color: $color-gunmetal-two;
      }

      .details {
        font-size: 1em;
        font-weight: $font-medium;
        color: $color-gunmetal-two;
        margin-right: 1em;
        margin-bottom: 0.3em !important;

        span {
          display: flex;
          align-items: center;

          img {
            width: 1.2em;
            margin-right: 0.5em;
          }
        }

        h3 {
          margin: 0;
          text-align: left;
          color: $color-light-blue-gray;
        }

        &_last {
          margin-bottom: 1em !important;
        }
      }
    }

    .step {
      border: none;
      //max-width: 700px;
      //min-width: 700px;
      width: 100%;
      //padding: 1em;

      @media #{$onlyMobile} {
        min-width: unset;
      }

      &__section {
        padding: 3% 0;
        border-bottom: 1px solid $color-light-blue-gray;

        h3 {
          font-size: 1em;
          font-weight: $font-semi-bold;
          color: $color-gunmetal-two;

          a {
            float: right;
            color: $color-sick-green;
            font-size: 0.8em;
          }
        }

        input {
          border-radius: 0.3em;
          border: 1px solid $color-light-blue-gray;
        }

        .textarea {
          border-radius: 0.3em;
        }

        .grid--form {
          padding: 1em 0 0;
        }

        .field-group {
          color: $color-battleship-grey;
          
          &__half {
            @media #{$tabletDesktop} {
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 0.8em;
            }
          }

          .label,
          .hint {
            font-size: 0.8em;
            line-height: 1.8;
            font-style: italic;
          }

          .hint_alert {
            font-size: 0.8em;
            line-height: 1.8;
            font-style: italic;

            &_success {
              color: $color-sick-green;
            }

            &_error {
              color: red;
            }
          }
        }
      }
    }
  }

  .new {
    &__steps {
      //padding: 0em 3em 1em 3em;
      padding: 1em;
      width: auto;
      margin: auto;

      @media #{$tabletDesktop} {
        width: 750px;
      }
    }

    &__step {
      min-width: unset !important;
      padding: 0em !important;

      @media #{$tabletDesktop} {
        padding: 0em 1em 1em 1em !important;
      }

      &__header-wrapper {
        background-color: white;
        padding: 0;

        .button--primary {
          border: 1px solid $color-primary;
          box-shadow: none;
        }

        @media #{$tabletDesktop} {
          padding: 1em 2.5em 0;
        }
      }

      &__header {
        font-weight: 800;
        font-size: 1.4rem;
        text-align: center;

        @media #{$tabletDesktop} {
          font-size: 2.4rem;
        }
      }

      &__footer {
        position: relative;
        width: 100%;
        background: white;

        label>span {
          font-size: 1em;
        }

        &__container {
          padding: 2em 0 1em;

          @media #{$tabletDesktop} {
            padding: 2em 2.5em 1em 2.5em;
          }

          justify-content: center;
        }
      }
    }

    &__spacer {
      border: none;
      border-top: 1px solid #f1f1f1;
      height: 2px;
      width: 100%;
      margin: 2em 0;
    }

    &__button {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      //align-items: center;
      margin-bottom: 20px;

      &__address {
        width: 100%;
      }

      p {
        font-size: 17px;
        font-weight: 700;
        margin: 0;
        padding: 0;
        text-align: left;
        width: 100%;
        padding: 0;

        span {
          font-size: 15px;
          font-weight: 500;
        }
      }
    }
  }

  .step-delivery {
    &__button-next {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2em 0 0.5em 0;
  
      button {
        width: 100% !important;
        font-size: 18px !important;
        text-transform: capitalize;
  
        span {
          margin: 0 .2em;
        }
  
        @media #{$tabletDesktop} {
          font-size: $font-size-base;
        }
      }
    }
  
    &__checkbox {
      display: flex;
      justify-content: center;
      align-items: center;
      label {
        margin: 0;
        span {
          padding-top: 0;
          padding-left: 0;
          padding-bottom: 0;
        }
      }

  
      .Mui-checked {
        .MuiSvgIcon-root {
          fill: $color-primary !important;
        }
      }
    }
  }
  
  .coupon {
    font-size: 1em;
    font-weight: $font-regular;
    margin: 1.8em 0;

    &-label {
      color: $color-black;
      font-weight: $font-bold;
    }

    .grid--small-form {
      margin: 0.5em 0;
      grid-column-gap: 1em;
      column-gap: 0.7em;

      @media #{$tabletDesktop} {
        grid-template-columns: 65% 30%;
      }

      button {
        border: none !important;
      }
    }
  }
