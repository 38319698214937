@import "../../../styles/main";

.back-btn {
  //position: absolute;
  //top: 1.5em;
  //left: 1em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 15px;

  @media #{$tabletDesktop} {
    //top: 2.5em;
    //left: 2em;
    padding: 5px 10px;
  }

  &>img {
    padding: 0;

    @media #{$tabletDesktop} {
      padding: 0 0.3em 0;
    }
  }

  &>span {
    display: none;

    @media #{$tabletDesktop} {
      display: inherit;
      margin-right: 0.3em;
    }
  }
}

.react-switch-selector-option-label {
  z-index: 1 !important;
}

.restaurant_detail {
  font-weight: $font-regular;
  text-align: center;
  padding: 0 0 3rem;

  &__description_container {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 30px;
  }

  &__text {
    color: $color-black;
    text-align: left;
  }

  &__description {
    font-size: 1.05em;
    color: $color-black;
    height: fit-content;
    flex-grow: 1;
    margin: 0 1em;

    @media #{$tabletDesktop} {
      margin: 0 1.25em;
    }

    &_name {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      h1,
      h2 {
        font-size: 1.5em;
        font-weight: $font-bold;
        line-height: 1.65rem;
        color: $color-black;
        text-transform: initial;
        margin: 1rem 0 0 0;
        text-align: left;
      }

      h2>span {
        img {
          padding-right: 0.8em;
        }

        display: flex;
        line-height: 1.65rem;
        font-size: 13px;
        font-weight: $font-regular;
        color: $color-dead-black;
        align-items: center;

        .sep {
          color: $color-light-blue-gray;
        }

        .bullet {
          padding: 0 7px;
          color: $color-black;
        }
      }

      h3 {
        display: inline-flex;
        color: $color-light-gray-blue;
        margin: 0;
        font-size: 1.15em;
        font-weight: $font-regular;
      }
    }

    &_text {
      color: $color-black;
      margin-right: 1em;
      white-space: nowrap;

      p {
        margin: 5px 0 0;
        white-space: normal;

        &.featured {
          font-weight: $font-semi-bold;
        }
      }

      h3 {
        margin: 0;
        text-align: left;
        color: $color-light-blue-gray;
      }
    }

    &_price {
      font-size: 0.8em;
      color: $color-light-blue-gray;
    }

    &_score {
      font-size: 0.7em;
      display: flex;
      align-items: center;
      margin-top: 1em;
      color: $color-gunmetal-two;

      img {
        transform: translatey(-20%);
      }

      span {
        margin-left: 1em;
      }

      span>span {
        color: $color-light-blue-gray;
        margin-left: 0.5em;
      }
    }

    &_promotion {
      display: flex;
      align-items: center;
      margin-top: 1em;
      padding: 0.3em;
      background: $color-back-blue;
      border-radius: 0.3em;
      width: fit-content;

      img {
        vertical-align: sub;
      }

      span {
        margin: 0 0.5em 0 0.5em;
        color: $color-gunmetal-two;
      }
    }

    &_image {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      position: relative;
      text-align: left;
      height: auto;
      min-height: 10.5em !important;
      overflow: hidden;
      border-radius: 0;
      background-position: center center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      padding: 1.5em 1em;

      img {
        width: 100%;
        height: auto;
      }

      .no-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      @media #{$tabletDesktop} {
        height: 12.5em !important;
        padding: 1.5em 1.25em;
      }

      /*
      @media (min-width: 992px) {
        height: 10.5em !important;
      }
      */

      .main-text {
        align-items: flex-start;
        width: 100%;
        margin: 1em 0 0;

        @media #{$tabletDesktop} {
          margin: 1.5em 0 0;
        }

        h1 {
          font-size: 2.2em;
          font-weight: $font-bold;
          color: $color-white-two;
          text-transform: capitalize;
          margin: 0;
        }

        h3 {
          color: $color-white-two;
          font-weight: $font-semi-bold;
          font-size: 1.1em;
          margin: 0;
        }
      }
    }
  }

  &__filter {
    padding: 1em;
    background: $color-back-blue;
  }

  &__menu {
    padding: 1em 0;

    &:first-child {
      h2 {
        margin: .5em .25em;
      }
    }

    h2 {
      font-size: 20px;
      font-weight: $font-bold;
      letter-spacing: -.005em;
      text-transform: none;
      text-align: left;
      color: $color-black;
      margin: 1.5em .25em .5em .25em;

      @media #{$tablet} {
        font-size: 22px;
      }

      @media #{$desktop} {
        font-size: 24px;
      }
    }

    h3 {
      color: $color-light-blue-gray;
    }
  }

  &__car {
    position: relative;

    &_section {
      //background: $color-white;
      background: rgba(194, 209, 217, 0.1);
      position: -webkit-sticky;
      position: sticky;
      top: 80px;
      height: 100vh;

      //* Oculta todo el sidebar
      display: none !important;

      @media #{$desktop} {
        //display: block;
      }

      .delivery-switch {
        background: rgb(239, 239, 239);
        font-size: 0.8em;
        color: $color-light-blue-gray;
        display: flex;
        justify-content: center;
        padding: 3.5%;

        div {

          &> :first-child,
          &> :last-child {
            margin: 0 5px;
          }
        }

        .active-label {
          color: $color-black;
        }
      }

      h3 {
        font-weight: $font-bold;
        color: $color-black;
        text-align: center;
        margin: 0.7em 0;
      }

      .cart {
        padding: 0;

        &__list {
          padding: 3% 0;
          max-height: calc(100vh - 350px);
        }

        .spacer {
          margin: 0.5em 0;
        }
      }

      .cart__item__info>* {
        margin-right: 0;
      }
    }
  }
}

.menu_tabs {
  width: 100vw;

  @media #{$desktop} {
    width: unset;
    top: $topbar-height;
  }

  display: flex;
  align-items: center;
  // border-bottom: 1px solid $color-light-blue-gray;

  .container {
    display: flex;
    align-items: center;
    margin: 0 !important;
    justify-content: space-between;
    width: 100%;
    @media #{$tabletDesktop} {
      width: 90%;
    }

    &>*:first-child {
      display: flex;
      align-items: center;
    }
  }

  position: -webkit-sticky;
  position: sticky;
  top: $topbar-height - 20;
  z-index: 1;
  // background: rgba(194, 209, 217, 0.1);
  background: #f9fafb;
  box-shadow: inset 0 1px #fff,
  0 1px 3px rgba(34, 25, 25, 0.4);
  -moz-box-shadow: inset 0 1px #fff,
  0 1px 3px rgba(34, 25, 25, 0.4);
  -webkit-box-shadow: inset 0 1px #fff,
  0 1px 3px rgba(34, 25, 25, 0.4);

  &_items {
    cursor: pointer;
    text-transform: uppercase;
    color: $color-black;
    font-weight: $font-medium;
    font-size: 0.8em;
    border-bottom: 3px solid transparent;

    a {
      display: block;
      padding: 1em;
      margin: 0;
      color: $color-black;
      text-decoration: none;
    }

    &.selected {
      border-bottom: 3px solid $color-active;
    }

    //a:hover,
    //a:focus {
    //  border-bottom: solid 4px $color-gunmetal-two;
    //}
  }
}

.menu-list {
  padding: 1em;

  .container {
    width: 100%;
    margin: 0 auto;
  }
}

.cart-section {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 201;
  padding: 1em;
  //background-color: $color-white-two;
  //box-shadow: 0 5px 15px 15px rgba(0, 0, 0, 0.1);

  &>button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-size: 1.2em;
    text-transform: none;
    height: 58px;
    border-radius: 29px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    padding: 14px;

    &:hover,
    &:focus {
      background: $color-primary-dk;
    }

    /*
    span {
      vertical-align: sub;
      vertical-align: -webkit-baseline-middle;
    }
    */

    &>*:nth-child(1) {
      display: flex;
      flex-grow: 0;
      align-items: center;
      justify-content: center;
      //top: 6px;
      left: 15px;
      background: rgba(255, 255, 255, 0.15);
      min-width: 32px;
      height: 32px;
      border-radius: 18px;
      //padding: 4px 7px 0 7px;
      line-height: 1.2em;
    }

    &>*:nth-child(2) {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      padding: 0 1em;
    }

    &>*:nth-child(3) {
      //position: absolute;
      //top: 10px;
      right: 15px;
      font-size: 1.125em;
      font-weight: $font-regular;
      line-height: 1em;

      display: flex;
      flex-grow: 0;
      align-items: center;
    }
  }

  &__dialog-wrapper {
    background: $color-white;
    height: 100%;
  }
}

//* Mui extra styles
.MuiTabScrollButton-root {
  width: 42px !important;

  &.Mui-disabled {
    width: 20px !important;
  }
}

.MuiTab-textColorPrimary.Mui-selected,
.MuiSvgIcon-root {
  fill: $color-black  !important;
}

.MuiSvgIcon-fontSizeSmall {
  font-size: 1.5rem !important;
}