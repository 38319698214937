@import "responsive.breakpoints";

.mobile {
  &--disabled {
    display: inherit !important;
  }
  &--on {
    display: none !important;
  }
}
@media #{$desktop} {
  .mobile {
    &--disabled {
      display: none !important;
    }
    &--on {
      display: inherit !important;
    }
  }
}

.desktop {
  &--disabled {
    display: inherit !important;
  }
  &--on {
    display: none !important;
  }
}
@media #{$desktop} {
  .desktop {
    &--disabled {
      display: none !important;
    }
    &--on {
      display: inherit !important;
    }
  }
}
