@import "../../../../../styles/main";

.pin-to-login {
  @extend .full-height;

  display: flex;
  justify-items: center;
  justify-content: center;

  padding: 20px 0;

  @media #{$tabletDesktop} {
    padding: 64px;
  }

  .container {
    max-width: 500px;
    @media #{$tabletDesktop} {
      max-width: 700px;
    }

    .note {
      box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.1);
      width: 100%;
      background: $color-violet;
      color: $color-white-two;
      padding: 0 0.5em;
      margin-bottom: 1em;
      display: grid;
      grid-template-columns: 10% 80% 10%;
      align-items: center;
      border-radius: 0;
      @media #{$desktop} {
        grid-template-columns: 5% 90% 5%;
      }

      p {
        font-size: $font-size-base;
        font-weight: $font-extra-light;
        text-align: center;
        @media #{$desktop} {
          text-align: left;
        }
      }

      button {
        background: transparent;
        border: none;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .form {
      //box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.1);
      width: 100%;
      background: $color-white-two;
      padding: 1em 0.5em;
      text-align: center;
      border-radius: 0;

      @media #{$desktop} {
        padding: 2em 5em;
      }

      .title {
        font-size: 2rem;
        line-height: 1.5rem;
        letter-spacing: -.025em;
        background-image: none;
        color: $color-black;
        min-height: 55px;
        margin: 0;
        font-family: "Nunito Sans";
        font-weight: 300;
        text-align: center;
        color: #094296;
        margin-bottom: 0.5rem;

        @media #{$tabletDesktop} {
          font-size: 2.1rem;
          line-height: 2.375rem;
          background-image: none;
          min-height: 0;
          margin: initial;
        }

        span {
          display: block;
          margin: 15px auto 0;
          background: #e50914;
          width: 36px;
          height: 2px;
        }
      }

      .subtitle {
        font-family: "Nunito Sans";
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5rem;
        display: flex;
        padding: 30px 30px 20px;
        color: black;
      }

      .fields {
        display: flex;
        justify-items: center;
        justify-content: center;
        padding: 0 0 0.5em 0;

        &-error {
          display: block;
          max-width: 300px;
          width: 100%;
          text-align: left;
          font-size: 0.875rem;
          line-height: 1.5;
          font-family: "Nunito Sans";
          font-weight: 400;
          color: rgb(255, 66, 77);
          margin: 0;
          height: 0;
          text-align: center;
        }

        &-form-error {
          height: auto;
          text-align: center;
          display: block;
          background: red;
          color: white !important;
          padding: 0.5em;
          font-weight: bold !important;
          border-radius: 8px;
        }
        
        .error-message-container{
          display: flex;
          justify-content: center;
          padding-bottom: 2em;
        }

        .extra {
          display: grid;
          grid-template-columns: 40% 60%;
          align-items: center;
          font-size: 0.8em;
          margin: 0;
          padding: 0;

          &:first-child {
            text-align: left;
          }

          &:last-child {
            text-align: right;
            margin-right: 1em;
          }

          span {
            font-size: 1em;
          }
        }

        .action {
          padding-top: 1.5em;
        }

        button {
          font-size: 1rem;
          font-weight: 400;
          text-transform: none !important;
          line-height: 1.5;
          height: 50px;
          margin: 0 auto;
          outline: 0px;
          border: 0px;
          font-family: "Nunito Sans";
          width: 100%;
          min-width: 64px;
          max-width: 295px;
          border-radius: 8px;
          color: rgb(255, 255, 255);
          background-color: rgb(30, 74, 204);
          box-shadow: rgba(0,0,0,0.2) 0px 3px 1px -2px, rgba(0, 0,0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
          font-weight: 700;
          transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

          span {
            color: $color-primary;
          }

          &:hover,
          &:focus {
            span {
              color: $color-primary-dk;
            }
          }
        }

        .register-link {
          display: flex;
          justify-items: center;
          justify-content: center;
          align-items: center;
          font-size: 0.5em;

          p {
            text-align: center;
            font-size: $font-size-base;
            color: $color-active;
            padding-right: 0.8em;
          }

          .button-link {
            font-weight: 400;
            color: $color-primary;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.react-code-input {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.resend-pin-modal {
  justify-content: center;

  &__header {
    font-size: 22px !important;
    font-weight: 800 !important;
    margin-bottom: 1em;
    text-align: center;
    padding: 1em 2em !important;

    h2 {
      line-height: 1;
      font-size: 1.2rem;
      font-family: "Nunito Sans";
      font-weight: 400;
      text-align: center;
      flex: 0 0 auto;
      margin: 0px;
      padding: 0;
      color: #094296;
    }

    &__close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 1.1em;
      right: 1em;
      height: 44px;
      width: 44px;
      border-radius: 22px;
    }
  }

  .content-child {
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 0px auto;

    .resend_code {
      text-decoration: none;
      font-size: $font-size;
      text-align: center;
      color: $color-primary;
      margin: 0.35em 2em;
      padding: 0.8em 1.5em 0.8em 1.5em;
      cursor: pointer;
      font-family: "Nunito Sans";
      min-width: 64px;
      border-radius: 8px;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border: 1px solid rgba(30, 74, 204, 0.5);
      color: rgb(30, 74, 204);
      width: 55%;
      font-size: 1rem;
      padding: 10px 33px;
      line-height: 1.5;
      font-weight: 700;

      &:hover,
      &:focus {
        color: $color-white-two;
        background: $color-primary-dk;
        border: 1px solid $color-primary-dk;

        a {
          color: $color-white-two;
        }
      }

      &--cancel {
        margin: 1.5em;
        color: $color-white-two;
        background-color: $color-primary;

        &:hover,
        &:focus {
          background: $color-primary-dk;
          border: 1px solid $color-primary-dk;
        }
      }

      a {
        text-decoration: none;
        color: $color-primary;
      }
    }
  }
}

.modify-button {
  &__container {
    position: relative;
    margin-top: 24px;
    max-width: 100%;
    font-family: "Nunito Sans";
    font-size: 0.928571rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    border-radius: 16px;
    white-space: nowrap;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    cursor: default;
    outline: 0px;
    text-decoration: none;
    padding: 0px;
    vertical-align: middle;
    box-sizing: border-box;
    background-color: transparent;
    border: 1px solid rgba(81, 178, 112, 0.7);
    color: rgb(81, 178, 112);
    padding: 0 1em;
  }
  &__icon {
    font-size: 22px;
    cursor: pointer;
    margin: 0px 5px 0px -6px;
    color: rgba(81, 178, 112, 0.7);
    transform: translateX(10px);
    height: 24px;
    width: 24px;

    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentColor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}

