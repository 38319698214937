@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap);
/** Thin */
@font-face {
  font-family: "San Francisco";
  font-weight: 200;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-thin-webfont.woff");
}

/** Regular */
@font-face {
  font-family: "San Francisco";
  font-weight: 400;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}

/** Medium */
@font-face {
  font-family: "San Francisco";
  font-weight: 500;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-medium-webfont.woff");
}

/** Semi Bold */
@font-face {
  font-family: "San Francisco";
  font-weight: 600;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-semibold-webfont.woff");
}

/** Bold */ 
@font-face {
  font-family: "San Francisco";
  font-weight: 700;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-bold-webfont.woff");
}

body {
  margin: 0;
  font-family: "San Francisco";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.toasts-container {
  position: fixed;
  overflow: hidden;
  z-index: 999999999999;
  max-height: calc(100vh - 10px);
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end; }
  .toasts-container .toast-distribution {
    max-width: 300px;
    display: flex;
    justify-content: space-evenly;
    align-items: center; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.topbar,
.topbar-container {
  height: 60px; }
  @media (min-width: 1200px) {
    .topbar,
    .topbar-container {
      height: 80px; } }
  .topbar header,
  .topbar-container header {
    background-color: #fff;
    box-sizing: border-box;
    margin: 0 auto;
    position: relative;
    right: 0;
    left: 0; }
    @media (min-width: 1200px) {
      .topbar header .container,
      .topbar-container header .container {
        box-sizing: border-box;
        max-width: 1920px;
        margin: 0 auto; } }

.impersonation-block {
  background: #FF424D;
  color: white;
  font-size: medium;
  font-weight: 500;
  line-height: 2;
  display: flex;
  justify-content: flex-end;
  padding-right: .5em; }

.topbar {
  position: relative;
  left: 0;
  right: 0;
  background: #ffffff;
  font-size: 0.75rem;
  z-index: 200; }
  .topbar--fixed {
    position: fixed;
    box-shadow: inset 0 1px #fff, 0 1px 3px rgba(34, 25, 25, 0.4);
    -moz-box-shadow: inset 0 1px #fff, 0 1px 3px rgba(34, 25, 25, 0.4);
    -webkit-box-shadow: inset 0 1px #fff, 0 1px 3px rgba(34, 25, 25, 0.4); }
  .topbar-placeholder {
    height: 0;
    width: 100%; }
    .topbar-placeholder--fixed {
      height: 60px; }
      @media (min-width: 1200px) {
        .topbar-placeholder--fixed {
          height: 80px; } }
  .topbar .cart-total {
    display: none; }
    @media (min-width: 768px) {
      .topbar .cart-total {
        display: inline; } }
  .topbar .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
    width: calc(100% - 1em);
    padding: 0 0.5em;
    /*
    @media (max-width: 1199px) {
      width: 100%;
    }
    @media (max-width: 640px) {
      width: calc(100% - 1em);
      padding: 0 0.5em;
    }
    */ }
    @media (min-width: 768px) {
      .topbar .container {
        width: 100%; } }
  .topbar__logo-block {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    width: 38px;
    padding-left: 0 !important;
    overflow: hidden; }
    @media (min-width: 768px) {
      .topbar__logo-block {
        flex-grow: unset;
        padding: 0 2em;
        margin: 0 0 0 2em;
        width: auto; } }
    @media (min-width: 1200px) {
      .topbar__logo-block {
        margin: 0 0 0 1em; } }
    .topbar__logo-block img {
      margin: auto .5em; }
    @media (min-width: 768px) {
      .topbar__logo-block {
        margin: auto; } }
  .topbar__navigation {
    flex-grow: 1;
    height: 100%; }
    .topbar__navigation ul {
      height: 100%;
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
      align-items: center; }
      .topbar__navigation ul .wide-link {
        min-width: 130px; }
      .topbar__navigation ul li {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        padding: 0.35em 0.8em; }
        @media (min-width: 768px) {
          .topbar__navigation ul li {
            padding: 0.35em 1.5em; } }
        .topbar__navigation ul li a {
          margin: 0; }
          @media (min-width: 768px) {
            .topbar__navigation ul li a {
              margin: 0 2em; } }
        .topbar__navigation ul li p,
        .topbar__navigation ul li span {
          font-size: 16px !important;
          text-transform: none; }
        .topbar__navigation ul li p {
          color: #1d1d1f !important; }
        .topbar__navigation ul li span.bullet {
          padding: 0 7px;
          color: #1d1d1f; }
          .topbar__navigation ul li span.bullet__white {
            color: #ffffff; }
        .topbar__navigation ul li .MuiInputBase-input {
          height: 33.38px;
          min-width: 130px; }
          .topbar__navigation ul li .MuiInputBase-input > div:last-child > div {
            padding: 4px !important; }
  .topbar__btn-link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 400;
    text-transform: none !important;
    line-height: 1.5;
    height: 42px;
    color: #ffffff;
    background: #1e4acc;
    border: 1px solid #1e4acc !important;
    border-radius: 4px;
    margin: 0 1em !important; }
    .topbar__btn-link:hover, .topbar__btn-link:focus {
      background: #0D35AB;
      border-color: #0D35AB !important; }
    .topbar__btn-link span {
      color: #ffffff; }
    .topbar__btn-link a {
      padding: auto 1em;
      text-decoration: none;
      color: #ffffff; }
      .topbar__btn-link a:hover, .topbar__btn-link a:focus {
        background: #0D35AB !important; }
    .topbar__btn-link--active {
      color: #1e4acc;
      border: 1px solid #1e4acc;
      cursor: pointer; }
      .topbar__btn-link--active:hover, .topbar__btn-link--active:focus {
        background: #0D35AB;
        color: #f8f8f8; }
  .topbar__auth-btn {
    border: 1px solid #1d1d1f;
    padding: 0 !important; }
    .topbar__auth-btn a {
      text-transform: none !important;
      display: block;
      padding: 0.35em 1.5em;
      text-decoration: none;
      color: #1d1d1f; }
    .topbar__auth-btn a:hover,
    .topbar__auth-btn a:focus {
      background: #f5f5ed; }
  .topbar__flat-link {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    line-height: 2.5;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 0 !important; }
    .topbar__flat-link .menu-wrapper {
      height: 100%; }
    .topbar__flat-link button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background: transparent;
      color: #1d1d1f;
      height: 100%;
      padding: 0.35em 0.5em;
      vertical-align: middle; }
      @media (min-width: 768px) {
        .topbar__flat-link button {
          padding: 0.35em 1.5em; } }
      .topbar__flat-link button.btn-rounded--black {
        height: 38px;
        min-width: 65px;
        border-radius: 18px;
        padding: 8px 16px;
        margin: 0 1em;
        background: #1d1d1f;
        color: #ffffff; }
        .topbar__flat-link button.btn-rounded--black:hover, .topbar__flat-link button.btn-rounded--black:focus {
          background: rgba(29, 29, 31, 0.91); }
      .topbar__flat-link button.btn-rounded--primary {
        height: 38px;
        min-width: 65px;
        border-radius: 18px;
        padding: 8px 16px;
        margin: 0 1em;
        background: #1e4acc;
        color: #ffffff; }
        .topbar__flat-link button.btn-rounded--primary:hover, .topbar__flat-link button.btn-rounded--primary:focus {
          background: #0D35AB; }
      @media (min-width: 768px) {
        .topbar__flat-link button.btn-rounded {
          margin: 0 1.25em; } }
      @media (min-width: 1200px) {
        .topbar__flat-link button.btn-rounded {
          margin: 0 0 0 1.25em; } }
      .topbar__flat-link button span {
        align-self: center;
        padding-left: 0.5em;
        font-size: 1.1em; }
    .topbar__flat-link.cart-full button {
      display: flex; }
      .topbar__flat-link.cart-full button span {
        align-self: center; }
    @media (max-width: 640px) {
      .topbar__flat-link.cart-full {
        display: none; } }
    .topbar__flat-link a {
      color: #1d1d1f;
      text-decoration: none;
      text-transform: uppercase;
      padding: 0.35em 0.5em; }
      @media (min-width: 768px) {
        .topbar__flat-link a {
          padding: 0.35em 1.5em; } }
    .topbar__flat-link:hover, .topbar__flat-link:focus {
      cursor: pointer; }
  .topbar__nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 8%;
    padding: 0 !important;
    margin: 0 !important;
    color: #1d1d1f;
    text-decoration: none;
    text-transform: uppercase;
    border-bottom: 3px solid transparent;
    cursor: pointer; }
    .topbar__nav-link a {
      margin-top: 2px;
      text-decoration: none;
      color: #1d1d1f; }
    .topbar__nav-link:hover, .topbar__nav-link:focus {
      border: none;
      border-bottom: 3px solid #a1aeb7; }
    .topbar__nav-link--active {
      border-bottom: 3px solid #1d1d1f !important; }
  .topbar .langPicker {
    height: 100%; }
    .topbar .langPicker .MuiSelect-root {
      height: 100%;
      display: flex;
      padding: 0 !important; }

.nav-container:first-child {
  flex-grow: 1; }
  .nav-container:first-child li.logo-li {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-right: 37px !important; }

.nav-mobile {
  flex-direction: column; }
  .nav-mobile > li {
    display: flex;
    justify-content: center;
    width: 100% !important;
    max-width: unset !important;
    padding: 0.5em !important; }
    .nav-mobile > li > * {
      flex-grow: 1 !important; }

.header_btn {
  display: block;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  transition: all 0.2s linear;
  border-radius: 5px;
  background: transparent;
  border: 2px solid white;
  color: white;
  text-align: center; }

.MuiListItemIcon-root {
  min-width: 25px !important; }

.MuiMenuItem-root {
  width: auto;
  overflow: hidden;
  font-size: 0.8em;
  box-sizing: border-box;
  min-height: 36px;
  font-weight: 400;
  line-height: 1;
  padding-top: 4px;
  padding-bottom: 4px;
  white-space: nowrap; }

@media only screen and (max-width: 767px) {
  .logo {
    display: block;
    cursor: pointer;
    padding: 0.5rem 1rem; }
  .header_navigation {
    width: 100%;
    margin-top: 1rem; }
  .header_navigation ul {
    display: none; }
  .header_navigation ul.show {
    display: block; }
  .header_navigation ul li {
    display: block; }
  .header_navigation ul li a {
    display: block;
    padding: 0.5rem 0;
    transition: all 0.4s linear;
    border-radius: 5px; }
  .header_navigation ul li a:hover {
    background: #4a4a4a; } }

.drawable-logo {
  padding: 0.5em 0px 1em;
  display: flex;
  justify-content: space-evenly;
  align-items: center; }
  .drawable-logo .close-btn {
    cursor: pointer; }

.Component-list-18,
.Component-list-19 {
  padding: 1em 0 0 !important; }
  .Component-list-18 a:first-child,
  .Component-list-19 a:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    color: #ffffff;
    background: #1e4acc !important;
    border: none !important;
    border-radius: 21px;
    margin: 0 1em 1em; }
    .Component-list-18 a:first-child:hover,
    .Component-list-19 a:first-child:hover {
      background: #0D35AB !important; }
  .Component-list-18 .MuiListItem-button:hover + span,
  .Component-list-19 .MuiListItem-button:hover + span {
    color: #1e4acc; }
  .Component-list-18 .MuiListItem-button:focus,
  .Component-list-19 .MuiListItem-button:focus {
    background-color: #0D35AB !important;
    opacity: 1; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.cart_dialog {
  padding: 0 !important;
  margin: 0 !important; }
  .cart_dialog__header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; }
    .cart_dialog__header .mobile-edit-purchase {
      position: absolute;
      left: 3%;
      top: 20%;
      width: 2.5em;
      height: 2.2em;
      padding-top: .6em;
      display: block;
      align-items: center;
      text-align: center;
      color: #ffffff;
      background: #1d1d1f; }
    .cart_dialog__header__title {
      cursor: pointer; }
    .cart_dialog__header__close {
      position: absolute;
      right: 5%;
      line-height: 0;
      transform: translateX(30%);
      height: 35px; }
      .cart_dialog__header__close img {
        width: 16px; }
  .cart_dialog .button--primary,
  .cart_dialog .button--outline {
    margin: .4em 0; }

.MuiDialog-container .MuiDialog-paper {
  padding: 0 !important;
  margin: 0 !important; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.scrollable_list {
  border: none;
  margin-bottom: 1em; }

.cart {
  font-size: 1rem;
  padding: 2em 2em 1em; }
  .cart__header {
    padding: 1em 1.5em; }
    .cart__header__title {
      font-size: 22px;
      font-weight: 800; }
    .cart__header__close {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px !important;
      width: 35px !important; }
    .cart__header .MuiSvgIcon-root {
      font-size: 1.35rem; }
  .cart__list {
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
    border: 1px solid transparent; }
  .cart__total {
    display: flex;
    flex-direction: row;
    margin: 2em .4em 1.5em .6em;
    margin-left: 70px; }
    .cart__total .cart-item-column {
      width: 100%; }
  .cart__item {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .cart__item span {
      vertical-align: text-bottom; }
    .cart__item__info {
      display: grid;
      grid-template-columns: -webkit-min-content 1fr -webkit-min-content;
      grid-template-columns: min-content 1fr min-content;
      width: 100%; }
      .cart__item__info .cart-item-actions {
        display: flex;
        align-items: center; }
        .cart__item__info .cart-item-actions .spinner-grid {
          font-weight: 500;
          scale: 90%; }
          .cart__item__info .cart-item-actions .spinner-grid span {
            min-width: 20px; }
          @media (min-width: 768px) {
            .cart__item__info .cart-item-actions .spinner-grid {
              scale: 100%; }
              .cart__item__info .cart-item-actions .spinner-grid span {
                min-width: 20px; } }
      .cart__item__info .cart-item-column {
        display: flex;
        align-items: center;
        margin-left: 0;
        padding: 0 .5em;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
        .cart__item__info .cart-item-column .title {
          word-break: break-all;
          width: 100%; }
        .cart__item__info .cart-item-column .variant {
          display: flex;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 0.8em;
          font-weight: 200;
          color: #1d1d1f; }
        .cart__item__info .cart-item-column .details {
          display: flex;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 0.8em;
          font-weight: 200;
          color: #a1aeb7; }
      .cart__item__info .cart__item__price {
        display: flex;
        justify-content: flex-end; }
    .cart__item__price .price {
      display: flex;
      align-items: center;
      margin: 0 0.4em; }
  .cart .cart-action {
    justify-content: center;
    text-align: center;
    display: flex; }
    .cart .cart-action button {
      font-size: 1.125em;
      text-transform: none;
      min-height: 52px;
      background: #1e4acc;
      padding: 12px 32px !important; }
      .cart .cart-action button:hover, .cart .cart-action button:focus {
        background: #0D35AB; }
    .cart .cart-action a {
      width: 70%;
      justify-content: center;
      text-align: center;
      display: flex; }
      @media (min-width: 768px) {
        .cart .cart-action a {
          width: 60%; } }
    .cart .cart-action span {
      margin: 0 .2em; }
  .cart .subtotal,
  .cart .cost {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; }
    .cart .subtotal span:first-child,
    .cart .cost span:first-child {
      font-size: 1em; }
  .cart .total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    margin-bottom: 1em; }
  .cart .subtotal,
  .cart .cost, .cart .total {
    height: 39.39px; }
  .cart .spacer {
    margin: 1em 0; }
  .cart .button--primary {
    position: relative; }
    .cart .button--primary .total {
      position: absolute;
      right: 10px;
      transform: translateY(-50%);
      top: 50%;
      color: #ffffff;
      font-size: 1.2em; }
  .cart .button--primary,
  .cart .button--outline {
    margin: 0.4em 0; }

.car {
  position: relative; }
  .car_section {
    background: rgba(194, 209, 217, 0.1);
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
    height: 100vh;
    display: none !important; }
    .car_section .delivery-switch {
      background: #efefef;
      font-size: 0.8em;
      color: #c2d1d9;
      display: flex;
      justify-content: center;
      padding: 3.5%; }
      .car_section .delivery-switch div > :first-child,
      .car_section .delivery-switch div > :last-child {
        margin: 0 5px; }
      .car_section .delivery-switch .active-label {
        color: #1d1d1f; }
    .car_section h3 {
      font-weight: 700;
      color: #1d1d1f;
      text-align: center;
      margin: 0.7em 0; }
    .car_section .cart {
      padding: 0; }
      .car_section .cart__list {
        padding: 3% 0;
        max-height: calc(100vh - 350px); }
      .car_section .cart .spacer {
        margin: 0.5em 0; }
    .car_section .cart__item__info > * {
      margin-right: 0; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.spinner-grid {
  display: flex;
  align-items: center;
  text-align: right; }
  .spinner-grid-menu {
    float: right; }
  .spinner-grid button {
    height: auto;
    padding: 0.2em 0.2em 0 0.2em;
    color: #1d1d1f;
    border: 0.8px solid #c2d1d9;
    border-radius: 2px;
    margin-left: 0;
    margin-right: 0; }
    .spinner-grid button svg {
      color: #1d1d1f;
      width: 20px;
      height: 20px; }
    .spinner-grid button:hover, .spinner-grid button:focus {
      color: #ffffff;
      background: #0D35AB; }
      .spinner-grid button:hover > svg, .spinner-grid button:focus > svg {
        color: #ffffff !important;
        fill: #ffffff !important;
        background: #0D35AB; }
  .spinner-grid span {
    font-weight: 700;
    margin: 0.2em 0.4em;
    font-size: 18px;
    text-align: center; }
  .spinner-grid > * {
    margin: 0.5em;
    font-weight: 500; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.mydialog__container {
  overflow: auto;
  z-index: 500;
  height: 100vh;
  background: rgba(38, 38, 38, 0.8);
  right: 0;
  left: 0;
  top: 0;
  position: fixed; }

.mydialog__wrapper {
  min-height: 100vh;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  margin: 0 auto; }

.mydialog__placeholder {
  height: 0px;
  width: 100%; }

.mydialog__dialog {
  background: #ffffff;
  margin: 0 auto;
  position: relative;
  border-radius: 8px; }
  @media (min-width: 768px) and (max-width: 1199px) {
    .mydialog__dialog {
      border-radius: 12px; } }
  .mydialog__dialog__header {
    position: -webkit-sticky;
    position: sticky;
    background: red;
    top: 0; }
    .mydialog__dialog__header__inner {
      height: 0;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      width: 100%;
      z-index: 503; }
      .mydialog__dialog__header__inner .title {
        transition: height 300ms ease 0s, opacity 300ms ease 0s;
        height: 0;
        background: white;
        width: 100%;
        opacity: 0;
        align-items: center;
        display: flex;
        position: absolute; }
        .mydialog__dialog__header__inner .title::before {
          flex: 1 0 48px;
          content: ""; }
        .mydialog__dialog__header__inner .title__text {
          flex: 1 1 auto;
          text-align: center;
          padding: 12px;
          font-size: 18px;
          text-overflow: ellipsis;
          line-height: 24px;
          color: #000000;
          white-space: nowrap;
          overflow: hidden; }
        .mydialog__dialog__header__inner .title::after {
          flex: 1 10000 48px;
          content: ""; }
        .mydialog__dialog__header__inner .title-sticky {
          opacity: 1;
          box-shadow: #e2e2e2 0px -2px 0px inset;
          height: 64px; }
      .mydialog__dialog__header__inner .close {
        position: absolute;
        left: 1em;
        top: 1.5em;
        width: 48px;
        height: 48px;
        z-index: 501;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        color: black;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
        .mydialog__dialog__header__inner .close:hover, .mydialog__dialog__header__inner .close:focus {
          background: #1d1d1f; }
          .mydialog__dialog__header__inner .close:hover > svg, .mydialog__dialog__header__inner .close:focus > svg {
            fill: #ffffff !important; }
        .mydialog__dialog__header__inner .close-topped {
          top: .6em; }
        .mydialog__dialog__header__inner .close--small {
          left: 1em;
          top: 1em;
          width: 1.55em;
          height: 1.55em; }
        .mydialog__dialog__header__inner .close--light {
          color: black;
          box-shadow: none; }
          .mydialog__dialog__header__inner .close--light:hover, .mydialog__dialog__header__inner .close--light:focus {
            background: transparent;
            box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
            .mydialog__dialog__header__inner .close--light:hover > svg, .mydialog__dialog__header__inner .close--light:focus > svg {
              fill: black !important; }
    .mydialog__dialog__header__hold {
      z-index: 504;
      top: 0;
      position: relative; }
      .mydialog__dialog__header__hold-sticky {
        position: -webkit-sticky;
        position: sticky; }
  .mydialog__dialog__content {
    width: 650px;
    position: relative; }
    .mydialog__dialog__content--dense {
      width: 450px; }
    .mydialog__dialog__content__inner {
      overflow: hidden;
      overflow-y: auto;
      min-height: 60vh;
      max-height: 60vh;
      width: 100%; }
    .mydialog__dialog__content__image {
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 400px;
      width: 100%;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px; }
      .mydialog__dialog__content__image img {
        width: 100%; }
      .mydialog__dialog__content__image .sold-out {
        top: 0;
        left: 0;
        width: 100%;
        min-width: 35em;
        height: 100%;
        padding-top: 25em;
        position: relative;
        text-transform: uppercase;
        background-color: rgba(255, 255, 255, 0.5); }
      .mydialog__dialog__content__image .hide {
        display: none; }
    .mydialog__dialog__content__caption {
      padding: 20px 61px 0; }
      .mydialog__dialog__content__caption h1, .mydialog__dialog__content__caption h2, .mydialog__dialog__content__caption h3, .mydialog__dialog__content__caption h4, .mydialog__dialog__content__caption h5, .mydialog__dialog__content__caption h5 {
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
        margin: 0;
        font-weight: normal; }
      .mydialog__dialog__content__caption div {
        text-align: left;
        padding-top: 16px;
        color: #545454;
        line-height: 20px;
        font-size: 14px; }
    .mydialog__dialog__content__description {
      text-align: left;
      white-space: break-spaces; }
    .mydialog__dialog__content__list {
      list-style: none;
      padding: 0;
      margin: 0; }
      .mydialog__dialog__content__list__head {
        padding: 16px 24px;
        background: #f6f6f6;
        text-align: left; }
        .mydialog__dialog__content__list__head__title {
          font-size: 18px;
          line-height: 24px;
          color: #000000; }
        .mydialog__dialog__content__list__head__subtitle {
          color: #545454;
          line-height: 20px;
          font-size: 14px; }
      .mydialog__dialog__content__list__body {
        padding: 24px;
        display: flex;
        flex-direction: column; }
        .mydialog__dialog__content__list__body__item {
          display: flex;
          align-items: center; }
          .mydialog__dialog__content__list__body__item__name {
            flex: 1 1;
            line-height: 16px;
            font-size: 14px;
            color: #000000;
            text-align: left; }
          .mydialog__dialog__content__list__body__item__price {
            color: #545454;
            line-height: 20px;
            font-size: 14px;
            white-space: nowrap; }
    .mydialog__dialog__content .divition {
      background-color: #e2e2e2;
      margin: 0;
      height: 1px;
      border: none; }
  .mydialog__dialog__footer {
    background: linear-gradient(rgba(255, 255, 255, 0) 2.5%, rgba(255, 255, 255, 0.92) 55.35%);
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    padding: 15px 15px 0 15px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    display: flex;
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    z-index: 509; }
    .mydialog__dialog__footer button {
      height: 30px;
      padding: 10px 14px;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      min-height: 56px;
      line-height: 24px; }
      @media (min-width: 768px) {
        .mydialog__dialog__footer button {
          height: 32px;
          padding: 12px 16px;
          font-size: 18px; } }
      .mydialog__dialog__footer button:first-of-type {
        margin-left: 0; }
      .mydialog__dialog__footer button .button__prefix {
        flex: 1 1; }
      .mydialog__dialog__footer button .button__label {
        flex: 1 1;
        text-transform: capitalize;
        text-align: left; }
      .mydialog__dialog__footer button .button__price {
        flex: 1 1;
        white-space: nowrap;
        text-align: right; }
  .mydialog__dialog .stock-hint {
    width: 100%;
    padding: .5em 0;
    color: #476184;
    font-size: .8em; }
  .mydialog__dialog .required {
    color: red; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.alert {
  font-size: 1rem;
  padding: 5%; }
  .alert__header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; }
    .alert__header h2 {
      font-family: "San Francisco";
      font-weight: 700; }
    .alert__header__close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 5%;
      line-height: 0;
      transform: translateX(30%);
      height: 35px;
      width: 35px; }
      .alert__header__close img {
        width: 20px; }
  .alert .button--primary,
  .alert .button--outline {
    margin: .4em 0; }
  .alert__body {
    padding: 1em 2em; }
  .alert__footer {
    padding: 1em; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.bu {
  transition: flex-grow 500ms; }

.bt {
  max-width: 200px; }
  @media (min-width: 768px) {
    .bt {
      max-width: 300px; } }

.bs {
  flex-grow: 0; }

.af {
  position: relative; }

.d8 {
  flex-grow: 1; }

.modal-select__container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  width: 100%;
  height: 370px; }
  .modal-select__container > *:first-child {
    max-width: 300px; }

.button-address {
  display: flex;
  justify-content: left;
  align-items: center;
  border: none;
  font-size: 1.15em;
  max-width: none !important;
  height: 34px;
  width: 100%;
  letter-spacing: normal !important;
  border-radius: 23px !important;
  background: transparent !important;
  padding: 0.35em 0.5em; }
  .button-address.btn-custom {
    background: #f6f6f6 !important;
    color: #1d1d1f;
    height: 46px; }
    .button-address.btn-custom:hover, .button-address.btn-custom:focus, .button-address.btn-custom.active {
      color: #1d1d1f; }
  @media (min-width: 768px) {
    .button-address {
      height: 46px; } }
  @media (min-width: 768px) {
    .button-address {
      padding: 0px 12px !important;
      background: #f6f6f6 !important; } }
  .button-address img {
    margin-right: 10px; }
  .button-address input {
    height: 20px !important;
    width: 200px !important; }
  .button-address .input-container {
    display: flex;
    flex: 1 1; }
  .button-address div {
    display: flex;
    align-items: center;
    background: transparent;
    text-overflow: ellipsis;
    line-height: 24px;
    text-transform: none !important;
    color: #000000;
    white-space: nowrap;
    overflow: hidden; }

.MuiFilledInput-root .button-address {
  background: #f6f6f6 !important;
  color: #1d1d1f; }
  .MuiFilledInput-root .button-address .css-6q0nyr-Svg {
    display: none; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.group-address-controls {
  display: flex; }
  .group-address-controls .button--plus {
    width: 48px;
    height: 48px;
    margin-left: 5px; }

.modal-select__container {
  padding-top: 5px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 440px; }
  .modal-select__container .group-address-controls {
    max-width: unset !important;
    width: 100%;
    width: -moz-available;
    /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;
    /* Mozilla-based browsers will ignore this. */
    width: stretch;
    padding: 0 2em;
    height: 100%; }
    .modal-select__container .group-address-controls .searchbar {
      flex: 1 1; }
    .modal-select__container .group-address-controls button {
      height: 49px; }
  .modal-select__container .button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1; }

.address-select__custom {
  border-bottom: 1px solid #c2d1d9; }
  .address-select__custom_option_header {
    border-bottom: 1px solid #c2d1d9;
    padding: 0.5em 1.5em;
    align-items: center; }
    .address-select__custom_option_header span {
      margin: 0 !important;
      font-weight: 600;
      color: #1d1d1f; }
    .address-select__custom_option_header a {
      float: right;
      font-weight: 600;
      color: #c2d1d9; }
    .address-select__custom_option_header a:hover,
    .address-select__custom_option_header a:focus {
      cursor: pointer; }
  .address-select__custom_option_body {
    border: 1px solid #1d1d1f;
    margin: 0.5em 1.5em;
    text-align: center; }
    .address-select__custom_option_body a {
      display: block;
      padding: 0.35em 1.5em;
      text-decoration: none;
      color: #1d1d1f; }
    .address-select__custom_option_body a:hover,
    .address-select__custom_option_body a:focus {
      background: #1d1d1f;
      color: #ffffff;
      cursor: pointer; }
  .address-select__custom_option_body:hover, .address-select__custom_option_body:focus {
    border: 1px solid #1d1d1f;
    background: #1d1d1f; }
  .address-select__custom_form_header {
    border-bottom: 1px solid #c2d1d9;
    padding: 0.5em 1.5em;
    display: flex;
    align-items: center; }
    .address-select__custom_form_header span {
      margin: 0 !important;
      font-weight: 600;
      color: #1d1d1f; }
    .address-select__custom_form_header img {
      border: 1px solid #c2d1d9;
      border-radius: 0.3em;
      padding: 0.5em;
      margin-right: 0.8em; }
    .address-select__custom_form_header img:hover,
    .address-select__custom_form_header img:focus {
      cursor: pointer;
      background: #f5f5ed; }
  .address-select__custom_form_body {
    margin: 0.5em 1.5em;
    display: flex;
    flex-direction: row; }
    .address-select__custom_form_body input {
      margin: 0 !important;
      flex-grow: 2;
      border-right: none !important;
      border-bottom-left-radius: 0.3em !important;
      border-top-left-radius: 0.3em !important; }
    .address-select__custom_form_body input:focus {
      outline: none; }
    .address-select__custom_form_body button {
      height: 2.7em;
      border: 1px solid #c2d1d9;
      border-top-right-radius: 0.3em;
      border-bottom-right-radius: 0.3em;
      background: #ffffff; }
      .address-select__custom_form_body button img {
        padding: 0 !important; }
  .address-select__custom_distance {
    display: flex;
    align-items: center;
    text-align: center; }
    .address-select__custom_distance span {
      padding: 0.5em 1.5em;
      font-weight: 600;
      color: #1d1d1f; }
  .address-select__custom_form_footer {
    margin: 1em 13em;
    text-align: center; }
    .address-select__custom_form_footer a {
      text-transform: none !important;
      display: block;
      padding: 0.35em 1.5em;
      text-decoration: none;
      background-color: #1d1d1f;
      color: #f8f8f8; }
    .address-select__custom_form_footer a:hover,
    .address-select__custom_form_footer a:focus {
      cursor: pointer; }

.address-select_items {
  padding: 1em; }
  .address-select_items:hover {
    cursor: pointer;
    background-color: #f6f6f6; }
  .address-select_items_content {
    padding: 0 0 0 0.5em !important; }
    .address-select_items_content .info-name {
      color: #1d1d1f;
      font-weight: 400;
      margin: 0 0.3em;
      font-size: 16px; }
    .address-select_items_content .info-address {
      margin: 0 0.3em;
      display: flex;
      flex-direction: row; }
      .address-select_items_content .info-address span {
        margin: 0 !important;
        flex-grow: 2;
        color: #a1aeb7;
        text-align: left; }
      .address-select_items_content .info-address input {
        width: 1.2em;
        height: 1.2em;
        margin: 0.5em 0.5em;
        padding: 0.5em 1em; }
  .address-select_items_actions {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center; }

.delivery-address-dialog {
  font-size: 1rem;
  padding: 2em; }
  .delivery-address-dialog__header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; }
    .delivery-address-dialog__header.MuiDialogTitle-root {
      padding: 1.5em 2em 1.5em 2em !important; }
    .delivery-address-dialog__header__close {
      position: absolute;
      right: 2em;
      line-height: 0;
      transform: translateX(30%); }
      .delivery-address-dialog__header__close img {
        width: 16px; }
  .delivery-address-dialog__body {
    padding: 0.5em !important;
    margin: 0 !important; }
  .delivery-address-dialog__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; }
    .delivery-address-dialog__footer .button--primary,
    .delivery-address-dialog__footer .button--outline {
      margin: auto;
      padding: 1em 4em; }

.MuiInputLabel-outlined {
  background: white; }

.date_verification_dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1rem; }
  .date_verification_dialog .verification_dialog_header {
    width: 20em;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; }
  .date_verification_dialog .verification_dialog_body {
    width: 20em;
    font-size: 1rem; }

.verification_dialog_header {
  width: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; }
  .verification_dialog_header .MuiTypography-h6 {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.35; }

.verification_dialog_body {
  display: flex;
  justify-content: center;
  padding: 0 2em !important; }
  .verification_dialog_body h3 {
    font-weight: 400; }

.verification_dialog_actions {
  padding: 1.5em 2em !important; }
  .verification_dialog_actions button {
    width: calc(50% - 10px);
    margin: 0 auto; }

.MuiDialogTitle-root {
  padding: 1.5em 2em 0 2em !important; }

.MuiDialogActions-root {
  justify-content: center !important;
  padding: 8px 2em 2em 2em; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.searchbar div button {
  margin-top: auto;
  margin-bottom: auto; }

.list {
  padding: 1.3em 0 2em;
  overflow: hidden;
  overflow-y: auto; }
  .list__bottom {
    height: 37px;
    width: 100%;
    background: #F2F2F2;
    background: linear-gradient(to bottom, transparent, #F2F2F2);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }
  .list .searchbar {
    flex: 1 1; }
  .list_items {
    border-bottom: 1px solid lightgray; }
    .list_items:last-of-type {
      border-bottom: none; }
    .list_items__container:hover {
      background-color: #F2F2F2; }
    .list_items_content {
      padding: 1em 0 1em 2em;
      cursor: pointer; }
      .list_items_content .field1 {
        font-size: medium;
        font-weight: bold; }
    .list_items_actions {
      cursor: pointer;
      padding: 1em 2em 1em 0;
      display: flex;
      justify-content: flex-end;
      align-items: center; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.bu {
  transition: flex-grow 500ms; }

.bt {
  max-width: unset; }
  @media (min-width: 768px) {
    .bt {
      max-width: 450px; } }

.bs {
  flex-grow: 0; }

.af {
  position: relative; }

.d8 {
  flex-grow: 1; }

.button-search {
  display: flex;
  justify-content: left;
  align-items: center;
  border: none;
  max-width: unset;
  background: white;
  width: 100%;
  padding: 0.35em 0.5em;
  letter-spacing: normal !important; }
  @media (min-width: 768px) {
    .button-search {
      max-width: 300px; } }
  @media (min-width: 768px) {
    .button-search {
      background: transparent; } }
  .button-search img {
    margin-right: o; }
    @media (min-width: 768px) {
      .button-search img {
        margin-right: 5px; } }
  .button-search span {
    display: none; }
    @media (min-width: 768px) {
      .button-search span {
        display: inherit; } }
  .button-search div {
    background: transparent;
    flex-grow: 1;
    text-overflow: ellipsis;
    line-height: 24px;
    text-transform: none !important;
    color: #000000;
    white-space: nowrap;
    overflow: hidden; }

.separator {
  border-bottom: 1px solid lightgray;
  width: 100%;
  height: 1px; }

.business-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.6em 0.6em; }
  .business-item__name {
    font-size: small;
    font-weight: bolder;
    padding-top: 0.6em;
    line-height: 1; }
  .business-item__avatar {
    border-radius: 50%;
    overflow: hidden;
    height: 65px;
    width: 65px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .business-item__avatar img {
      height: 100%;
      width: auto; }

.business-detail-item {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  grid-gap: .6em;
  gap: .6em;
  margin: .6em 0; }
  .business-detail-item__header {
    display: flex;
    border-radius: 5px; }
    .business-detail-item__header:hover {
      background-color: #f6f6f6 !important;
      cursor: pointer !important; }
  .business-detail-item__name {
    display: flex;
    font-weight: bold;
    line-height: 1.4;
    font-size: 1.3em; }
  .business-detail-item__avatar {
    border-radius: 50%;
    overflow: hidden;
    height: 55px;
    width: 55px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .business-detail-item__avatar img {
      height: 100%;
      width: auto; }
  .business-detail-item__title-div {
    display: flex; }
  .business-detail-item__title {
    margin-left: 1em;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
  .business-detail-item__more {
    display: flex; }
    .business-detail-item__more__item {
      display: flex;
      grid-gap: 0.3em;
      gap: 0.3em;
      margin-right: 1em; }
      .business-detail-item__more__item__text {
        font-size: 1em !important;
        padding-left: 5px; }

.general-placeholder {
  color: #8F8F8F;
  margin: 2em 0; }

.item-offer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important; }
  .item-offer__image {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 0.5em; }
  .item-offer__name {
    font-weight: bold;
    font-size: 1.1em;
    line-height: 1;
    text-align: left; }
  .item-offer__price {
    color: #565656;
    font-size: small; }
    .item-offer__price::before {
      content: '$'; }

.swipper-actions {
  display: none; }

@media (min-width: 1200px) {
  .swipper-actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 0;
    transform: translateY(-40px); }
    .swipper-actions button {
      width: 38px;
      height: 38px; } }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.tab-container {
  margin: 1em 0;
  width: 70%; }

.autosuggest-container {
  display: flex;
  width: 100%;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 502;
  margin-left: auto;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, white 50%, white 100%);
  padding: 4em 0 0.5em 0; }
  .autosuggest-container > div {
    flex-grow: 1;
    max-width: unset; }
    .autosuggest-container > div > input {
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
      background-color: #f6f6f6 !important; }
      .autosuggest-container > div > input::-webkit-input-placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #5d5d5d !important;
        opacity: 1;
        /* Firefox */ }
      .autosuggest-container > div > input:-ms-input-placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #5d5d5d !important;
        opacity: 1;
        /* Firefox */ }
      .autosuggest-container > div > input::-ms-input-placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #5d5d5d !important;
        opacity: 1;
        /* Firefox */ }
      .autosuggest-container > div > input::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #5d5d5d !important;
        opacity: 1;
        /* Firefox */ }
  .autosuggest-container > button {
    border: none;
    border-bottom: 1px solid #f6f6f6;
    background-color: #f6f6f6 !important;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .autosuggest-container > button:last-child {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px; }

.modal-select__container {
  padding-top: 5px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 440px; }
  .modal-select__container .group-address-controls {
    max-width: unset !important;
    width: 100%;
    width: -moz-available;
    /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;
    /* Mozilla-based browsers will ignore this. */
    width: stretch;
    padding: 0 2em;
    height: 100%; }
    .modal-select__container .group-address-controls .searchbar {
      flex: 1 1; }
    .modal-select__container .group-address-controls button {
      height: 49px; }
  .modal-select__container .button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1; }

.MuiInputBase-input {
  padding: 0 25px; }

.MuiInputLabel-outlined {
  background: white; }

.MuiDialogTitle-root {
  padding: 1.5em 2em 0 2em !important; }

.MuiDialogActions-root {
  justify-content: center !important;
  padding: 8px 2em 2em 2em; }

.skeleton-row {
  margin: 0 !important;
  max-width: calc(100% - 32px);
  display: flex;
  justify-content: flex-start; }
  .skeleton-row .bc-skeleton {
    margin-right: 1em; }
    .skeleton-row .bc-skeleton__avatar {
      margin: 5px 0; }
    .skeleton-row .bc-skeleton__row .MuiSkeleton-root {
      min-width: 277.5px; }
      @media (min-width: 1200px) {
        .skeleton-row .bc-skeleton__row .MuiSkeleton-root {
          min-width: unset; } }
  .skeleton-row__business {
    display: flex;
    flex-direction: column; }
    .skeleton-row__business .bc-skeleton__business {
      flex-direction: column;
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid lightgray;
      margin: 0 !important;
      padding: 1em 0; }
      .skeleton-row__business .bc-skeleton__business__header {
        display: flex;
        flex-direction: row;
        align-items: center; }
      .skeleton-row__business .bc-skeleton__business__offers {
        display: flex;
        grid-gap: 10px;
        gap: 10px;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 1em; }

.swiper-wrapper {
  padding-left: 10px; }
  .swiper-wrapper .swiper-slide {
    position: relative;
    width: 82px;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box; }

.swiper-container {
  grid-gap: 0;
  gap: 0;
  width: 100%;
  height: auto;
  overflow: hidden; }
  .swiper-container .swiper-button-prev,
  .swiper-container .swiper-button-next {
    border: none;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    width: 35px;
    height: 35px;
    margin-top: -22px !important;
    z-index: 10;
    cursor: pointer;
    background-size: 50px 50px;
    background-position: center;
    background-repeat: no-repeat; }
  .swiper-container .swiper-button-prev.swiper-button-disabled,
  .swiper-container .swiper-button-next.swiper-button-disabled {
    display: none; }
  .swiper-container .swiper-button-prev,
  .swiper-container .swiper-container-rtl .swiper-button-next {
    left: 0;
    right: auto; }
  .swiper-container .swiper-button-next,
  .swiper-container .swiper-container-rtl .swiper-button-prev {
    right: 0;
    left: auto; }
  .swiper-container .swiper-button-lock {
    display: none; }

.swiper-wrapper {
  padding-left: 0; }

.swiper-slide {
  border-radius: 5px;
  padding: 1em; }
  .swiper-slide:hover {
    background-color: #f6f6f6 !important;
    cursor: pointer !important; }

.searchUberModal .MuiOutlinedInput-input {
  padding-left: 2em; }
  .searchUberModal .MuiOutlinedInput-input::-webkit-input-placeholder {
    color: black !important; }
  .searchUberModal .MuiOutlinedInput-input:-ms-input-placeholder {
    color: black !important; }
  .searchUberModal .MuiOutlinedInput-input::-ms-input-placeholder {
    color: black !important; }
  .searchUberModal .MuiOutlinedInput-input::placeholder {
    color: black !important; }

.searchUberModal .MuiOutlinedInput-adornedEnd {
  padding-right: 6px; }

@media (max-width: 640px) {
  .searchUberModal .mydialog__wrapper {
    height: 100%; } }

@media (max-width: 640px) {
  .searchUberModal .mydialog__wrapper .mydialog__dialog {
    height: 100%; } }

.searchUberModal .mydialog__wrapper .mydialog__dialog__content__caption {
  padding: 0; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

footer {
  flex-shrink: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  height: auto; }
  @media (min-width: 768px) {
    footer {
      height: 64px; } }

.page-footer {
  background-color: white;
  box-sizing: border-box;
  border-top: 1px solid #c2d1d9;
  font-size: 14px;
  padding-bottom: 0;
  overflow-x: hidden !important;
  padding: 0; }
  .page-footer .row {
    box-sizing: border-box;
    margin-right: -0.5rem;
    margin-right: var(--gutter-compensation, -0.5rem);
    margin-left: -0.5rem;
    margin-left: var(--gutter-compensation, -0.5rem);
    padding: 0; }
  .page-footer .container-fluid {
    padding: 1.5em 1em; }
  .page-footer .wrap {
    box-sizing: border-box;
    max-width: 1366px;
    margin: 0 auto; }
  .page-footer .col-xs {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    box-sizing: border-box;
    padding-right: 0.5rem;
    padding-right: var(--half-gutter-width, 0.5rem);
    padding-left: 0.5rem;
    padding-left: var(--half-gutter-width, 0.5rem); }
  .page-footer .start {
    text-align: center; }
    .page-footer .start p {
      line-height: 1em;
      margin: 0 0 .5em 0; }
  .page-footer .end {
    text-align: center; }
    .page-footer .end p {
      line-height: 1em;
      margin: 0; }
  @media (min-width: 1200px), (min-width: 768px) {
    .page-footer .row {
      padding: 1.5em 0;
      box-sizing: border-box;
      display: flex;
      flex: 0 1 auto;
      flex-direction: row;
      flex-wrap: wrap;
      margin-right: -0.5rem;
      margin-right: var(--gutter-compensation, -0.5rem);
      margin-left: -0.5rem;
      margin-left: var(--gutter-compensation, -0.5rem); }
    .page-footer .container-fluid {
      padding: 0 1em; }
    .page-footer .start {
      text-align: start; }
      .page-footer .start p {
        margin: 0; }
    .page-footer .end {
      text-align: end; }
      .page-footer .end p {
        margin: -7px 0; } }
  .page-footer .end-xs {
    justify-content: flex-end;
    text-align: end; }
  .page-footer .tag {
    color: #000;
    font-weight: normal;
    font-size: 14px !important; }
  .page-footer a {
    text-decoration: none; }
  .page-footer a:hover {
    color: #2c2c2c; }

.row {
  box-sizing: border-box;
  padding: 0.7em 0;
  margin-right: -0.5rem;
  margin-right: var(--gutter-compensation, -0.5rem);
  margin-left: -0.5rem;
  margin-left: var(--gutter-compensation, -0.5rem); }

.container-fluid {
  padding-right: 20px;
  padding-left: 20px; }

.wrap {
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto; }

.col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  box-sizing: border-box;
  padding-right: 0.5rem;
  padding-right: var(--half-gutter-width, 0.5rem);
  padding-left: 0.5rem;
  padding-left: var(--half-gutter-width, 0.5rem); }

.start {
  text-align: center; }

.end {
  text-align: center; }

@media (min-width: 768px) {
  .row {
    padding: 1.5em 2em;
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -0.5rem;
    margin-right: var(--gutter-compensation, -0.5rem);
    margin-left: -0.5rem;
    margin-left: var(--gutter-compensation, -0.5rem); }
  .start {
    text-align: start; }
  .end {
    text-align: end; } }

.end-xs {
  justify-content: flex-end;
  text-align: end; }

.tag {
  color: #000;
  font-weight: normal;
  font-size: 14px !important; }

a {
  text-decoration: none; }

a:hover {
  color: #2c2c2c; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.field-group-2 {
  color: #707a85;
  text-align: left;
  margin: 1em 0;
  display: grid;
  grid-template-columns: 32px 1fr; }
  .field-group-2 .MuiInput-underline:before {
    border-bottom: none !important; }
  .field-group-2 .MuiOutlinedInput-adornedEnd {
    padding-right: 0; }
  .field-group-2 .label {
    grid-column: 2 / 2;
    grid-row: 1 / 1; }
  .field-group-2 .front-icon {
    grid-column: 1 / 1;
    grid-row: 2 / 2;
    transform: translateY(20%); }
  .field-group-2 input,
  .field-group-2 .MuiTextField-root {
    grid-column: 2 / 2;
    grid-row: 2 / 2; }
  .field-group-2 .label,
  .field-group-2 .hint {
    font-size: 0.8em;
    line-height: 1.8;
    font-style: italic; }
    .field-group-2 .label_error,
    .field-group-2 .hint_error {
      color: red; }
  .field-group-2 .hint_alert {
    font-size: 0.8em;
    line-height: 1.8;
    font-style: italic; }
    .field-group-2 .hint_alert_success {
      color: #9abe29; }
    .field-group-2 .hint_alert_error {
      color: red; }

.small .MuiFormControlLabel-label {
  font-size: 1em;
  text-align: left; }

.register,
.register > .section {
  background: #f8f2ed !important;
  padding: 2em 1em !important;
  display: flex;
  justify-content: center; }

.register {
  font-size: 1rem;
  display: flex;
  justify-content: center; }
  .register .container {
    max-width: 750px;
    padding: 5em 0; }
    @media (min-width: 1200px) {
      .register .container {
        padding: 5em 3em; } }
    .register .container .note {
      box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.1);
      width: 100%;
      background: #693478;
      color: #ffffff;
      padding: 0 0.5em;
      margin-bottom: 1em;
      display: grid;
      grid-template-columns: 10% 80% 10%;
      align-items: center;
      border-radius: 0; }
      @media (min-width: 1200px) {
        .register .container .note {
          grid-template-columns: 5% 90% 5%; } }
      .register .container .note p {
        font-size: 0.9rem;
        font-weight: 200;
        text-align: center; }
        @media (min-width: 1200px) {
          .register .container .note p {
            text-align: left; } }
      .register .container .note button {
        background: transparent;
        border: none; }
        .register .container .note button:hover {
          cursor: pointer; }
    .register .container .resend_code_form {
      box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.1);
      width: 100%;
      background: #ffffff;
      padding: 2em 0 1em 0;
      margin: 1em 0;
      text-align: center;
      border-radius: 0; }
      @media (min-width: 1200px) {
        .register .container .resend_code_form {
          padding: 2em 0; } }
      .register .container .resend_code_form .resend_code_title {
        font-size: 0.9rem;
        font-weight: 700;
        margin-bottom: 1em; }
      .register .container .resend_code_form .resend_code {
        text-decoration: none;
        color: #1d1d1f;
        font-size: 0.9rem;
        padding: 0.3em 1.5em 1em 1.5em; }
        .register .container .resend_code_form .resend_code a {
          text-decoration: none; }
        @media (min-width: 1200px) {
          .register .container .resend_code_form .resend_code {
            padding: 0; } }
      .register .container .resend_code_form .resend_code:hover {
        cursor: pointer; }
    .register .container .form {
      box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.1);
      width: 100%;
      background: #ffffff;
      padding: 2em 0;
      text-align: center;
      border-radius: 0; }
      @media (min-width: 1200px) {
        .register .container .form {
          padding: 2em 0; } }
      .register .container .form .title {
        font-weight: 700; }
      .register .container .form .subtitle {
        font-size: 0.9rem;
        padding: 0.3em 2em 1em; }
      .register .container .form .fields {
        display: flex;
        justify-items: center;
        justify-content: center;
        padding: 1em 1.5em; }
        .register .container .form .fields .extra {
          display: grid;
          grid-template-columns: 40% 60%;
          align-items: center;
          font-size: 0.8em;
          margin: 0;
          padding: 0; }
          .register .container .form .fields .extra:first-child {
            text-align: left; }
          .register .container .form .fields .extra:last-child {
            text-align: right;
            margin-right: 1em; }
          .register .container .form .fields .extra span {
            font-size: 1em; }
        .register .container .form .fields .register-link {
          display: flex;
          justify-items: center;
          justify-content: center;
          align-items: center;
          font-size: 0.8em; }
          .register .container .form .fields .register-link p {
            font-size: 1em;
            padding-right: 1em; }
  .register__header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .register__header h2 {
      font-size: 1.7em;
      text-align: center !important;
      font-weight: 600;
      margin-bottom: 0.5em !important; }
  .register-data {
    background: #ffffff;
    width: 100%;
    max-width: 30%;
    min-width: 300px;
    padding: 2em 4em;
    box-shadow: 0 5pt 15pt rgba(0, 0, 0, 0.1); }
    @media (max-width: 500px) {
      .register-data {
        min-width: 100%;
        max-width: 100%; } }
    .register-data__info {
      color: #1d1d1f;
      font-size: 1.3em;
      text-align: center; }
      .register-data__info a {
        color: #2c2c2c; }
  .register .form-container {
    display: flex;
    flex-direction: column;
    padding: 1em 0;
    width: 100%; }
    @media (min-width: 768px) {
      .register .form-container {
        padding: 2em 0; } }
    .register .form-container > * {
      flex-basis: 60px; }

.MuiFormHelperText-root {
  font-size: 0.7rem !important;
  margin-top: 3px !important;
  min-height: 1em !important;
  text-align: right !important; }

.MuiFormHelperText-contained {
  margin: 3px 14px 0 !important; }

.ReactPasswordStrength-strength-desc {
  color: #2c2c2c;
  font-size: 0.7em;
  font-weight: 400;
  text-transform: none; }

.password-strength-meter__progress-container {
  display: flex;
  justify-content: space-between; }
  .password-strength-meter__progress-container p {
    color: rgba(0, 0, 0, 0.54);
    margin: 0;
    font-weight: 400;
    line-height: 1em;
    letter-spacing: 0.03333em;
    font-size: 0.7rem !important;
    margin-top: 3px !important;
    min-height: 1em !important;
    text-align: right !important; }

.password-strength-meter__progress {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  width: 250px;
  height: 0.7em;
  flex-grow: 1;
  padding: 0.2em 1em 0 0; }
  .password-strength-meter__progress::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 3px; }
  .password-strength-meter__progress::-webkit-progress-value {
    border-radius: 2px;
    background-size: 35px 20px, 100% 100%, 100% 100%; }

.password-strength-meter .strength-1::-webkit-progress-value {
  background-color: #f25f5c; }

.password-strength-meter .strength-2::-webkit-progress-value {
  background-color: #ffe066; }

.password-strength-meter .strength-3::-webkit-progress-value {
  background-color: #247ba0; }

.password-strength-meter .strength-4::-webkit-progress-value {
  background-color: #70c1b3; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.back-btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 15px; }
  @media (min-width: 768px) {
    .back-btn {
      padding: 5px 10px; } }
  .back-btn > img {
    padding: 0; }
    @media (min-width: 768px) {
      .back-btn > img {
        padding: 0 0.3em 0; } }
  .back-btn > span {
    display: none; }
    @media (min-width: 768px) {
      .back-btn > span {
        display: inherit;
        margin-right: 0.3em; } }

.react-switch-selector-option-label {
  z-index: 1 !important; }

.restaurant_detail {
  font-weight: 400;
  text-align: center;
  padding: 0 0 3rem; }
  .restaurant_detail__description_container {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 30px; }
  .restaurant_detail__text {
    color: #1d1d1f;
    text-align: left; }
  .restaurant_detail__description {
    font-size: 1.05em;
    color: #1d1d1f;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-grow: 1;
    margin: 0 1em; }
    @media (min-width: 768px) {
      .restaurant_detail__description {
        margin: 0 1.25em; } }
    .restaurant_detail__description_name {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px; }
      .restaurant_detail__description_name h1,
      .restaurant_detail__description_name h2 {
        font-size: 1.5em;
        font-weight: 700;
        line-height: 1.65rem;
        color: #1d1d1f;
        text-transform: none;
        text-transform: initial;
        margin: 1rem 0 0 0;
        text-align: left; }
      .restaurant_detail__description_name h2 > span {
        display: flex;
        line-height: 1.65rem;
        font-size: 13px;
        font-weight: 400;
        color: #000000;
        align-items: center; }
        .restaurant_detail__description_name h2 > span img {
          padding-right: 0.8em; }
        .restaurant_detail__description_name h2 > span .sep {
          color: #c2d1d9; }
        .restaurant_detail__description_name h2 > span .bullet {
          padding: 0 7px;
          color: #1d1d1f; }
      .restaurant_detail__description_name h3 {
        display: inline-flex;
        color: #a1aeb7;
        margin: 0;
        font-size: 1.15em;
        font-weight: 400; }
    .restaurant_detail__description_text {
      color: #1d1d1f;
      margin-right: 1em;
      white-space: nowrap; }
      .restaurant_detail__description_text p {
        margin: 5px 0 0;
        white-space: normal; }
        .restaurant_detail__description_text p.featured {
          font-weight: 600; }
      .restaurant_detail__description_text h3 {
        margin: 0;
        text-align: left;
        color: #c2d1d9; }
    .restaurant_detail__description_price {
      font-size: 0.8em;
      color: #c2d1d9; }
    .restaurant_detail__description_score {
      font-size: 0.7em;
      display: flex;
      align-items: center;
      margin-top: 1em;
      color: #1d1d1f; }
      .restaurant_detail__description_score img {
        transform: translatey(-20%); }
      .restaurant_detail__description_score span {
        margin-left: 1em; }
      .restaurant_detail__description_score span > span {
        color: #c2d1d9;
        margin-left: 0.5em; }
    .restaurant_detail__description_promotion {
      display: flex;
      align-items: center;
      margin-top: 1em;
      padding: 0.3em;
      background: #f2f5f7;
      border-radius: 0.3em;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content; }
      .restaurant_detail__description_promotion img {
        vertical-align: sub; }
      .restaurant_detail__description_promotion span {
        margin: 0 0.5em 0 0.5em;
        color: #1d1d1f; }
    .restaurant_detail__description_image {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      text-align: left;
      height: auto;
      min-height: 10.5em !important;
      overflow: hidden;
      border-radius: 0;
      background-position: center center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      padding: 1.5em 1em;
      /*
      @media (min-width: 992px) {
        height: 10.5em !important;
      }
      */ }
      .restaurant_detail__description_image img {
        width: 100%;
        height: auto; }
      .restaurant_detail__description_image .no-image {
        height: 100%;
        width: 100%;
        object-fit: cover; }
      @media (min-width: 768px) {
        .restaurant_detail__description_image {
          height: 12.5em !important;
          padding: 1.5em 1.25em; } }
      .restaurant_detail__description_image .main-text {
        align-items: flex-start;
        width: 100%;
        margin: 1em 0 0; }
        @media (min-width: 768px) {
          .restaurant_detail__description_image .main-text {
            margin: 1.5em 0 0; } }
        .restaurant_detail__description_image .main-text h1 {
          font-size: 2.2em;
          font-weight: 700;
          color: #ffffff;
          text-transform: capitalize;
          margin: 0; }
        .restaurant_detail__description_image .main-text h3 {
          color: #ffffff;
          font-weight: 600;
          font-size: 1.1em;
          margin: 0; }
  .restaurant_detail__filter {
    padding: 1em;
    background: #f2f5f7; }
  .restaurant_detail__menu {
    padding: 1em 0; }
    .restaurant_detail__menu:first-child h2 {
      margin: .5em .25em; }
    .restaurant_detail__menu h2 {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: -.005em;
      text-transform: none;
      text-align: left;
      color: #1d1d1f;
      margin: 1.5em .25em .5em .25em; }
      @media (min-width: 768px) and (max-width: 1199px) {
        .restaurant_detail__menu h2 {
          font-size: 22px; } }
      @media (min-width: 1200px) {
        .restaurant_detail__menu h2 {
          font-size: 24px; } }
    .restaurant_detail__menu h3 {
      color: #c2d1d9; }
  .restaurant_detail__car {
    position: relative; }
    .restaurant_detail__car_section {
      background: rgba(194, 209, 217, 0.1);
      position: -webkit-sticky;
      position: sticky;
      top: 80px;
      height: 100vh;
      display: none !important; }
      .restaurant_detail__car_section .delivery-switch {
        background: #efefef;
        font-size: 0.8em;
        color: #c2d1d9;
        display: flex;
        justify-content: center;
        padding: 3.5%; }
        .restaurant_detail__car_section .delivery-switch div > :first-child,
        .restaurant_detail__car_section .delivery-switch div > :last-child {
          margin: 0 5px; }
        .restaurant_detail__car_section .delivery-switch .active-label {
          color: #1d1d1f; }
      .restaurant_detail__car_section h3 {
        font-weight: 700;
        color: #1d1d1f;
        text-align: center;
        margin: 0.7em 0; }
      .restaurant_detail__car_section .cart {
        padding: 0; }
        .restaurant_detail__car_section .cart__list {
          padding: 3% 0;
          max-height: calc(100vh - 350px); }
        .restaurant_detail__car_section .cart .spacer {
          margin: 0.5em 0; }
      .restaurant_detail__car_section .cart__item__info > * {
        margin-right: 0; }

.menu_tabs {
  width: 100vw;
  display: flex;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  z-index: 1;
  background: #f9fafb;
  box-shadow: inset 0 1px #fff, 0 1px 3px rgba(34, 25, 25, 0.4);
  -moz-box-shadow: inset 0 1px #fff, 0 1px 3px rgba(34, 25, 25, 0.4);
  -webkit-box-shadow: inset 0 1px #fff, 0 1px 3px rgba(34, 25, 25, 0.4); }
  @media (min-width: 1200px) {
    .menu_tabs {
      width: unset;
      top: 80px; } }
  .menu_tabs .container {
    display: flex;
    align-items: center;
    margin: 0 !important;
    justify-content: space-between;
    width: 100%; }
    @media (min-width: 768px) {
      .menu_tabs .container {
        width: 90%; } }
    .menu_tabs .container > *:first-child {
      display: flex;
      align-items: center; }
  .menu_tabs_items {
    cursor: pointer;
    text-transform: uppercase;
    color: #1d1d1f;
    font-weight: 500;
    font-size: 0.8em;
    border-bottom: 3px solid transparent; }
    .menu_tabs_items a {
      display: block;
      padding: 1em;
      margin: 0;
      color: #1d1d1f;
      text-decoration: none; }
    .menu_tabs_items.selected {
      border-bottom: 3px solid #1d1d1f; }

.menu-list {
  padding: 1em; }
  .menu-list .container {
    width: 100%;
    margin: 0 auto; }

.cart-section {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 201;
  padding: 1em; }
  .cart-section > button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-size: 1.2em;
    text-transform: none;
    height: 58px;
    border-radius: 29px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    padding: 14px;
    /*
    span {
      vertical-align: sub;
      vertical-align: -webkit-baseline-middle;
    }
    */ }
    .cart-section > button:hover, .cart-section > button:focus {
      background: #0D35AB; }
    .cart-section > button > *:nth-child(1) {
      display: flex;
      flex-grow: 0;
      align-items: center;
      justify-content: center;
      left: 15px;
      background: rgba(255, 255, 255, 0.15);
      min-width: 32px;
      height: 32px;
      border-radius: 18px;
      line-height: 1.2em; }
    .cart-section > button > *:nth-child(2) {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      padding: 0 1em; }
    .cart-section > button > *:nth-child(3) {
      right: 15px;
      font-size: 1.125em;
      font-weight: 400;
      line-height: 1em;
      display: flex;
      flex-grow: 0;
      align-items: center; }
  .cart-section__dialog-wrapper {
    background: #f8f8f8;
    height: 100%; }

.MuiTabScrollButton-root {
  width: 42px !important; }
  .MuiTabScrollButton-root.Mui-disabled {
    width: 20px !important; }

.MuiTab-textColorPrimary.Mui-selected,
.MuiSvgIcon-root {
  fill: #1d1d1f !important; }

.MuiSvgIcon-fontSizeSmall {
  font-size: 1.5rem !important; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.business-card-list {
  display: grid;
  grid-row-gap: 1em;
  grid-row-gap: 1em;
  row-gap: 1em;
  grid-template-columns: 1fr;
  grid-column-gap: 0;
  -webkit-column-gap: 0;
  grid-column-gap: 0;
  column-gap: 0; }
  @media (min-width: 768px) {
    .business-card-list {
      grid-template-columns: repeat(2, calc(50% - .85em));
      grid-column-gap: 1em;
      -webkit-column-gap: 1em;
      grid-column-gap: 1em;
      column-gap: 1em;
      justify-content: center; } }
  @media (min-width: 1200px) {
    .business-card-list {
      grid-template-columns: repeat(3, calc(33.33% - .85em)); } }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.offer-item {
  display: flex;
  width: 100%;
  height: 9rem;
  border: 1px solid #efefef;
  border-radius: 8px;
  background-color: #ffffff;
  cursor: pointer;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .offer-item {
      height: 9rem;
      width: 100; } }
  .offer-item .offer-image-box {
    text-align: center;
    overflow: hidden;
    display: flex;
    justify-content: center;
    height: 9rem;
    width: calc(35%);
    max-width: 9rem;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; }
    @media (min-width: 768px) {
      .offer-item .offer-image-box {
        height: 9rem;
        width: 11rem; } }
    .offer-item .offer-image-box img {
      height: 100%;
      width: auto; }
    .offer-item .offer-image-box .thumbnails-image {
      flex-shrink: 0;
      min-width: 100%;
      min-height: 100%; }
    .offer-item .offer-image-box .no-image {
      object-fit: cover;
      position: absolute; }
  .offer-item .offer-content {
    width: 100%;
    display: grid;
    grid-template-rows: 60% 40%;
    position: relative;
    top: 0;
    left: 0; }
    .offer-item .offer-content .offer-detail {
      padding: 1em 1em 0 1em;
      text-align: left;
      display: grid; }
      .offer-item .offer-content .offer-detail .offer-name {
        font-size: 1.2em;
        font-weight: 600;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden; }
      .offer-item .offer-content .offer-detail .offer-price {
        padding-top: 0.5em;
        font-size: 1em;
        color: #707a85; }
    .offer-item .offer-content .offer-actions {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      padding: 0.5em 1em;
      text-align: right;
      display: flex;
      justify-content: flex-end; }

.shadow {
  border: none !important;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2); }

.sold-out-offer {
  position: absolute; }

.sold-out {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 4em;
  position: relative;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.5); }

.hide {
  display: none; }

.MuiDialogContent-dividers {
  padding: 0 !important;
  border-top: none !important;
  border-bottom: none !important; }

.additions-dialog .additions-header {
  font-size: 0.9rem;
  font-weight: 700;
  text-align: center;
  align-items: center;
  color: #1d1d1f; }
  @media (min-width: 768px) {
    .additions-dialog .additions-header {
      font-size: 16px; } }
  .additions-dialog .additions-header .additions-image img {
    max-width: 100%;
    width: 100%;
    max-height: 60vh;
    height: auto; }
  .additions-dialog .additions-header .additions-image .close {
    position: absolute;
    top: 1%;
    right: 3%;
    padding: 0.5em;
    background: #efefef; }
    @media (min-width: 1200px) {
      .additions-dialog .additions-header .additions-image .close {
        top: 2%;
        right: 4%; } }
    @media (min-width: 768px) and (max-width: 1199px) {
      .additions-dialog .additions-header .additions-image .close {
        top: 2%;
        right: 3%; } }
    .additions-dialog .additions-header .additions-image .close img {
      width: 16px; }
  .additions-dialog .additions-header .offer-name {
    margin: 0.5em 0 !important;
    color: #1d1d1f; }
  .additions-dialog .additions-header .offer-description {
    margin: 0.5em 0 !important;
    color: #707a85; }
  .additions-dialog .additions-header .offer-price {
    margin: 0 !important;
    color: #707a85; }

.additions-dialog .additions-section-title {
  background: #f5f5f5; }
  .additions-dialog .additions-section-title h3 {
    padding: 0.8em 1em;
    margin: 0;
    color: #1d1d1f; }

.additions-dialog .additions-content {
  padding: 0 0.5em 1em 0.5em; }
  .additions-dialog .additions-content .additions-items {
    margin: 0.2em 0;
    display: flex;
    align-items: center;
    padding: 0.2em 0.5em;
    border-bottom: 1px solid #eaeaea;
    background: #ffffff !important; }
    .additions-dialog .additions-content .additions-items .info {
      min-height: 2.4em;
      align-items: center; }
      .additions-dialog .additions-content .additions-items .info .offer {
        color: #1d1d1f;
        margin: 0 0.5em;
        text-transform: capitalize; }
      .additions-dialog .additions-content .additions-items .info .count {
        color: #707a85;
        margin-right: 0.5em; }
    .additions-dialog .additions-content .additions-items .actions {
      display: flex;
      align-items: center; }
    .additions-dialog .additions-content .additions-items .check {
      color: #1d1d1f; }
    .additions-dialog .additions-content .additions-items .delete {
      padding-left: 0.5em;
      border-left: 1px solid #eaeaea;
      color: #e50914; }

.additions-dialog .offer-total-cost {
  padding: 1em;
  color: #1d1d1f; }

.additions-footer {
  padding: 0.5em;
  border-top: 1px solid #c2d1d9;
  align-items: center; }
  .additions-footer button {
    padding: 0.5em 3em; }

#root {
  font-family: San Francisco, sans-serif;
  text-align: center; }

.switch-selector-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: San Francisco, sans-serif;
  text-align: center;
  color: #000000;
  max-width: 440px;
  min-width: 280px;
  height: 46px; }

.react-switch-selector-wrapper {
  width: auto; }

.react-switch-selector-option {
  border-radius: 24px; }

.react-switch-selector-option-label p {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.2;
  margin: 2px 0 0 0;
  font-weight: 600;
  color: #000000; }
  .react-switch-selector-option-label p > span {
    font-weight: 400;
    color: #747474; }

.react-switch-selector-option-label:has(> .disabled) {
  pointer-events: none; }
  .react-switch-selector-option-label:has(> .disabled) > .disabled {
    color: darkgray; }
    .react-switch-selector-option-label:has(> .disabled) > .disabled > span {
      color: darkgray; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.form_container {
  flex-grow: 1;
  font-size: .8em;
  padding: 2em 1.5em; }
  @media (min-width: 768px) {
    .form_container {
      padding: 1em !important; } }
  .form_container__body {
    overflow: visible !important; }
    .form_container__body__fields {
      display: block !important;
      padding: .5em 0; }
      @media (min-width: 768px) {
        .form_container__body__fields {
          display: block !important;
          padding: .5em; } }
      .form_container__body__fields button {
        width: 100%; }
      .form_container__body__fields .margin-left {
        margin-left: .3em; }
      .form_container__body__fields .margin-right {
        margin-right: .3em; }
      .form_container__body__fields > div:first-child {
        margin-bottom: 1em !important; }
  .form_container .form_actions {
    position: relative;
    display: flex;
    padding: 2.8em 0 1.5em 0; }
    @media (min-width: 768px) {
      .form_container .form_actions {
        padding: 2.8em .5em 1.5em .5em; } }
    .form_container .form_actions button {
      width: 100% !important;
      max-width: none;
      font-size: 1.2em; }
      @media (min-width: 768px) {
        .form_container .form_actions button {
          font-size: 0.9rem; } }
    .form_container .form_actions .button_align_left {
      padding-left: .6em; }
    .form_container .form_actions .button_align_right {
      padding-right: .6em; }
    .form_container .form_actions .button_align_center {
      display: flex;
      justify-content: center;
      text-align: center; }

.loading-message {
  background: white;
  padding: 2em;
  display: flex;
  flex-direction: column;
  color: black;
  justify-content: space-evenly;
  width: 400px;
  align-items: center; }
  .loading-message #loader {
    width: 70px;
    height: 70px;
    left: unset;
    top: unset;
    margin: 0; }

._loading_overlay_wrapper--active,
.loading_overlay_wrapper--active {
  z-index: 1500; }

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  #preloader body {
    background-color: #222; }

.preloader-center {
  display: flex;
  justify-content: center;
  align-items: center; }

#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #ff4949;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite; }

#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #476184;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite; }

#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #ffffff;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite; }

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main, .login {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.login {
  display: flex;
  justify-items: center;
  justify-content: center;
  padding: 20px 0; }
  @media (min-width: 768px) {
    .login {
      padding: 64px; } }
  .login .container {
    max-width: 500px; }
    @media (min-width: 768px) {
      .login .container {
        max-width: 700px; } }
    .login .container .note {
      box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.1);
      width: 100%;
      background: #693478;
      color: #ffffff;
      padding: 0 0.5em;
      margin-bottom: 1em;
      display: grid;
      grid-template-columns: 10% 80% 10%;
      align-items: center;
      border-radius: 0; }
      @media (min-width: 1200px) {
        .login .container .note {
          grid-template-columns: 5% 90% 5%; } }
      .login .container .note p {
        font-size: 0.9rem;
        font-weight: 200;
        text-align: center; }
        @media (min-width: 1200px) {
          .login .container .note p {
            text-align: left; } }
      .login .container .note button {
        background: transparent;
        border: none; }
        .login .container .note button:hover {
          cursor: pointer; }
    .login .container .form {
      width: 100%;
      background: #ffffff;
      padding: 1em 0.5em;
      text-align: center;
      border-radius: 0; }
      @media (min-width: 1200px) {
        .login .container .form {
          padding: 2em 5em; } }
      .login .container .form .title {
        font-family: "Nunito Sans";
        font-size: 2rem;
        font-weight: 300;
        line-height: 1.5rem;
        background-image: none;
        color: #094296;
        min-height: 55px;
        margin: 0 0 0 .5rem; }
        @media (min-width: 768px) {
          .login .container .form .title {
            font-size: 2rem;
            line-height: 2.375rem;
            background-image: none;
            min-height: 0;
            margin: 0;
            margin: initial; } }
        .login .container .form .title span {
          display: block;
          margin: 15px auto 0;
          background: #e50914;
          width: 36px;
          height: 2px; }
      .login .container .form .subtitle {
        font-family: "Nunito Sans";
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5rem;
        display: flex;
        padding: 30px 30px 20px;
        color: black; }
      .login .container .form .fields {
        display: flex;
        justify-items: center;
        justify-content: center;
        padding: 0.5em 0; }
        .login .container .form .fields-error {
          display: block;
          max-width: 300px;
          width: 100%;
          text-align: left;
          font-size: 0.875rem;
          line-height: 1.5;
          font-family: "Nunito Sans";
          font-weight: 400;
          color: #ff424d;
          margin: 0;
          height: 0; }
        .login .container .form .fields-form-error {
          height: auto;
          text-align: center;
          display: block;
          background: red;
          color: white !important;
          padding: 0.5em;
          font-weight: bold !important;
          border-radius: 8px; }
        .login .container .form .fields .PhoneInput {
          margin-bottom: 0.5em; }
        .login .container .form .fields .captcha {
          display: block;
          margin: 0 auto;
          width: 304px; }
          .login .container .form .fields .captcha > div:first-child {
            margin-bottom: 0.5em; }
          .login .container .form .fields .captcha + .fields-error {
            display: block;
            margin: 0 auto;
            width: 304px; }
        .login .container .form .fields .extra {
          display: grid;
          grid-template-columns: 40% 60%;
          align-items: center;
          font-size: 0.8em;
          margin: 0;
          padding: 0; }
          .login .container .form .fields .extra:first-child {
            text-align: left; }
          .login .container .form .fields .extra:last-child {
            text-align: right;
            margin-right: 1em; }
          .login .container .form .fields .extra span {
            font-size: 1em; }
        .login .container .form .fields button {
          font-size: 1rem;
          font-weight: 400;
          text-transform: none !important;
          line-height: 1.5;
          height: 50px;
          margin: 0 auto;
          outline: 0px;
          border: 0px;
          font-family: "Nunito Sans";
          width: 100%;
          min-width: 64px;
          max-width: 295px;
          border-radius: 8px;
          color: white;
          background-color: #1e4acc;
          box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
          font-weight: 700;
          transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
        .login .container .form .fields .register-link {
          display: flex;
          justify-items: center;
          justify-content: center;
          align-items: center; }
          .login .container .form .fields .register-link p {
            font-size: 0.85rem;
            font-weight: 400;
            line-height: 1.15rem; }

.PhoneInput {
  width: 100%;
  box-sizing: border-box;
  font-size: 1em;
  font-weight: 400;
  border: 1px solid #cecece;
  border-radius: 4px;
  margin: 2em 0 0; }
  .PhoneInputInput {
    padding: 3px 7px;
    color: #2a2a2a;
    background-color: #ffffff;
    border-radius: 4px;
    height: 50px;
    border: none;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.38rem;
    outline: none; }

.PhoneInputCountrySelect::-ms-expand {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: ""; }

.PhoneInputCountry {
  margin-right: 0;
  margin-left: 12px; }

.PhoneInputCountryIcon--border {
  box-shadow: none; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main, .pin-to-login {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.pin-to-login {
  display: flex;
  justify-items: center;
  justify-content: center;
  padding: 20px 0; }
  @media (min-width: 768px) {
    .pin-to-login {
      padding: 64px; } }
  .pin-to-login .container {
    max-width: 500px; }
    @media (min-width: 768px) {
      .pin-to-login .container {
        max-width: 700px; } }
    .pin-to-login .container .note {
      box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.1);
      width: 100%;
      background: #693478;
      color: #ffffff;
      padding: 0 0.5em;
      margin-bottom: 1em;
      display: grid;
      grid-template-columns: 10% 80% 10%;
      align-items: center;
      border-radius: 0; }
      @media (min-width: 1200px) {
        .pin-to-login .container .note {
          grid-template-columns: 5% 90% 5%; } }
      .pin-to-login .container .note p {
        font-size: 0.9rem;
        font-weight: 200;
        text-align: center; }
        @media (min-width: 1200px) {
          .pin-to-login .container .note p {
            text-align: left; } }
      .pin-to-login .container .note button {
        background: transparent;
        border: none; }
        .pin-to-login .container .note button:hover {
          cursor: pointer; }
    .pin-to-login .container .form {
      width: 100%;
      background: #ffffff;
      padding: 1em 0.5em;
      text-align: center;
      border-radius: 0; }
      @media (min-width: 1200px) {
        .pin-to-login .container .form {
          padding: 2em 5em; } }
      .pin-to-login .container .form .title {
        font-size: 2rem;
        line-height: 1.5rem;
        letter-spacing: -.025em;
        background-image: none;
        color: #1d1d1f;
        min-height: 55px;
        margin: 0;
        font-family: "Nunito Sans";
        font-weight: 300;
        text-align: center;
        color: #094296;
        margin-bottom: 0.5rem; }
        @media (min-width: 768px) {
          .pin-to-login .container .form .title {
            font-size: 2.1rem;
            line-height: 2.375rem;
            background-image: none;
            min-height: 0;
            margin: 0;
            margin: initial; } }
        .pin-to-login .container .form .title span {
          display: block;
          margin: 15px auto 0;
          background: #e50914;
          width: 36px;
          height: 2px; }
      .pin-to-login .container .form .subtitle {
        font-family: "Nunito Sans";
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5rem;
        display: flex;
        padding: 30px 30px 20px;
        color: black; }
      .pin-to-login .container .form .fields {
        display: flex;
        justify-items: center;
        justify-content: center;
        padding: 0 0 0.5em 0; }
        .pin-to-login .container .form .fields-error {
          display: block;
          max-width: 300px;
          width: 100%;
          text-align: left;
          font-size: 0.875rem;
          line-height: 1.5;
          font-family: "Nunito Sans";
          font-weight: 400;
          color: #ff424d;
          margin: 0;
          height: 0;
          text-align: center; }
        .pin-to-login .container .form .fields-form-error {
          height: auto;
          text-align: center;
          display: block;
          background: red;
          color: white !important;
          padding: 0.5em;
          font-weight: bold !important;
          border-radius: 8px; }
        .pin-to-login .container .form .fields .error-message-container {
          display: flex;
          justify-content: center;
          padding-bottom: 2em; }
        .pin-to-login .container .form .fields .extra {
          display: grid;
          grid-template-columns: 40% 60%;
          align-items: center;
          font-size: 0.8em;
          margin: 0;
          padding: 0; }
          .pin-to-login .container .form .fields .extra:first-child {
            text-align: left; }
          .pin-to-login .container .form .fields .extra:last-child {
            text-align: right;
            margin-right: 1em; }
          .pin-to-login .container .form .fields .extra span {
            font-size: 1em; }
        .pin-to-login .container .form .fields .action {
          padding-top: 1.5em; }
        .pin-to-login .container .form .fields button {
          font-size: 1rem;
          font-weight: 400;
          text-transform: none !important;
          line-height: 1.5;
          height: 50px;
          margin: 0 auto;
          outline: 0px;
          border: 0px;
          font-family: "Nunito Sans";
          width: 100%;
          min-width: 64px;
          max-width: 295px;
          border-radius: 8px;
          color: white;
          background-color: #1e4acc;
          box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
          font-weight: 700;
          transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
          .pin-to-login .container .form .fields button span {
            color: #1e4acc; }
          .pin-to-login .container .form .fields button:hover span, .pin-to-login .container .form .fields button:focus span {
            color: #0D35AB; }
        .pin-to-login .container .form .fields .register-link {
          display: flex;
          justify-items: center;
          justify-content: center;
          align-items: center;
          font-size: 0.5em; }
          .pin-to-login .container .form .fields .register-link p {
            text-align: center;
            font-size: 0.9rem;
            color: #1d1d1f;
            padding-right: 0.8em; }
          .pin-to-login .container .form .fields .register-link .button-link {
            font-weight: 400;
            color: #1e4acc; }
          .pin-to-login .container .form .fields .register-link:hover {
            cursor: pointer; }

.react-code-input {
  /* Firefox */ }
  .react-code-input input::-webkit-outer-spin-button,
  .react-code-input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .react-code-input input[type="number"] {
    -moz-appearance: textfield; }

.resend-pin-modal {
  justify-content: center; }
  .resend-pin-modal__header {
    font-size: 22px !important;
    font-weight: 800 !important;
    margin-bottom: 1em;
    text-align: center;
    padding: 1em 2em !important; }
    .resend-pin-modal__header h2 {
      line-height: 1;
      font-size: 1.2rem;
      font-family: "Nunito Sans";
      font-weight: 400;
      text-align: center;
      flex: 0 0 auto;
      margin: 0px;
      padding: 0;
      color: #094296; }
    .resend-pin-modal__header__close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 1.1em;
      right: 1em;
      height: 44px;
      width: 44px;
      border-radius: 22px; }
  .resend-pin-modal .content-child {
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 0px auto; }
    .resend-pin-modal .content-child .resend_code {
      text-decoration: none;
      font-size: 16px;
      text-align: center;
      color: #1e4acc;
      margin: 0.35em 2em;
      padding: 0.8em 1.5em 0.8em 1.5em;
      cursor: pointer;
      font-family: "Nunito Sans";
      min-width: 64px;
      border-radius: 8px;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border: 1px solid rgba(30, 74, 204, 0.5);
      color: #1e4acc;
      width: 55%;
      font-size: 1rem;
      padding: 10px 33px;
      line-height: 1.5;
      font-weight: 700; }
      .resend-pin-modal .content-child .resend_code:hover, .resend-pin-modal .content-child .resend_code:focus {
        color: #ffffff;
        background: #0D35AB;
        border: 1px solid #0D35AB; }
        .resend-pin-modal .content-child .resend_code:hover a, .resend-pin-modal .content-child .resend_code:focus a {
          color: #ffffff; }
      .resend-pin-modal .content-child .resend_code--cancel {
        margin: 1.5em;
        color: #ffffff;
        background-color: #1e4acc; }
        .resend-pin-modal .content-child .resend_code--cancel:hover, .resend-pin-modal .content-child .resend_code--cancel:focus {
          background: #0D35AB;
          border: 1px solid #0D35AB; }
      .resend-pin-modal .content-child .resend_code a {
        text-decoration: none;
        color: #1e4acc; }

.modify-button__container {
  position: relative;
  margin-top: 24px;
  max-width: 100%;
  font-family: "Nunito Sans";
  font-size: 0.928571rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 16px;
  white-space: nowrap;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: default;
  outline: 0px;
  text-decoration: none;
  padding: 0px;
  vertical-align: middle;
  box-sizing: border-box;
  background-color: transparent;
  border: 1px solid rgba(81, 178, 112, 0.7);
  color: #51b270;
  padding: 0 1em; }

.modify-button__icon {
  font-size: 22px;
  cursor: pointer;
  margin: 0px 5px 0px -6px;
  color: rgba(81, 178, 112, 0.7);
  transform: translateX(10px);
  height: 24px;
  width: 24px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.recover-password {
  background: #f8f2ed;
  display: flex;
  justify-items: center;
  justify-content: center; }
  .recover-password .container {
    max-width: 750px;
    padding: 5em 0; }
    @media (min-width: 1200px) {
      .recover-password .container {
        padding: 5em 3em; } }
    .recover-password .container .note {
      box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.1);
      width: 100%;
      background: #693478;
      color: #ffffff;
      padding: 0 .5em;
      margin-bottom: 1em;
      display: grid;
      grid-template-columns: 10% 80% 10%;
      align-items: center;
      border-radius: 0; }
      @media (min-width: 1200px) {
        .recover-password .container .note {
          grid-template-columns: 5% 90% 5%; } }
      .recover-password .container .note p {
        font-size: 0.9rem;
        font-weight: 200;
        text-align: center; }
        @media (min-width: 1200px) {
          .recover-password .container .note p {
            text-align: left; } }
      .recover-password .container .note button {
        background: transparent;
        border: none; }
        .recover-password .container .note button:hover {
          cursor: pointer; }
    .recover-password .container .form {
      box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.1);
      width: 100%;
      background: #ffffff;
      padding: 2em 0;
      text-align: center;
      border-radius: 0; }
      @media (min-width: 1200px) {
        .recover-password .container .form {
          padding: 2em 0; } }
      .recover-password .container .form .title {
        font-weight: 700; }
      .recover-password .container .form .subtitle {
        font-size: 0.9rem;
        padding: .3em 2em 1em; }
      .recover-password .container .form .fields {
        display: flex;
        justify-items: center;
        justify-content: center;
        padding: 1em 1.5em; }
        .recover-password .container .form .fields .extra {
          display: grid;
          grid-template-columns: 40% 60%;
          align-items: center;
          font-size: .8em;
          margin: 0;
          padding: 0; }
          .recover-password .container .form .fields .extra:first-child {
            text-align: left; }
          .recover-password .container .form .fields .extra:last-child {
            text-align: right;
            margin-right: 1em; }
          .recover-password .container .form .fields .extra span {
            font-size: 1em; }
        .recover-password .container .form .fields .register-link {
          display: flex;
          justify-items: center;
          justify-content: center;
          align-items: center;
          font-size: .8em; }
          .recover-password .container .form .fields .register-link p {
            font-size: 1em;
            padding-right: 1em; }
    .recover-password .container .resend_code_form {
      box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.1);
      width: 100%;
      background: #ffffff;
      padding: 2em 0 1em 0;
      margin: 1em 0;
      text-align: center;
      border-radius: 0; }
      @media (min-width: 1200px) {
        .recover-password .container .resend_code_form {
          padding: 2em 0; } }
      .recover-password .container .resend_code_form .resend_code_title {
        font-size: 0.9rem;
        font-weight: 700;
        margin-bottom: 1em; }
      .recover-password .container .resend_code_form .resend_code {
        text-decoration: none;
        color: #1d1d1f;
        font-size: 0.9rem;
        padding: .3em 1.5em 1em 1.5em; }
        .recover-password .container .resend_code_form .resend_code a {
          text-decoration: none; }
        @media (min-width: 1200px) {
          .recover-password .container .resend_code_form .resend_code {
            padding: 0; } }
      .recover-password .container .resend_code_form .resend_code:hover {
        cursor: pointer; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.h1-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.5em; }
  .h1-container > h1 {
    margin: 0 !important;
    font-weight: 600;
    font-size: large;
    letter-spacing: -.005em; }
    @media (min-width: 768px) {
      .h1-container > h1 {
        font-size: xx-large; } }
  @media (min-width: 768px) {
    .h1-container {
      padding: 2.5em 1em 1em; } }

.skeleton-row {
  margin-left: 1.5em;
  max-width: calc(100% - 32px);
  margin: 0 auto; }
  .skeleton-row .restaurants-skeleton__row .MuiSkeleton-root {
    min-width: 277.5px; }
    @media (min-width: 1200px) {
      .skeleton-row .restaurants-skeleton__row .MuiSkeleton-root {
        min-width: unset; } }

.search-wrap {
  display: flex;
  justify-content: center;
  padding: 1em; }

.address-wrap {
  display: flex;
  justify-content: center;
  padding: 1em 1em 0 1em; }

.separator {
  border-bottom: 1px solid lightgray;
  width: 100%;
  height: 1px; }

.restaurant-carousels-list .wrap {
  box-sizing: border-box;
  max-width: 100%; }

.restaurant-carousels-list .container-fluid {
  padding-right: 0;
  padding-left: 0;
  max-width: calc(100% - 0);
  margin-bottom: 1.25em; }
  @media (min-width: 768px) and (max-width: 1199px) {
    .restaurant-carousels-list .container-fluid {
      padding-right: 1.125em;
      padding-left: 1.125em; } }

.restaurant-carousels-list .scroll-fluid {
  padding-right: 0;
  padding-left: 0;
  max-width: calc(100% - 0);
  margin-bottom: 1.25em; }

.restaurant-carousels-list .not-found-container {
  display: flex;
  justify-content: flex-start;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  .restaurant-carousels-list .not-found-container h2 {
    font-weight: 600;
    color: #1d1d1f;
    margin-bottom: 5px; }
  .restaurant-carousels-list .not-found-container h3 {
    margin-top: 12px;
    color: #c2d1d9; }
    @media (min-width: 768px) {
      .restaurant-carousels-list .not-found-container h3 {
        margin-top: 18px; } }

.restaurant-carousels-list .not-found-wrapper {
  padding: 2em 0;
  display: flex;
  justify-content: center;
  align-self: center; }

.restaurant-carousels-list .default_page_message {
  margin: 1.5em; }
  .restaurant-carousels-list .default_page_message h2 {
    color: #1d1d1f;
    text-align: center;
    font-weight: 600; }
  .restaurant-carousels-list .default_page_message span {
    text-align: left;
    color: #1d1d1f; }

.restaurants-skeleton {
  margin: 0 auto; }
  .restaurants-skeleton__header {
    display: flex;
    flex-direction: column;
    margin-top: 2em; }
    .restaurants-skeleton__header__title {
      display: flex;
      flex-direction: column; }
    .restaurants-skeleton__header__subtitle {
      display: flex;
      justify-content: space-between;
      margin-top: 0.5em; }
    .restaurants-skeleton__header__nav {
      display: flex;
      grid-gap: 0.5em;
      gap: 0.5em; }
      .restaurants-skeleton__header__nav span {
        border-radius: 50%; }
  .restaurants-skeleton__row {
    display: flex;
    grid-gap: 30px;
    gap: 30px;
    margin-top: 0.5em; }

.infinite-scroll-component {
  overflow: hidden !important;
  overflow-y: auto; }

.MuiSkeleton-wave {
  border-radius: 8px; }
  @media (min-width: 768px) and (max-width: 1199px) {
    .MuiSkeleton-wave {
      border-radius: 12px; } }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

#cuisine-section {
  color: #1d1d1f;
  margin: 0;
  padding: 0 1em !important; }
  @media (min-width: 768px) {
    #cuisine-section {
      margin: 2.5em auto 0; } }

.avatar {
  border-radius: 50%;
  overflow: hidden; }

.cuisine-skeleton__row {
  display: flex; }

.cuisine-skeleton__column {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  padding: 0 40px 0 40px; }
  .cuisine-skeleton__column > :first-child {
    border-radius: 50%;
    margin: 1em 0; }

.cuisine-type {
  color: #1d1d1f; }
  .cuisine-type__header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; }
    .cuisine-type__header__close {
      margin-right: 1em;
      position: absolute;
      right: 1em;
      line-height: 0;
      transform: translateX(30%); }
      .cuisine-type__header__close img {
        width: 16px; }
  .cuisine-type__search-info {
    text-align: left;
    font-size: 18px;
    line-height: 20px;
    padding: 16px 4px 4px 4px;
    font-weight: 600; }
    .cuisine-type__search-info p {
      margin: 0; }
  .cuisine-type__body {
    /*padding: 1em 0 !important;
    @media (min-width: 768px) {
      padding: 1em 0 2.15em !important;
    }
    margin: 0 !important;
    display: flex;
    width: auto;
    overflow-x: auto;
    @media (min-width: 768px) {
      overflow-x: hidden;
      &:hover {
        overflow-x: auto;
        padding: 1em 0 1em !important;
      }
    }*/
    position: relative;
    width: 100%;
    display: flex;
    background-color: white;
    overflow-X: auto; }
    .cuisine-type__body__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-self: center;
      padding: 1.5em;
      cursor: pointer;
      height: auto; }
      @media (min-width: 768px) and (max-width: 1199px) {
        .cuisine-type__body__item {
          width: 11em;
          height: auto; } }
      @media (min-width: 1200px) {
        .cuisine-type__body__item {
          width: 11em;
          height: auto; } }
      .cuisine-type__body__item--selected {
        background: rgba(194, 209, 217, 0.2);
        border: 5px solid white;
        border-radius: 8px; }
        @media (min-width: 768px) and (max-width: 1199px) {
          .cuisine-type__body__item--selected {
            border-radius: 12px; } }
      .cuisine-type__body__item .crop-image {
        width: 70px;
        height: 40px;
        position: relative;
        overflow: hidden;
        margin-bottom: 1em;
        display: flex;
        justify-content: center;
        align-items: center; }
        .cuisine-type__body__item .crop-image img {
          width: auto;
          height: 100%; }
      .cuisine-type__body__item span {
        white-space: nowrap;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px; }

.swiper-wrapper .swiper-slide {
  position: relative;
  width: 82px;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box; }

.swiper-container {
  grid-gap: 0;
  gap: 0;
  width: 100%;
  height: auto;
  overflow: hidden; }
  .swiper-container .swiper-button-prev,
  .swiper-container .swiper-button-next {
    border: none;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    width: 35px;
    height: 35px;
    margin-top: -22px !important;
    z-index: 10;
    cursor: pointer;
    background-size: 50px 50px;
    background-position: center;
    background-repeat: no-repeat; }
  .swiper-container .swiper-button-prev.swiper-button-disabled,
  .swiper-container .swiper-button-next.swiper-button-disabled {
    display: none; }
  .swiper-container .swiper-button-prev,
  .swiper-container .swiper-container-rtl .swiper-button-next {
    left: 0;
    right: auto; }
  .swiper-container .swiper-button-next,
  .swiper-container .swiper-container-rtl .swiper-button-prev {
    right: 0;
    left: auto; }
  .swiper-container .swiper-button-lock {
    display: none; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.restaurant-carousels-list-item {
  margin: 30px 0 50px; }
  .restaurant-carousels-list-item .hide {
    display: none; }
  .restaurant-carousels-list-item .container-fluid {
    margin: 0 1em;
    /*
    @media (min-width: 768px) {
      margin: 0 1.5em;
    }
    */ }
    @media (min-width: 768px) and (max-width: 1199px) {
      .restaurant-carousels-list-item .container-fluid {
        padding-right: 0;
        padding-left: 0;
        margin: 0; } }
    @media (min-width: 1200px) {
      .restaurant-carousels-list-item .container-fluid {
        padding-right: 1.25em;
        padding-left: 1.25em;
        margin: 0;
        width: 100% !important; } }
    .restaurant-carousels-list-item .container-fluid .carousel-title-bar {
      display: block;
      align-items: center;
      margin: 0; }
      @media (min-width: 1200px) {
        .restaurant-carousels-list-item .container-fluid .carousel-title-bar {
          margin: 2em 0 0 0; } }
      .restaurant-carousels-list-item .container-fluid .carousel-title-bar .tag-container {
        display: flex; }
        .restaurant-carousels-list-item .container-fluid .carousel-title-bar .tag-container .restaurant-tag {
          margin: 1em .5em 0 0; }
          .restaurant-carousels-list-item .container-fluid .carousel-title-bar .tag-container .restaurant-tag img {
            height: 1.8em; }
      .restaurant-carousels-list-item .container-fluid .carousel-title-bar .restaurant-name {
        width: 100%;
        text-align: left;
        display: flex;
        align-items: center; }
        .restaurant-carousels-list-item .container-fluid .carousel-title-bar .restaurant-name h2 {
          font-size: 24px;
          font-weight: 700;
          margin: 0 !important; }
          @media (min-width: 768px) and (max-width: 1199px) {
            .restaurant-carousels-list-item .container-fluid .carousel-title-bar .restaurant-name h2 {
              font-size: 21px; } }
          @media (min-width: 1200px) {
            .restaurant-carousels-list-item .container-fluid .carousel-title-bar .restaurant-name h2 {
              font-size: 21px; } }
        .restaurant-carousels-list-item .container-fluid .carousel-title-bar .restaurant-name .rating {
          display: flex;
          align-items: center;
          margin: 0 7px; }
          .restaurant-carousels-list-item .container-fluid .carousel-title-bar .restaurant-name .rating img {
            margin-right: 3px; }
        .restaurant-carousels-list-item .container-fluid .carousel-title-bar .restaurant-name .more-business {
          display: flex;
          align-items: center;
          margin: 0 1em;
          text-transform: capitalize;
          color: #1e4acc; }
          .restaurant-carousels-list-item .container-fluid .carousel-title-bar .restaurant-name .more-business img {
            margin-right: 3px; }
      .restaurant-carousels-list-item .container-fluid .carousel-title-bar .restaurant-detail {
        display: flex;
        align-items: flex-start;
        margin-bottom: 0.5em;
        grid-template-columns: 80% 20% !important; }
        .restaurant-carousels-list-item .container-fluid .carousel-title-bar .restaurant-detail .restaurant-info {
          width: 100%;
          display: flex;
          font-size: 13px; }
          @media (min-width: 768px) {
            .restaurant-carousels-list-item .container-fluid .carousel-title-bar .restaurant-detail .restaurant-info {
              display: flex;
              width: 100%; } }
          .restaurant-carousels-list-item .container-fluid .carousel-title-bar .restaurant-detail .restaurant-info .key {
            font-family: inherit;
            font-weight: 400;
            color: #707a85; }
          .restaurant-carousels-list-item .container-fluid .carousel-title-bar .restaurant-detail .restaurant-info .value {
            font-family: inherit;
            font-weight: 400;
            color: #707a85;
            margin: 0 0 0 0.5em; }
          .restaurant-carousels-list-item .container-fluid .carousel-title-bar .restaurant-detail .restaurant-info .bar {
            margin: 0 0.5em 0 0.5em; }
      .restaurant-carousels-list-item .container-fluid .carousel-title-bar .carousel-swiper-buttons {
        width: 100%;
        text-align: right; }
        .restaurant-carousels-list-item .container-fluid .carousel-title-bar .carousel-swiper-buttons .swiper-button-next,
        .restaurant-carousels-list-item .container-fluid .carousel-title-bar .carousel-swiper-buttons .swiper-button-prev {
          display: none; }
        @media (min-width: 1200px) {
          .restaurant-carousels-list-item .container-fluid .carousel-title-bar .carousel-swiper-buttons .swiper-button-prev,
          .restaurant-carousels-list-item .container-fluid .carousel-title-bar .carousel-swiper-buttons .swiper-button-next {
            display: inline-grid;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 50%;
            padding: 0.5em;
            color: #1d1d1f; }
          .restaurant-carousels-list-item .container-fluid .carousel-title-bar .carousel-swiper-buttons .swiper-button-prev {
            margin-right: 0.5em; } }
    .restaurant-carousels-list-item .container-fluid .swiper-container {
      grid-gap: 12px;
      gap: 12px;
      width: 100%;
      height: auto;
      padding-bottom: 1em;
      overflow: hidden; }
      .restaurant-carousels-list-item .container-fluid .swiper-container .swiper-wrapper .swiper-slide {
        text-align: left;
        font-size: 18px;
        background: #ffffff;
        width: 20%;
        height: auto !important;
        cursor: pointer !important;
        display: inline-block;
        /* flex; */
        justify-content: start;
        align-items: start;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        min-width: 277.5px; }
        @media (min-width: 768px) and (max-width: 1199px) {
          .restaurant-carousels-list-item .container-fluid .swiper-container .swiper-wrapper .swiper-slide {
            margin-right: 30px;
            border-radius: 12px; } }
        .restaurant-carousels-list-item .container-fluid .swiper-container .swiper-wrapper .swiper-slide .card-image-container {
          height: 9em;
          display: flex;
          flex-direction: column;
          justify-content: center;
          box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1); }
          .restaurant-carousels-list-item .container-fluid .swiper-container .swiper-wrapper .swiper-slide .card-image-container img {
            width: auto;
            height: 9em;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px; }
        .restaurant-carousels-list-item .container-fluid .swiper-container .swiper-wrapper .swiper-slide .card-details-container .label-title {
          font-family: inherit;
          font-size: 15px;
          font-weight: 800;
          line-height: 18px;
          color: #1d1d1f;
          margin: 0.8em 1em 0.25em 1em; }
        .restaurant-carousels-list-item .container-fluid .swiper-container .swiper-wrapper .swiper-slide .card-details-container .label-content {
          font-family: inherit;
          font-size: 0.8em;
          font-weight: 400;
          line-height: 1.6;
          color: #565656;
          margin: 0 15px 7px; }
      .restaurant-carousels-list-item .container-fluid .swiper-container .swiper-wrapper .swiper-slide.showAll {
        background: transparent !important;
        box-shadow: none !important;
        font-size: 16px;
        font-weight: 700;
        color: #1d1d1f;
        text-align: left;
        display: flex;
        align-items: center; }
        .restaurant-carousels-list-item .container-fluid .swiper-container .swiper-wrapper .swiper-slide.showAll .showAllLink {
          /* Safari */
          display: flex;
          /* Safari 7.0+ */
          align-items: center;
          /* padding: 40% 20% 0; */ }
          .restaurant-carousels-list-item .container-fluid .swiper-container .swiper-wrapper .swiper-slide.showAll .showAllLink:hover {
            cursor: pointer; }
        .restaurant-carousels-list-item .container-fluid .swiper-container .swiper-wrapper .swiper-slide.showAll svg {
          height: 1em;
          vertical-align: middle;
          display: inline-block; }
          .restaurant-carousels-list-item .container-fluid .swiper-container .swiper-wrapper .swiper-slide.showAll svg .icon {
            width: 24px;
            color: #2c2c2c;
            padding-top: 2px;
            padding-left: 10px;
            margin: 0 !important; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.business-group-by-product-type {
  margin: 30px 0 50px; }
  .business-group-by-product-type .hide {
    display: none; }
  .business-group-by-product-type .container-fluid {
    margin: 0 1em; }
    @media (min-width: 768px) and (max-width: 1199px) {
      .business-group-by-product-type .container-fluid {
        padding-right: 0;
        padding-left: 0; } }
    @media (min-width: 1200px) {
      .business-group-by-product-type .container-fluid {
        padding-right: 1.25em;
        padding-left: 1.25em;
        margin: 0;
        width: 100% !important; } }
    .business-group-by-product-type .container-fluid .carousel-title-bar {
      display: flex;
      align-items: flex-end;
      margin: 1em 0; }
      @media (min-width: 1200px) {
        .business-group-by-product-type .container-fluid .carousel-title-bar {
          margin: 2em 0 1em 0; } }
      .business-group-by-product-type .container-fluid .carousel-title-bar .tag-container {
        display: flex; }
        .business-group-by-product-type .container-fluid .carousel-title-bar .tag-container .restaurant-tag {
          margin: 1em .5em 0 0; }
          .business-group-by-product-type .container-fluid .carousel-title-bar .tag-container .restaurant-tag img {
            height: 1.8em; }
      .business-group-by-product-type .container-fluid .carousel-title-bar .restaurant-name {
        width: 100%;
        text-align: left;
        display: flex;
        align-items: baseline; }
        .business-group-by-product-type .container-fluid .carousel-title-bar .restaurant-name h2 {
          font-size: 21px;
          font-weight: 700;
          letter-spacing: -.005em;
          text-transform: none;
          margin: 0 !important; }
          @media (min-width: 768px) and (max-width: 1199px) {
            .business-group-by-product-type .container-fluid .carousel-title-bar .restaurant-name h2 {
              font-size: 21px; } }
          @media (min-width: 1200px) {
            .business-group-by-product-type .container-fluid .carousel-title-bar .restaurant-name h2 {
              font-size: 24px; } }
        .business-group-by-product-type .container-fluid .carousel-title-bar .restaurant-name .more-business {
          display: flex;
          align-items: center;
          font-weight: 500;
          color: #1e4acc;
          margin: 0 1em;
          cursor: pointer; }
          .business-group-by-product-type .container-fluid .carousel-title-bar .restaurant-name .more-business img {
            margin-right: 3px; }
      .business-group-by-product-type .container-fluid .carousel-title-bar .restaurant-detail {
        display: flex;
        align-items: center;
        margin-bottom: 0.5em;
        grid-template-columns: 80% 20% !important; }
        .business-group-by-product-type .container-fluid .carousel-title-bar .restaurant-detail .restaurant-info {
          width: 100%;
          display: flex;
          font-size: 13px; }
          @media (min-width: 768px) {
            .business-group-by-product-type .container-fluid .carousel-title-bar .restaurant-detail .restaurant-info {
              display: flex;
              width: 100%; } }
      .business-group-by-product-type .container-fluid .carousel-title-bar .carousel-swiper-buttons {
        width: 100%;
        text-align: right; }
        .business-group-by-product-type .container-fluid .carousel-title-bar .carousel-swiper-buttons .swiper-button-next,
        .business-group-by-product-type .container-fluid .carousel-title-bar .carousel-swiper-buttons .swiper-button-prev {
          display: none; }
        @media (min-width: 1200px) {
          .business-group-by-product-type .container-fluid .carousel-title-bar .carousel-swiper-buttons .swiper-button-prev,
          .business-group-by-product-type .container-fluid .carousel-title-bar .carousel-swiper-buttons .swiper-button-next {
            display: inline-grid;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 50%;
            padding: 0.5em;
            color: #1d1d1f; }
          .business-group-by-product-type .container-fluid .carousel-title-bar .carousel-swiper-buttons .swiper-button-prev {
            margin-right: 0.5em; } }
    .business-group-by-product-type .container-fluid .swiper-container {
      grid-gap: 12px;
      gap: 12px;
      width: 100%;
      height: auto;
      padding-bottom: 1em;
      overflow: hidden; }
      .business-group-by-product-type .container-fluid .swiper-container .swiper-wrapper .swiper-slide {
        text-align: left;
        font-size: 18px;
        background: #ffffff;
        width: 20%;
        height: auto !important;
        cursor: pointer !important;
        display: inline-block;
        /* flex; */
        justify-content: start;
        align-items: start;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        min-width: 277.5px; }
        .business-group-by-product-type .container-fluid .swiper-container .swiper-wrapper .swiper-slide .card-image-container {
          height: 9em;
          display: flex;
          flex-direction: column;
          justify-content: center;
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1); }
          .business-group-by-product-type .container-fluid .swiper-container .swiper-wrapper .swiper-slide .card-image-container img {
            width: auto;
            height: 9em;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px; }
        .business-group-by-product-type .container-fluid .swiper-container .swiper-wrapper .swiper-slide .card-details-container {
          margin: 0.5em 0; }
          .business-group-by-product-type .container-fluid .swiper-container .swiper-wrapper .swiper-slide .card-details-container .label-title {
            display: flex;
            justify-content: space-between;
            align-items: center; }
            .business-group-by-product-type .container-fluid .swiper-container .swiper-wrapper .swiper-slide .card-details-container .label-title h3 {
              font-family: inherit;
              font-size: 15px;
              font-weight: 800;
              line-height: 1em;
              color: #1d1d1f;
              margin: 0.5em 0.6em; }
            .business-group-by-product-type .container-fluid .swiper-container .swiper-wrapper .swiper-slide .card-details-container .label-title .rating-star {
              display: flex;
              align-items: flex-start;
              margin: 0 .5em; }
              .business-group-by-product-type .container-fluid .swiper-container .swiper-wrapper .swiper-slide .card-details-container .label-title .rating-star span {
                font-size: .8em; }
              .business-group-by-product-type .container-fluid .swiper-container .swiper-wrapper .swiper-slide .card-details-container .label-title .rating-star img {
                margin-left: .2em;
                width: 1.1em; }
          .business-group-by-product-type .container-fluid .swiper-container .swiper-wrapper .swiper-slide .card-details-container .label-content {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-family: inherit;
            font-size: 13px;
            font-weight: 400;
            line-height: 1.6;
            color: #565656;
            margin: 0 0.8em 0.5em; }
            .business-group-by-product-type .container-fluid .swiper-container .swiper-wrapper .swiper-slide .card-details-container .label-content .key {
              font-family: inherit;
              font-weight: 400;
              color: #707a85; }
            .business-group-by-product-type .container-fluid .swiper-container .swiper-wrapper .swiper-slide .card-details-container .label-content .value {
              font-family: inherit;
              font-weight: 400;
              color: #707a85;
              margin: 0; }
            .business-group-by-product-type .container-fluid .swiper-container .swiper-wrapper .swiper-slide .card-details-container .label-content .bar {
              margin: 0 0.2em; }
        .business-group-by-product-type .container-fluid .swiper-container .swiper-wrapper .swiper-slide .card:hover-details-container .label-title h3 {
          color: blue; }
      .business-group-by-product-type .container-fluid .swiper-container .swiper-wrapper .swiper-slide.showAll {
        background: transparent !important;
        box-shadow: none !important;
        font-size: 16px;
        font-weight: 700;
        color: #1d1d1f;
        text-align: left; }
        .business-group-by-product-type .container-fluid .swiper-container .swiper-wrapper .swiper-slide.showAll .showAllLink {
          /* Safari */
          display: flex;
          /* Safari 7.0+ */
          align-items: center;
          padding: 40% 20% 0; }
          .business-group-by-product-type .container-fluid .swiper-container .swiper-wrapper .swiper-slide.showAll .showAllLink:hover {
            cursor: pointer; }
        .business-group-by-product-type .container-fluid .swiper-container .swiper-wrapper .swiper-slide.showAll svg {
          height: 1em;
          vertical-align: middle;
          display: inline-block; }
          .business-group-by-product-type .container-fluid .swiper-container .swiper-wrapper .swiper-slide.showAll svg .icon {
            width: 24px;
            color: #2c2c2c;
            padding-top: 2px;
            padding-left: 10px;
            margin: 0 !important; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.customer-satisfaction {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around; }
  .customer-satisfaction .cs-form {
    margin: 1em; }
    @media (min-width: 1200px) {
      .customer-satisfaction .cs-form {
        margin: 2em 20em; } }
    .customer-satisfaction .cs-form .cs-restaurant {
      background: #ffffff;
      border-radius: 3px;
      margin-bottom: 1em; }
      .customer-satisfaction .cs-form .cs-restaurant .cs-image-box {
        height: 12em; }
        .customer-satisfaction .cs-form .cs-restaurant .cs-image-box .cs-image-box-background {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-size: cover;
          overflow: hidden; }
        .customer-satisfaction .cs-form .cs-restaurant .cs-image-box .image-caption {
          background: rgba(255, 255, 255, 0.8);
          padding: 10px;
          display: flex;
          flex-direction: column;
          max-width: 70%;
          min-width: 60%;
          margin: auto; }
      .customer-satisfaction .cs-form .cs-restaurant .cs-restaurant-info {
        padding: 1em 0;
        display: flex;
        justify-content: left;
        text-align: left; }
        @media (min-width: 768px) {
          .customer-satisfaction .cs-form .cs-restaurant .cs-restaurant-info {
            padding: 1em 1em 1em 3em; } }
        .customer-satisfaction .cs-form .cs-restaurant .cs-restaurant-info .cs-title {
          font-family: inherit;
          font-size: 18px;
          font-weight: 700 !important;
          line-height: 1em;
          color: #3e3e3e;
          margin: 0.5em 0;
          width: 100%; }
        .customer-satisfaction .cs-form .cs-restaurant .cs-restaurant-info .cs-label span {
          margin: .2em 0; }
    .customer-satisfaction .cs-form .cs-form-subtitle {
      font-size: 1.4em;
      color: #1d1d1f;
      font-weight: 700;
      padding: 1em 0;
      border-bottom: 1px solid #a1aeb7; }
    .customer-satisfaction .cs-form .cs-review {
      text-align: center;
      margin: 1em 0; }
      .customer-satisfaction .cs-form .cs-review .cs-score-label {
        margin: 1em 0 0 0;
        color: #1d1d1f;
        font-weight: 700; }
      .customer-satisfaction .cs-form .cs-review .cs-score {
        align-items: center;
        margin: 0 0 1em 0;
        color: #1d1d1f; }
        .customer-satisfaction .cs-form .cs-review .cs-score .rate {
          text-align: center; }
          .customer-satisfaction .cs-form .cs-review .cs-score .rate .icons {
            width: 1.2em;
            height: 1.2em; }
            @media (min-width: 768px) {
              .customer-satisfaction .cs-form .cs-review .cs-score .rate .icons {
                width: 1.8em;
                height: 1.8em; } }
        .customer-satisfaction .cs-form .cs-review .cs-score .cs-tag {
          margin: 1em 0;
          color: #1d1d1f;
          height: 2em; }
          .customer-satisfaction .cs-form .cs-review .cs-score .cs-tag .arrow-left {
            width: 0;
            height: 0;
            border-top: 1.6em solid transparent;
            border-bottom: 1.6em solid transparent;
            border-right: 1.6em solid #1d1d1f; }
            @media (min-width: 768px) {
              .customer-satisfaction .cs-form .cs-review .cs-score .cs-tag .arrow-left {
                width: 0;
                height: 0;
                border-top: 1.2em solid transparent;
                border-bottom: 1.2em solid transparent;
                border-right: 1.2em solid #1d1d1f; } }
          .customer-satisfaction .cs-form .cs-review .cs-score .cs-tag .arrow-content {
            justify-content: center;
            text-align: center;
            width: 7em;
            height: 2.8em;
            display: flex;
            align-items: center;
            padding: .2em;
            background: #1d1d1f;
            color: #ffffff; }
            @media (min-width: 768px) {
              .customer-satisfaction .cs-form .cs-review .cs-score .cs-tag .arrow-content {
                width: 12em;
                height: 2em; } }
      .customer-satisfaction .cs-form .cs-review .cs-input-detail {
        margin: 1em 0;
        width: 100% !important; }
  .customer-satisfaction .cs-register-message {
    /* margin: 1em;
    display: flex;
    text-align: center;
    line-height: 3em;

    @media (min-width: 1200px){
      margin: 5em 20em;
    }

    .message {
      display: flex;
      padding: 1em;
      font-weight: $font-bold;
      color: $color-gunmetal;
      font-size: $font-size-twice;
    }

    .action {
      display: block;
      margin-top: 3em;
      text-align: center;

      button {
        padding: .5em 2.5em;
      }
    }*/ }
    .customer-satisfaction .cs-register-message .cs-container {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      max-width: 1366px;
      padding: 4rem 20px 20px;
      margin: 0 auto; }
    @media (max-width: 767px) {
      .customer-satisfaction .cs-register-message .account-container {
        padding: 4.5rem 10px 10px; } }
    .customer-satisfaction .cs-register-message .fixed-block {
      flex: 0 0 auto; }
    .customer-satisfaction .cs-register-message .expanded-block {
      flex: 1 1 auto; }
    .customer-satisfaction .cs-register-message .basic-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      left: auto;
      right: auto;
      height: auto;
      text-align: center;
      border: 1px solid #d3dce6;
      background: white;
      box-shadow: none;
      padding: 1.85rem;
      margin: 0; }
    .customer-satisfaction .cs-register-message .basic-card.small {
      max-width: 320px;
      width: auto; }
    .customer-satisfaction .cs-register-message .basic-card.medium {
      max-width: 540px;
      width: auto; }
    .customer-satisfaction .cs-register-message .basic-card.large {
      max-width: 750px;
      width: auto; }
    .customer-satisfaction .cs-register-message .basic-card img.card-icon {
      align-self: center;
      width: 80px;
      height: auto;
      max-height: 80px;
      margin: 1rem 0;
      padding: 0; }
    .customer-satisfaction .cs-register-message .basic-card h4 {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.5rem;
      margin-top: 0;
      margin-bottom: 12px; }
    .customer-satisfaction .cs-register-message .basic-card p {
      font-size: 0.9375rem;
      font-weight: 400;
      line-height: 1.125rem;
      margin: 0; }
    .customer-satisfaction .cs-register-message .basic-card .button--primary {
      min-width: 142px; }
    .customer-satisfaction .cs-register-message .basic-card .resume {
      font-size: 0.85rem;
      /* 15px; */
      padding-top: 10px; }
    .customer-satisfaction .cs-register-message .basic-card .resume table {
      width: 100%; }
    .customer-satisfaction .cs-register-message .basic-card .resume tr {
      vertical-align: top; }
    .customer-satisfaction .cs-register-message .basic-card .resume tr td {
      text-align: left; }
    .customer-satisfaction .cs-register-message .basic-card .resume tr td:first-child {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      color: #707a85;
      padding-right: 20px; }
    .customer-satisfaction .cs-register-message .basic-card .resume tr td:last-child {
      text-align: right; }
    .customer-satisfaction .cs-register-message .d-flex {
      display: flex; }
    .customer-satisfaction .cs-register-message .flex-column {
      flex-direction: column; }
    .customer-satisfaction .cs-register-message .justfy-content-center {
      justify-content: center; }
    .customer-satisfaction .cs-register-message .mt-50 {
      margin-top: 50px; }
    .customer-satisfaction .cs-register-message .mb-20 {
      margin-bottom: 20px; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main, .email-form {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

#email {
  padding: 3px 7px;
  color: #2a2a2a;
  background-color: #ffffff;
  border-radius: 4px;
  height: 50px;
  border: none;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.38rem;
  outline: none; }

.email-form {
  background: #ffffff;
  display: flex;
  justify-items: center;
  justify-content: center;
  padding: 64px 0; }
  @media (min-width: 768px) {
    .email-form {
      padding: 64px; } }
  .email-form .container {
    max-width: 500px; }
    @media (min-width: 768px) {
      .email-form .container {
        max-width: 700px; } }
    .email-form .container .form-content {
      width: 100%;
      background: #ffffff;
      padding: 1em 0.5em;
      text-align: center;
      border-radius: 0; }
      @media (min-width: 1200px) {
        .email-form .container .form-content {
          padding: 2em 5em; } }
      .email-form .container .form-content .title {
        font-family: "Nunito Sans";
        font-size: 2rem;
        font-weight: 300;
        line-height: 1.5rem;
        background-image: none;
        color: #094296;
        min-height: 55px;
        margin: 0 0 0 .5rem; }
        @media (min-width: 768px) {
          .email-form .container .form-content .title {
            font-size: 2rem;
            line-height: 2.375rem;
            background-image: none;
            min-height: 0;
            margin: 0;
            margin: initial; } }
        .email-form .container .form-content .title span {
          display: block;
          margin: 15px auto 0;
          background: #e50914;
          width: 36px;
          height: 2px; }
      .email-form .container .form-content .subtitle {
        font-family: "Nunito Sans";
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5rem;
        display: inherit;
        padding: 30px 30px 20px;
        color: black; }
      .email-form .container .form-content .fields {
        display: flex;
        justify-items: center;
        justify-content: center;
        padding: 0.5em 0; }
        .email-form .container .form-content .fields-error {
          display: block;
          max-width: 300px;
          width: 100%;
          text-align: left;
          font-size: 0.875rem;
          line-height: 1.5;
          font-family: "Nunito Sans";
          font-weight: 400;
          color: #ff424d;
          margin: 0;
          height: 0; }
        .email-form .container .form-content .fields-form-error {
          height: auto;
          text-align: center;
          display: block;
          background: red;
          color: white !important;
          padding: 0.5em;
          font-weight: bold !important;
          border-radius: 8px; }
        .email-form .container .form-content .fields .extra {
          display: grid;
          grid-template-columns: 40% 60%;
          align-items: center;
          font-size: 0.8em;
          margin: 0;
          padding: 0; }
          .email-form .container .form-content .fields .extra:first-child {
            text-align: left; }
          .email-form .container .form-content .fields .extra:last-child {
            text-align: right;
            margin-right: 1em; }
          .email-form .container .form-content .fields .extra span {
            font-size: 1em; }
        .email-form .container .form-content .fields .action {
          padding-top: 1.5em; }
        .email-form .container .form-content .fields button {
          font-size: 1rem;
          font-weight: 400;
          text-transform: none !important;
          line-height: 1.5;
          height: 50px;
          margin: 0 auto;
          outline: 0px;
          border: 0px;
          font-family: "Nunito Sans";
          width: 100%;
          min-width: 64px;
          max-width: 295px;
          border-radius: 8px;
          color: white;
          background-color: #1e4acc;
          box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
          font-weight: 700;
          transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
        .email-form .container .form-content .fields .register-link {
          display: flex;
          justify-items: center;
          justify-content: center;
          align-items: center;
          font-size: 0.5em; }
          .email-form .container .form-content .fields .register-link p {
            text-align: center;
            font-size: 0.9rem;
            color: #1d1d1f;
            padding-right: 0.8em; }
          .email-form .container .form-content .fields .register-link .button-link {
            font-weight: bold;
            height: 21px; }
          .email-form .container .form-content .fields .register-link:hover {
            cursor: pointer; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.field-group-2 {
  color: #707a85;
  text-align: left;
  margin: 1em 0;
  display: grid;
  grid-template-columns: 32px 1fr; }
  .field-group-2 .MuiInput-underline:before {
    border-bottom: none !important; }
  .field-group-2 .MuiOutlinedInput-adornedEnd {
    padding-right: 0px; }
  .field-group-2 .label {
    grid-column: 2 / 2;
    grid-row: 1 / 1; }
  .field-group-2 .front-icon {
    grid-column: 1 / 1;
    grid-row: 2 / 2;
    transform: translateY(20%);
    width: 1em; }
  .field-group-2 input,
  .field-group-2 .MuiSkeleton-root,
  .field-group-2 .MuiTextField-root {
    grid-column: 2 / 2;
    grid-row: 2 / 2; }
  .field-group-2 .label,
  .field-group-2 .hint {
    font-size: 0.8em;
    line-height: 1.8;
    font-style: italic; }
    .field-group-2 .label_error,
    .field-group-2 .hint_error {
      color: red; }
  .field-group-2 .hint_alert {
    font-size: 0.8em;
    line-height: 1.8;
    font-style: italic; }
    .field-group-2 .hint_alert_success {
      color: #9abe29; }
    .field-group-2 .hint_alert_error {
      color: red; }
  .field-group-2 svg {
    fill: #1e4acc !important;
    margin-top: 4px; }

.profile {
  font-size: 1rem;
  padding: 1em; }
  .profile__header {
    display: flex;
    flex-direction: column;
    padding: 0 0 1em 0;
    margin: auto;
    max-width: 45%;
    min-width: 450px; }
    @media (min-width: 768px) {
      .profile__header {
        align-items: center;
        flex-direction: row;
        max-width: unset;
        min-width: unset;
        padding: 1em 0; } }
    .profile__header .button--outline {
      width: 12em;
      background: #ffffff;
      margin-top: 1em; }
      @media (min-width: 768px) {
        .profile__header .button--outline {
          margin-top: 0; } }
  .profile-data {
    background: transparent;
    width: 100%;
    max-width: 45%;
    min-width: 450px;
    margin: 1em auto;
    border: 1px solid #c2d1d9;
    border-radius: 8px; }
    @media (max-width: 500px) {
      .profile-data {
        min-width: 100%;
        max-width: 100%; } }
    @media (min-width: 768px) {
      .profile-data {
        border-radius: 12px; } }
    .profile-data > div {
      background: transparent; }
      .profile-data > div > div {
        background: transparent; }
    .profile-data__header .field-group-2 .label {
      padding: 0 7.5px; }
    .profile-data__header, .profile-data__form {
      padding: 0.5em; }
      @media (min-width: 768px) {
        .profile-data__header, .profile-data__form {
          padding: 0.5em 4em; } }
    .profile-data__form {
      border-top: 0;
      margin: 1.5em 0 !important; }
      .profile-data__form > * {
        margin-bottom: 1.5em !important; }
        .profile-data__form > *:last-child {
          margin-bottom: 0 !important;
          grid-gap: 1em;
          width: 50%;
          justify-self: center;
          margin: 0 auto; }
    .profile-data__avatar-wrapper {
      border-radius: 50%;
      overflow: hidden;
      height: 100px;
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center; }
    .profile-data__avatar {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1em 0 0; }
      .profile-data__avatar .label-container {
        transform: translate(100%, -100%); }
    .profile-data__name {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 1em 0 .25em 0; }
      .profile-data__name-holder {
        flex-grow: 1; }
      .profile-data__name__label {
        font-size: 1.25em;
        font-weight: 700;
        flex-grow: 1; }

.expansion-bottom-special {
  margin-bottom: -15px; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.address {
  min-height: 80vh;
  font-size: 1rem;
  padding: 0 1em; }
  @media (min-width: 768px) {
    .address {
      padding: 0 1em; } }
  .address__header {
    display: flex;
    align-items: center;
    padding: 1em 0; }
    .address__header button {
      font-size: .9em;
      font-weight: 500;
      text-transform: none;
      border: 1px solid #1e4acc;
      box-shadow: none;
      padding: 5px 15px; }
      .address__header button > svg {
        fill: #1e4acc !important;
        margin-right: 0; }
        @media (min-width: 768px) {
          .address__header button > svg {
            margin-right: 7px; } }
      .address__header button:hover > svg, .address__header button:focus > svg {
        fill: #ffffff !important; }
      .address__header button > span {
        display: none;
        font-size: 1em;
        margin-right: 3px; }
        @media (min-width: 768px) {
          .address__header button > span {
            display: inline-block;
            font-size: 1.125em; } }
  .address-data li {
    display: grid;
    grid-template-columns: 1fr 48px 48px;
    background: #ffffff;
    grid-template-columns: 1fr 48px 48px;
    border: 1px solid #c2d1d9;
    border-radius: 8px;
    margin-bottom: 1em;
    padding: 1em; }
    @media (min-width: 768px) {
      .address-data li {
        border-radius: 12px;
        padding: 1em 1em 1em 1.5em; } }
    .address-data li > *:first-child {
      grid-row: 1/1;
      grid-column: 1/1;
      text-align: left;
      font-size: .9em;
      color: #707a85; }
    .address-data li > *:nth-child(2) {
      grid-row: 2/2;
      grid-column: 1/1;
      text-align: left;
      font-size: 1em;
      color: #2a2a2a; }
    .address-data li > *:nth-child(3) {
      grid-row: 1/3;
      grid-column: 2/2;
      align-self: center;
      justify-self: center; }
    .address-data li > *:nth-child(4) {
      grid-row: 1/3;
      grid-column: 3/3;
      align-self: center;
      justify-self: center; }
    .address-data li svg.MuiSvgIcon-root {
      fill: #1e4acc !important; }

.address-section {
  padding: 2em 0 !important; }
  @media (min-width: 768px) {
    .address-section {
      padding: 2em 1em !important; } }

.address-form {
  box-shadow: none !important;
  border: 1px solid #c2d1d9;
  border-radius: 8px !important;
  margin: 0 1em; }
  @media (min-width: 768px) {
    .address-form {
      border-radius: 12px !important; } }
  @media (min-width: 1200px) {
    .address-form {
      max-width: 50%;
      margin: 2em auto; } }
  .address-form-header {
    position: relative;
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid #c2d1d9;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1em; }
    @media (min-width: 768px) {
      .address-form-header {
        padding: 1em 4em; } }
    .address-form-header h3 {
      max-width: 80%; }
    .address-form-header__close {
      position: absolute;
      height: 44px;
      width: 44px;
      top: 1em;
      right: 1.15em;
      line-height: 0;
      transform: translateX(30%); }
      .address-form-header__close img {
        width: 16px; }
    @media (min-width: 768px) {
      .address-form-header {
        justify-content: center;
        align-items: center;
        text-align: center; } }
  .address-form-body {
    padding: 0; }
    @media (min-width: 768px) {
      .address-form-body {
        padding: 1em 2.5em; } }

.MuiOutlinedInput-root fieldset {
  border-radius: 5px !important; }

.makeStyles-root-36 {
  margin-bottom: 1em !important; }

.Connect\(FormAddAddress\)-caption-16 {
  margin-left: 0; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.purchase {
  min-height: 80vh;
  font-size: 1rem; }
  .purchase .page-section {
    padding: 1em 0; }
    .purchase .page-section .wrap {
      box-sizing: border-box;
      max-width: 1200px;
      margin: 0 auto; }
    .purchase .page-section .container-fluid {
      padding-right: 1em;
      padding-left: 1em; }
      @media (min-width: 1200px) {
        .purchase .page-section .container-fluid {
          padding-right: 0;
          padding-left: 0; } }
    .purchase .page-section .history-header .divider-green {
      width: 32px;
      margin: 0.5em 0;
      border-bottom: 2px solid #1e4acc; }
    .purchase .page-section .history-header h2, .purchase .page-section .history-header .h2 {
      font-size: 28px;
      font-weight: bold;
      line-height: 38px; }
    .purchase .page-section .history-header h2.headline {
      text-align: center;
      margin-bottom: 40px; }
    .purchase .page-section .history-header h2.headline.left {
      text-align: left;
      margin-bottom: 25px; }
    .purchase .page-section .history-header p {
      margin-bottom: .5em; }
    .purchase .page-section .history-body {
      padding: 1em 0; }
  .purchase .not-found-container {
    display: flex;
    justify-content: flex-start;
    grid-column-gap: 1em;
    -webkit-column-gap: 1em;
            column-gap: 1em; }
    .purchase .not-found-container h2 {
      font-weight: 600;
      color: #1d1d1f;
      margin-bottom: 5px; }
    .purchase .not-found-container h3 {
      margin-top: 12px;
      color: #c2d1d9; }
      @media (min-width: 768px) {
        .purchase .not-found-container h3 {
          margin-top: 18px; } }
  .purchase .not-found-wrapper {
    padding: 2em 0;
    display: flex;
    justify-content: center;
    align-self: center; }

.infinite-scroll-component {
  overflow: hidden !important;
  overflow-y: auto; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.history-card {
  flex: 1 0 100%; }
  .history-card .card {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - 20px);
    align-items: stretch;
    border: 1px solid #c2d1d9;
    border-radius: 8px;
    box-shadow: none;
    margin-bottom: .5em; }
    @media (min-width: 768px) {
      .history-card .card {
        border-radius: 12px;
        margin-bottom: .75em; } }
    .history-card .card .history-card-image {
      flex-grow: 0;
      flex-shrink: 0;
      flex-wrap: nowrap;
      width: 100%;
      max-width: unset; }
      @media (min-width: 768px) {
        .history-card .card .history-card-image {
          max-width: 300px; } }
      .history-card .card .history-card-image img {
        display: none;
        border-radius: 0;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px; }
        @media (min-width: 768px) {
          .history-card .card .history-card-image img {
            display: inherit;
            width: 100%;
            height: auto;
            max-height: 236px;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px; } }
    .history-card .card .card-middle {
      flex-direction: column;
      width: auto;
      font-size: 0.875rem;
      padding: 0 20px 20px;
      font-size: 0.875rem;
      margin: 0; }
      .history-card .card .card-middle > div {
        width: auto; }
      .history-card .card .card-middle .top {
        flex-direction: column;
        margin-bottom: 15px; }
        .history-card .card .card-middle .top .name h3 {
          font-weight: 700;
          margin-bottom: 0; }
        .history-card .card .card-middle .top .resume-line .dot {
          color: #707a85;
          margin-left: .2em;
          font-size: 1em;
          font-weight: 700; }
          @media (min-width: 768px) {
            .history-card .card .card-middle .top .resume-line .dot {
              margin-left: .5em; } }
        .history-card .card .card-middle .top .resume-line .label-text {
          font-size: 0.875rem;
          color: #707a85;
          margin-right: .2em; }
          @media (min-width: 768px) {
            .history-card .card .card-middle .top .resume-line .label-text {
              margin-right: .5em; } }
        .history-card .card .card-middle .top .resume-line .more {
          cursor: pointer;
          color: #1e4acc;
          font-size: 0.875rem;
          font-weight: 400;
          text-decoration: underline; }
        .history-card .card .card-middle .top .delivered .card-label {
          font-weight: 700;
          margin-right: .5em; }
      .history-card .card .card-middle .phc-offers ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0; }
        .history-card .card .card-middle .phc-offers ul li {
          display: flex;
          align-items: center;
          font-size: 0.9375rem;
          margin-bottom: .5em;
          line-height: .5em; }
        .history-card .card .card-middle .phc-offers ul .dot {
          margin-left: 7px;
          margin-bottom: 0; }
        .history-card .card .card-middle .phc-offers ul .phc-item-quantity {
          border: 1px solid #c2d1d9;
          padding: .6em .7em;
          margin-right: 1.2em;
          line-height: .5rem;
          font-size: 1em; }
        .history-card .card .card-middle .phc-offers ul .phc-offer-detail {
          display: flex;
          flex-direction: column;
          flex: 1 1 100%;
          line-height: 1.4rem; }
          .history-card .card .card-middle .phc-offers ul .phc-offer-detail .main {
            margin: 0;
            line-height: 1em;
            font-size: 1em; }
          .history-card .card .card-middle .phc-offers ul .phc-offer-detail .secondary {
            margin: 0;
            color: #707a85;
            font-weight: 200;
            font-size: 1em;
            line-height: 1.5em; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.purchase-history-dialog .phd-header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background: #551ca6; }
  .purchase-history-dialog .phd-header .phd-logo {
    left: 1em;
    display: flex; }
    .purchase-history-dialog .phd-header .phd-logo img {
      fill: #ffffff !important; }
    .purchase-history-dialog .phd-header .phd-logo .phd-close {
      background: transparent;
      border: none;
      position: absolute;
      top: 1em;
      right: .8em;
      transition: all 200ms;
      opacity: 100%; }
      .purchase-history-dialog .phd-header .phd-logo .phd-close svg {
        fill: #ffffff !important;
        opacity: 35%; }
        .purchase-history-dialog .phd-header .phd-logo .phd-close svg:hover, .purchase-history-dialog .phd-header .phd-logo .phd-close svg:focus {
          opacity: 100%; }
      .purchase-history-dialog .phd-header .phd-logo .phd-close img {
        width: 16px; }
  .purchase-history-dialog .phd-header .phd-title {
    color: #ffffff;
    padding: 1em 0 .5em;
    font-size: 1.3em;
    font-weight: 700;
    line-height: 1.5em; }

.purchase-history-dialog .phd-order-type-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to bottom right, #551ca6 50%, #4853b6 0%, white 50%); }
  .purchase-history-dialog .phd-order-type-icon img {
    width: 6em; }

.purchase-history-dialog .phd-body .phd-rest-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5em; }
  .purchase-history-dialog .phd-body .phd-rest-info h3 {
    display: flex;
    align-items: center;
    font-size: 1.5em;
    margin-bottom: .2em;
    font-weight: 700; }
  .purchase-history-dialog .phd-body .phd-rest-info p {
    margin: 0 0 .5em;
    font-weight: 700; }

.purchase-history-dialog .phd-body .phd-total-price {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 1.5em;
  line-height: 2em;
  font-weight: 700;
  margin-bottom: .5em; }

.purchase-history-dialog .phd-body .phd-offers ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0; }
  .purchase-history-dialog .phd-body .phd-offers ul li {
    display: flex;
    align-items: center;
    font-size: 0.9375rem;
    margin-bottom: 0;
    line-height: 1em; }
  .purchase-history-dialog .phd-body .phd-offers ul .phd-offer-quantity {
    border: 1px solid #c2d1d9;
    padding: .4em .7em;
    margin-right: .5em;
    line-height: 1rem;
    font-size: 1em; }
  .purchase-history-dialog .phd-body .phd-offers ul .phd-offer-detail {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    line-height: 1em; }
    .purchase-history-dialog .phd-body .phd-offers ul .phd-offer-detail .main {
      margin: 0 0 0 .35em;
      line-height: 1em;
      font-size: 1em; }
    .purchase-history-dialog .phd-body .phd-offers ul .phd-offer-detail .secondary {
      margin: 0;
      color: #707a85;
      font-weight: 200;
      font-size: .9em;
      line-height: 1.5em; }
    .purchase-history-dialog .phd-body .phd-offers ul .phd-offer-detail .tertiary {
      margin: 0;
      color: #a1aeb7;
      font-weight: 200;
      font-size: .9em;
      line-height: 1.5em; }
  .purchase-history-dialog .phd-body .phd-offers ul .phd-offer-price {
    text-align: end;
    width: 12em; }

.purchase-history-dialog .phd-body .phd-cost-resume .item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  .purchase-history-dialog .phd-body .phd-cost-resume .item p {
    align-items: center;
    margin: 0;
    line-height: 2em; }

.purchase-history-dialog .phd-body .phd-pay-method {
  padding: .5em 0; }
  .purchase-history-dialog .phd-body .phd-pay-method .light {
    margin-left: .5em; }

.purchase-history-dialog .phd-footer {
  display: flex;
  align-items: center;
  padding-bottom: 2.5em; }
  .purchase-history-dialog .phd-footer button {
    min-width: 168px; }

.purchase-history-dialog .strong {
  font-weight: 700; }

.purchase-history-dialog .light {
  font-weight: 300; }

.purchase-history-dialog .phd-divider-white {
  width: 32px;
  margin: 0.5em 0;
  border-bottom: 2px solid #ffffff; }

.purchase-history-dialog .phd-divider-green {
  width: 32px;
  margin: 0.5em 0;
  border-bottom: 2px solid #1e4acc; }

.purchase-history-dialog .phd-line {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1em 0 .5em; }

.purchase-history-dialog > div > div {
  margin: 0 !important;
  width: 100% !important; }
  @media (min-width: 768px) {
    .purchase-history-dialog > div > div {
      margin: 1em !important;
      width: auto; } }

/* MUI adjust styles *
.MuiDialog-paper {
  margin: 0 !important;

  @media (min-width: 768px) {
    margin: 0 !important;
  }
}

.MuiDialog-paper.MuiDialog-paperFullWidth, 
.MuiDialog-paper.MuiDialog-paperScrollPaper {
  width: 100% !important;

  @media (min-width: 768px) {
    width: calc(100% - 1em);
  }
}
*/

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.resend-order-dialog {
  font-size: 1rem;
  padding: 2em; }
  .resend-order-dialog .line {
    border: none;
    border-top: 1px solid #c2d1d9;
    height: 2px;
    width: 100%;
    margin: 1em 0 .5em; }
  .resend-order-dialog .rod-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; }
    .resend-order-dialog .rod-header__close {
      position: absolute;
      right: 1em;
      line-height: 0;
      transform: translateX(30%); }
      .resend-order-dialog .rod-header__close img {
        width: 16px; }
  .resend-order-dialog .rod-body {
    margin: 1em; }
    .resend-order-dialog .rod-body .rest-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px; }
      .resend-order-dialog .rod-body .rest-info .nameRestaurant {
        display: flex;
        align-items: center;
        font-size: 18px;
        margin-bottom: 3px;
        font-weight: bold; }
    .resend-order-dialog .rod-body .total-price .h2 {
      display: flex;
      align-items: flex-start;
      justify-content: space-between; }
      .resend-order-dialog .rod-body .total-price .h2 .bigTotalPrice {
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        margin-bottom: 5px; }
    .resend-order-dialog .rod-body .orders-items ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0; }
      .resend-order-dialog .rod-body .orders-items ul li {
        font-size: 0.9375rem;
        margin-bottom: 10px; }
      .resend-order-dialog .rod-body .orders-items ul li:last-child {
        margin-left: 7px;
        margin-bottom: 0; }
      .resend-order-dialog .rod-body .orders-items ul .item-quantity {
        border: 1px solid #c2d1d9;
        padding: 2px 9px;
        margin-right: 10px; }
  .resend-order-dialog .rod-footer {
    position: relative;
    display: flex;
    padding: 0 1em 1em 1em; }
    .resend-order-dialog .rod-footer button {
      width: 100% !important;
      font-size: 1.5em; }
      @media (min-width: 768px) {
        .resend-order-dialog .rod-footer button {
          font-size: 0.9rem; } }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.cart-resume {
  font-size: 0.9rem; }
  .cart-resume .cart-resume-title {
    display: grid;
    grid-template-columns: 88% 12%;
    align-items: center;
    background: #efefef; }
    .cart-resume .cart-resume-title h1 {
      display: block;
      padding: 0.6em;
      font-size: 1.3em;
      font-weight: 700;
      color: #1d1d1f;
      text-align: center;
      margin: 0; }
    .cart-resume .cart-resume-title .edit-purchase {
      width: 90%;
      height: 70%;
      padding-top: 0.6em;
      display: block;
      align-items: center;
      text-align: center;
      color: #1d1d1f;
      border: 0.8px solid #c2d1d9; }
      .cart-resume .cart-resume-title .edit-purchase:hover {
        cursor: pointer;
        color: #ffffff;
        background: #1d1d1f; }
  .cart-resume__container {
    padding: 0 0 1em 0;
    background: white; }
    @media (min-width: 768px) {
      .cart-resume__container {
        padding: 0 2.5em 1em 2.5em; } }
  .cart-resume__item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 1em 0; }
    .cart-resume__item span {
      vertical-align: text-bottom; }
    .cart-resume__item__info {
      display: flex;
      flex-grow: 1; }
      .cart-resume__item__info > * {
        display: inline-block;
        margin-right: 0.5em; }
      .cart-resume__item__info__count {
        color: #1d1d1f;
        background-color: #f1f1f1;
        font-weight: 700;
        margin-right: 0.5em;
        padding: 0px 8px;
        height: 23px; }
        @media (min-width: 768px) {
          .cart-resume__item__info__count {
            margin-right: 1em; } }
      .cart-resume__item__info .title {
        font-weight: 700;
        font-size: 17px; }
      .cart-resume__item__info .details {
        font-size: 0.8em;
        font-weight: 200;
        color: #a1aeb7; }
        @media (min-width: 768px) {
          .cart-resume__item__info .details {
            font-size: 0.9em; } }
    .cart-resume__item__price {
      font-size: 17px; }
  .cart-resume .subtotal,
  .cart-resume .cost,
  .cart-resume .total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 17px;
    margin: 0.5em 0; }
  .cart-resume .subtotal span:first-child,
  .cart-resume .cost span:first-child {
    font-size: 17px; }
  .cart-resume .total {
    font-weight: 700;
    padding-top: 0.5em;
    font-size: 17px; }
  .cart-resume .coupon {
    font-size: 1em;
    font-weight: 400;
    margin: 1.8em 0; }
    .cart-resume .coupon-label {
      color: #1d1d1f;
      font-weight: 700; }
    .cart-resume .coupon .grid--small-form {
      margin: 0.5em 0;
      grid-column-gap: 1em;
      grid-column-gap: 0.7em;
      -webkit-column-gap: 0.7em;
              column-gap: 0.7em; }
      @media (min-width: 768px) {
        .cart-resume .coupon .grid--small-form {
          grid-template-columns: 65% 30%; } }

.checkout-order {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 1em;
  background-color: #ffffff;
  box-shadow: 0 5px 15px 15px rgba(0, 0, 0, 0.1); }
  .checkout-order > button {
    position: relative;
    font-size: 1.2em;
    text-transform: none; }
    @media (min-width: 768px) and (max-width: 1199px) {
      .checkout-order > button {
        width: 100% !important; } }
    .checkout-order > button span {
      vertical-align: middle; }
    .checkout-order > button > *:nth-child(1) {
      position: absolute;
      top: 6px;
      left: 10px;
      background: #428d44;
      border-radius: 3px;
      padding: 4px 7px 0 7px; }
    .checkout-order > button > *:nth-child(3) {
      position: absolute;
      top: 10px;
      right: 10px;
      font-weight: bold; }

.checkout-order-dialog__header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #ffffff;
  box-shadow: 0 5px 15px 15px rgba(0, 0, 0, 0.1); }
  @media (min-width: 768px) and (max-width: 1199px) {
    .checkout-order-dialog__header {
      box-shadow: none !important; } }
  .checkout-order-dialog__header span {
    color: #1d1d1f;
    font-weight: 700; }
  .checkout-order-dialog__header_close {
    position: absolute;
    right: 1em;
    line-height: 0;
    transform: translateX(30%); }

.checkout-order-dialog__content {
  background: #f8f8f8;
  padding: 1em !important;
  margin: 0 !important; }
  @media (min-width: 768px) and (max-width: 1199px) {
    .checkout-order-dialog__content {
      padding: 1em 3em !important; } }

.checkout-order-dialog__actions {
  box-shadow: 0 5px 15px 15px rgba(0, 0, 0, 0.1) !important;
  background: #f8f8f8;
  padding: 1em !important;
  flex-direction: column; }
  @media (min-width: 768px) and (max-width: 1199px) {
    .checkout-order-dialog__actions {
      padding: 1em 3em !important;
      box-shadow: none !important; } }
  .checkout-order-dialog__actions_coupon {
    width: 100% !important;
    text-align: left !important;
    font-size: 1em;
    font-weight: 400;
    grid-column-gap: 2em;
    grid-template-columns: 52% 30%; }
    @media (min-width: 768px) {
      .checkout-order-dialog__actions_coupon {
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
        margin: 0 2.5em;
        grid-template-columns: 70% 30%; } }
    .checkout-order-dialog__actions_coupon .coupon-label {
      color: #1d1d1f;
      font-weight: 700; }
    .checkout-order-dialog__actions_coupon .grid--small-form {
      margin: 0.5em 0;
      grid-column-gap: 1em;
      grid-column-gap: 0.7em;
      -webkit-column-gap: 0.7em;
              column-gap: 0.7em; }
      @media (min-width: 768px) {
        .checkout-order-dialog__actions_coupon .grid--small-form {
          grid-template-columns: 70% 30%; } }
  .checkout-order-dialog__actions_resume {
    width: 85% !important;
    display: grid;
    grid-template-columns: 10% 90%;
    grid-column-gap: 1em; }
    @media (min-width: 1200px) {
      .checkout-order-dialog__actions_resume {
        width: 100% !important;
        grid-template-columns: 35% 57% 8%; } }
    .checkout-order-dialog__actions_resume .subtotal,
    .checkout-order-dialog__actions_resume .cost,
    .checkout-order-dialog__actions_resume .total {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .checkout-order-dialog__actions_resume .subtotal span:first-child,
    .checkout-order-dialog__actions_resume .cost span:first-child {
      font-size: 1em; }
    .checkout-order-dialog__actions_resume .total {
      font-weight: 700;
      padding-top: 0.5em;
      font-size: 1.05em; }
    .checkout-order-dialog__actions_resume .spacer {
      margin: 1.5em 0 0.5em 0 !important; }
  .checkout-order-dialog__actions_pay-button {
    margin: 1.5em !important;
    justify-content: center;
    text-align: center;
    display: flex; }
    .checkout-order-dialog__actions_pay-button button {
      width: 70%;
      font-size: 1.2em; }
      @media (min-width: 768px) {
        .checkout-order-dialog__actions_pay-button button {
          width: 60%;
          font-size: 0.9rem; } }
    .checkout-order-dialog__actions_pay-button a {
      width: 70%; }
      @media (min-width: 768px) {
        .checkout-order-dialog__actions_pay-button a {
          width: 60%; } }
  .checkout-order-dialog__actions_buttons {
    width: 100%;
    margin: 1em 0;
    display: flex;
    justify-content: center;
    grid-gap: 0.8em;
    gap: 0.8em;
    text-align: center !important; }
    @media (min-width: 768px) and (max-width: 1199px) {
      .checkout-order-dialog__actions_buttons {
        width: 75%;
        grid-template-columns: 1fr 1fr; } }
    .checkout-order-dialog__actions_buttons button {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 1em; }

.checkout-stepper {
  width: 100% !important;
  background: #f8f8f8 !important;
  padding: 2em 1em !important;
  margin-top: 2em !important; }
  @media (min-width: 768px) {
    .checkout-stepper {
      background: #ffffff !important; } }

.steps {
  font-size: 0.9rem;
  padding: 1em;
  width: calc(100% - 2em);
  display: flex;
  justify-content: center; }
  @media (min-width: 1200px) {
    .steps {
      padding: 2em 1em;
      width: calc(100% - 2em); } }
  .steps > div {
    width: 100% !important; }
  .steps__header {
    margin-left: 1.5em; }
    .steps__header h1 {
      font-size: 1.2em;
      font-weight: 600;
      color: #1d1d1f;
      margin-bottom: 0.6em; }
    .steps__header .label-icon {
      font-size: 1em;
      color: #1d1d1f; }
    .steps__header .details {
      font-size: 1em;
      font-weight: 500;
      color: #1d1d1f;
      margin-right: 1em;
      margin-bottom: 0.3em !important; }
      .steps__header .details span {
        display: flex;
        align-items: center; }
        .steps__header .details span img {
          width: 1.2em;
          margin-right: 0.5em; }
      .steps__header .details h3 {
        margin: 0;
        text-align: left;
        color: #c2d1d9; }
      .steps__header .details_last {
        margin-bottom: 1em !important; }
  .steps .step {
    border: none;
    width: 100%; }
    @media (max-width: 640px) {
      .steps .step {
        min-width: unset; } }
    .steps .step__section {
      padding: 3% 0;
      border-bottom: 1px solid #c2d1d9; }
      .steps .step__section h3 {
        font-size: 1em;
        font-weight: 600;
        color: #1d1d1f; }
        .steps .step__section h3 a {
          float: right;
          color: #9abe29;
          font-size: 0.8em; }
      .steps .step__section input {
        border-radius: 0.3em;
        border: 1px solid #c2d1d9; }
      .steps .step__section .textarea {
        border-radius: 0.3em; }
      .steps .step__section .grid--form {
        padding: 1em 0 0; }
      .steps .step__section .field-group {
        color: #707a85; }
        .steps .step__section .field-group .label,
        .steps .step__section .field-group .hint {
          font-size: 0.8em;
          line-height: 1.8;
          font-style: italic; }
        .steps .step__section .field-group .hint_alert {
          font-size: 0.8em;
          line-height: 1.8;
          font-style: italic; }
          .steps .step__section .field-group .hint_alert_success {
            color: #9abe29; }
          .steps .step__section .field-group .hint_alert_error {
            color: red; }

.button-wrapper {
  padding: 6% 25% 0; }
  .button-wrapper--double {
    display: flex;
    justify-content: space-evenly;
    padding: 2% 6%; }
    .button-wrapper--double > button {
      max-width: 50%; }
  .button-wrapper--sticky {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background: white;
    box-shadow: 0 -5px 5px -5px #333;
    padding: 3em; }
  .button-wrapper--flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    max-width: unset;
    margin: auto;
    padding: 0; }
    .button-wrapper--flex > button:nth-child(1) {
      width: 100%;
      margin-right: .35em; }
    .button-wrapper--flex > button:nth-child(2) {
      width: 100%;
      margin-left: .35em; }

.react-datepicker-wrapper {
  width: 100%; }

.braintree-heading:first-child {
  transform: none !important;
  visibility: visible !important;
  font-size: 1em;
  font-weight: 500;
  color: #1d1d1f; }

.braintree-heading:nth-child(2) {
  font-size: 0.8em;
  font-weight: 500;
  color: #9abe29;
  text-decoration: none; }

.cart-resume-sidebar {
  width: calc(100% - 1em);
  border-radius: 12px;
  box-shadow: inset 0px 0px 0px 1px #c2d1d9;
  margin-top: 4.85em; }
  .cart-resume-sidebar .cart-resume__container {
    background: transparent;
    padding: 1em 1.5em 0 1.5em; }
  .cart-resume-sidebar .cart-resume__item__info .title {
    font-size: 16px;
    font-weight: 600; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.step-delivery__actions {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .step-delivery__actions button {
    font-size: .9em;
    font-weight: 500;
    border: 1px solid #1e4acc;
    box-shadow: none;
    padding: 5px 10px; }
    .step-delivery__actions button > svg {
      fill: #1e4acc !important;
      margin-right: 0; }
    .step-delivery__actions button:hover > svg, .step-delivery__actions button:focus > svg {
      fill: #ffffff !important; }
    .step-delivery__actions button > span {
      display: none;
      font-size: 1em;
      margin: 0 .2em; }
      @media (min-width: 768px) {
        .step-delivery__actions button > span {
          display: inline-block;
          font-size: 1.125em; } }

.creditcard-skeleton {
  border-radius: 5px; }

.payment-methods {
  /*
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  gap: 0.8em;
  */ }
  .payment-methods .payment-row {
    cursor: pointer;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    min-height: 48px;
    height: auto;
    background-color: white;
    padding: 0.5em 0 0.5em 1em;
    margin: 0.65em auto;
    position: relative;
    transition: opacity 300ms, width 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275), border-color 300ms, border-radius 300ms;
    border: 1px solid #c2d1d9;
    border-radius: 5px; }
    @media (min-width: 768px) {
      .payment-methods .payment-row {
        height: 58px;
        padding: 0;
        padding-left: 1em; } }
    .payment-methods .payment-row img {
      width: 48px;
      height: auto; }
      .payment-methods .payment-row img.card-icon {
        margin-right: 1em; }
      .payment-methods .payment-row img:last-child {
        background-color: #fafafa;
        border-radius: 100px;
        fill: #fafafa;
        padding: 5px;
        transform: scale(0);
        transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
    .payment-methods .payment-row .button-link {
      display: flex;
      align-items: center; }
    .payment-methods .payment-row--active {
      border: 1px solid #1e4acc;
      transition: opacity 300ms, width 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275), border-color 300ms, border-radius 300ms; }
      .payment-methods .payment-row--active img:last-child {
        fill: white;
        transform: scale(0.5);
        width: 50px;
        background-color: #1e4acc; }
    .payment-methods .payment-row__text {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      flex-grow: 1;
      font-size: 15px;
      line-height: 1.35;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden; }
      @media (min-width: 768px) {
        .payment-methods .payment-row__text {
          font-size: 16px;
          line-height: 1.45; } }
      .payment-methods .payment-row__text > *:last-child {
        font-size: 0.8em; }

.success-dialog__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 1em 0; }

.success-dialog__card {
  border: 1px solid lightgray;
  padding: 0.5em;
  border-radius: 5px;
  font-size: medium;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.mb-20 {
  margin-bottom: 20px; }

.MuiDialog-paper {
  margin: 1em !important; }
  .MuiDialog-paper.MuiDialog-paperFullWidth, .MuiDialog-paper.MuiDialog-paperScrollPaper {
    width: calc(100% - 1em); }

.MuiDialogTitle-root {
  right: 0;
  padding: 1.5em 1em 1em 1em !important; }
  .MuiDialogTitle-root > h2 > button {
    right: 0; }
    @media (min-width: 768px) {
      .MuiDialogTitle-root > h2 > button {
        right: 2em; } }
  @media (min-width: 768px) {
    .MuiDialogTitle-root {
      padding: 1.5em 2em 1em 2em !important; } }
  .MuiDialogTitle-root > .MuiTypography-h6 {
    font-weight: 600; }

.makeStyles-dialog-8 {
  padding: 1em 1em 2em !important; }
  @media (min-width: 768px) {
    .makeStyles-dialog-8 {
      padding: 1em 2em 2em !important; } }

.MuiOutlinedInput-root fieldset {
  border-radius: 5px; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.payment-success .account-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  max-width: 1366px;
  padding: 1.5em 1em 1.5em;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .payment-success .account-container {
      padding: 4.5em 1em 1.5em; } }

.payment-success .fixed-block {
  flex: 0 0 auto; }

.payment-success .expanded-block {
  flex: 1 1 auto; }

.payment-success .basic-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  left: auto;
  right: auto;
  height: auto;
  text-align: center;
  border: 1px solid #d3dce6;
  border-radius: 8px;
  background: white;
  box-shadow: none;
  padding: 1em 1em 2em;
  margin: 0; }
  @media (min-width: 768px) {
    .payment-success .basic-card {
      border-radius: 12px;
      padding: 1.85rem; } }

.payment-success .basic-card.small {
  max-width: 320px;
  width: auto; }

.payment-success .basic-card.medium {
  max-width: 540px;
  width: auto; }

.payment-success .basic-card.large {
  max-width: 750px;
  width: auto; }

.payment-success .basic-card img.card-icon {
  align-self: center;
  width: 80px;
  height: auto;
  max-height: 80px;
  margin: 1rem 0;
  padding: 0; }

.payment-success .basic-card h4 {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin-top: 0;
  margin-bottom: 12px; }

.payment-success .basic-card p {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.125rem;
  margin: 0; }

.payment-success .basic-card .button--primary {
  min-width: 142px; }

.payment-success .basic-card .resume {
  font-size: 0.85rem;
  /* 15px; */
  padding-top: 10px; }

.payment-success .basic-card .resume table {
  width: 100%; }

.payment-success .basic-card .resume tr {
  vertical-align: top; }

.payment-success .basic-card .resume tr td {
  text-align: left; }

.payment-success .basic-card .resume tr td:first-child {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: #707a85;
  padding-right: 20px; }

.payment-success .basic-card .resume tr td:last-child {
  text-align: right; }

.payment-success .d-flex {
  display: flex; }

.payment-success .flex-column {
  flex-direction: column; }

.payment-success .justfy-content-center {
  justify-content: center; }

.payment-success .mt-50 {
  margin-top: 50px; }

.payment-success .mt-20 {
  margin-top: 20px; }

.payment-success .mb-20 {
  margin-bottom: 20px; }

.payment-success .mb-0 {
  margin-bottom: 0px; }

.payment-success .Flex {
  display: flex;
  align-items: flex-start; }

.payment-success .FlexColumn {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.payment-error .account-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  max-width: 1366px;
  padding: 4rem 20px 20px;
  margin: 0 auto; }

@media (max-width: 767px) {
  .payment-error .account-container {
    padding: 4.5rem 10px 10px; } }

.payment-error .fixed-block {
  flex: 0 0 auto; }

.payment-error .expanded-block {
  flex: 1 1 auto; }

.payment-error .basic-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  left: auto;
  right: auto;
  height: auto;
  text-align: center;
  border: 1px solid #d3dce6;
  background: white;
  box-shadow: none;
  padding: 1.85rem;
  margin: 0; }

.payment-error .basic-card.small {
  max-width: 320px;
  width: auto; }

.payment-error .basic-card.medium {
  max-width: 540px;
  width: auto; }

.payment-error .basic-card.large {
  max-width: 750px;
  width: auto; }

.payment-error .basic-card img.card-icon {
  align-self: center;
  width: 80px;
  height: auto;
  max-height: 80px;
  margin: 1rem 0;
  padding: 0; }

.payment-error .basic-card h4 {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin-top: 0;
  margin-bottom: 12px; }

.payment-error .basic-card p {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.125rem;
  margin: 0; }

.payment-error .basic-card .button--primary {
  min-width: 142px; }

.payment-error .basic-card .resume {
  font-size: 0.85rem;
  /* 15px; */
  padding-top: 10px; }

.payment-error .basic-card .resume table {
  width: 100%; }

.payment-error .basic-card .resume tr {
  vertical-align: top; }

.payment-error .basic-card .resume tr td {
  text-align: left; }

.payment-error .basic-card .resume tr td:first-child {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: #707a85;
  padding-right: 20px; }

.payment-error .basic-card .resume tr td:last-child {
  text-align: right; }

.payment-error .d-flex {
  display: flex; }

.payment-error .flex-column {
  flex-direction: column; }

.payment-error .justfy-content-center {
  justify-content: center; }

.payment-error .mt-50 {
  margin-top: 50px; }

.payment-error .mb-20 {
  margin-bottom: 20px; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main, .email_verification {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.email_verification {
  display: flex;
  justify-items: center;
  justify-content: center;
  padding: 20px 0; }
  @media (min-width: 768px) {
    .email_verification {
      padding: 64px; } }
  .email_verification .container {
    max-width: 500px; }
    @media (min-width: 768px) {
      .email_verification .container {
        max-width: 700px; } }
    .email_verification .container .note {
      box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.1);
      width: 100%;
      background: #693478;
      color: #ffffff;
      padding: 0 0.5em;
      margin-bottom: 1em;
      display: grid;
      grid-template-columns: 10% 80% 10%;
      align-items: center;
      border-radius: 0; }
      @media (min-width: 1200px) {
        .email_verification .container .note {
          grid-template-columns: 5% 90% 5%; } }
      .email_verification .container .note p {
        font-size: 0.9rem;
        font-weight: 200;
        text-align: center; }
        @media (min-width: 1200px) {
          .email_verification .container .note p {
            text-align: left; } }
      .email_verification .container .note button {
        background: transparent;
        border: none; }
        .email_verification .container .note button:hover {
          cursor: pointer; }
    .email_verification .container .form {
      width: 100%;
      background: #ffffff;
      padding: 1em 0.5em;
      text-align: center;
      border-radius: 0; }
      @media (min-width: 1200px) {
        .email_verification .container .form {
          padding: 2em 5em; } }
      .email_verification .container .form .title {
        font-size: 2rem;
        line-height: 1.5rem;
        letter-spacing: -0.025em;
        background-image: none;
        color: #1d1d1f;
        min-height: 55px;
        margin: 0;
        font-family: "Nunito Sans";
        font-weight: 300;
        text-align: center;
        color: #094296;
        margin-bottom: 0.5rem; }
        @media (min-width: 768px) {
          .email_verification .container .form .title {
            font-size: 2.1rem;
            line-height: 2.375rem;
            background-image: none;
            min-height: 0;
            margin: 0;
            margin: initial; } }
        .email_verification .container .form .title span {
          display: block;
          margin: 15px auto 0;
          background: #e50914;
          width: 36px;
          height: 2px; }
      .email_verification .container .form .subtitle {
        font-size: 1em;
        line-height: 1.5rem;
        display: inherit;
        padding: 20px 30px;
        color: #2a2a2a; }
        .email_verification .container .form .subtitle .emailHint {
          font-weight: bold; }
      .email_verification .container .form .fields {
        display: flex;
        justify-items: center;
        justify-content: center;
        padding: 0 0 0.5em 0; }
        .email_verification .container .form .fields-error {
          display: block;
          max-width: 300px;
          width: 100%;
          text-align: left;
          font-size: 0.875rem;
          line-height: 1.5;
          font-family: "Nunito Sans";
          font-weight: 400;
          color: #ff424d;
          margin: 0;
          height: 0; }
        .email_verification .container .form .fields-form-error {
          height: auto;
          text-align: center;
          display: block;
          background: red;
          color: white !important;
          padding: 0.5em;
          font-weight: bold !important;
          border-radius: 8px; }
        .email_verification .container .form .fields .extra {
          display: grid;
          grid-template-columns: 40% 60%;
          align-items: center;
          font-size: 0.8em;
          margin: 0;
          padding: 0; }
          .email_verification .container .form .fields .extra:first-child {
            text-align: left; }
          .email_verification .container .form .fields .extra:last-child {
            text-align: right;
            margin-right: 1em; }
          .email_verification .container .form .fields .extra span {
            font-size: 1em; }
        .email_verification .container .form .fields .action {
          padding-top: 1.5em; }
        .email_verification .container .form .fields button {
          font-size: 1rem;
          font-weight: 400;
          text-transform: none !important;
          line-height: 1.5;
          height: 50px;
          margin: 0 auto;
          outline: 0px;
          border: 0px;
          font-family: "Nunito Sans";
          width: 100%;
          min-width: 64px;
          max-width: 295px;
          border-radius: 8px;
          color: white;
          background-color: #1e4acc;
          box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
          font-weight: 700;
          transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
          .email_verification .container .form .fields button span {
            color: #1e4acc; }
          .email_verification .container .form .fields button:hover span, .email_verification .container .form .fields button:focus span {
            color: #0D35AB; }
        .email_verification .container .form .fields .register-link {
          display: flex;
          justify-items: center;
          justify-content: center;
          align-items: center;
          font-size: 0.5em; }
          .email_verification .container .form .fields .register-link p {
            text-align: center;
            font-size: 0.9rem;
            color: #1d1d1f;
            padding-right: 0.8em; }
          .email_verification .container .form .fields .register-link .button-link {
            font-weight: 400;
            color: #1e4acc; }
          .email_verification .container .form .fields .register-link:hover {
            cursor: pointer; }

.verification_alert .alert__body .alert_title {
  text-align: center; }
  .verification_alert .alert__body .alert_title .alert_icon {
    width: 5em; }
  .verification_alert .alert__body .alert_title .alert_header_title {
    font-weight: 600;
    color: green; }

.verification_alert .alert__body .alert_message {
  text-align: center; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.selector {
  display: flex;
  justify-content: center;
  margin-bottom: 2em; }

.field-group {
  color: #707a85; }
  @media (min-width: 768px) {
    .field-group__half {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0.8em;
      gap: 0.8em; } }

.steps {
  font-size: 0.9rem;
  padding: 1em;
  width: calc(100% - 2em);
  display: flex;
  justify-content: center; }
  @media (min-width: 1200px) {
    .steps {
      padding: 2em 1em;
      width: calc(100% - 2em); } }
  .steps > div {
    width: 100% !important; }
  .steps__header {
    margin-left: 1.5em; }
    .steps__header h1 {
      font-size: 1.2em;
      font-weight: 600;
      color: #1d1d1f;
      margin-bottom: 0.6em; }
    .steps__header .label-icon {
      font-size: 1em;
      color: #1d1d1f; }
    .steps__header .details {
      font-size: 1em;
      font-weight: 500;
      color: #1d1d1f;
      margin-right: 1em;
      margin-bottom: 0.3em !important; }
      .steps__header .details span {
        display: flex;
        align-items: center; }
        .steps__header .details span img {
          width: 1.2em;
          margin-right: 0.5em; }
      .steps__header .details h3 {
        margin: 0;
        text-align: left;
        color: #c2d1d9; }
      .steps__header .details_last {
        margin-bottom: 1em !important; }
  .steps .step {
    border: none;
    width: 100%; }
    @media (max-width: 640px) {
      .steps .step {
        min-width: unset; } }
    .steps .step__section {
      padding: 3% 0;
      border-bottom: 1px solid #c2d1d9; }
      .steps .step__section h3 {
        font-size: 1em;
        font-weight: 600;
        color: #1d1d1f; }
        .steps .step__section h3 a {
          float: right;
          color: #9abe29;
          font-size: 0.8em; }
      .steps .step__section input {
        border-radius: 0.3em;
        border: 1px solid #c2d1d9; }
      .steps .step__section .textarea {
        border-radius: 0.3em; }
      .steps .step__section .grid--form {
        padding: 1em 0 0; }
      .steps .step__section .field-group {
        color: #707a85; }
        @media (min-width: 768px) {
          .steps .step__section .field-group__half {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 0.8em;
            gap: 0.8em; } }
        .steps .step__section .field-group .label,
        .steps .step__section .field-group .hint {
          font-size: 0.8em;
          line-height: 1.8;
          font-style: italic; }
        .steps .step__section .field-group .hint_alert {
          font-size: 0.8em;
          line-height: 1.8;
          font-style: italic; }
          .steps .step__section .field-group .hint_alert_success {
            color: #9abe29; }
          .steps .step__section .field-group .hint_alert_error {
            color: red; }

.new__steps {
  padding: 1em;
  width: auto;
  margin: auto; }
  @media (min-width: 768px) {
    .new__steps {
      width: 750px; } }

.new__step {
  min-width: unset !important;
  padding: 0em !important; }
  @media (min-width: 768px) {
    .new__step {
      padding: 0em 1em 1em 1em !important; } }
  .new__step__header-wrapper {
    background-color: white;
    padding: 0; }
    .new__step__header-wrapper .button--primary {
      border: 1px solid #1e4acc;
      box-shadow: none; }
    @media (min-width: 768px) {
      .new__step__header-wrapper {
        padding: 1em 2.5em 0; } }
  .new__step__header {
    font-weight: 800;
    font-size: 1.4rem;
    text-align: center; }
    @media (min-width: 768px) {
      .new__step__header {
        font-size: 2.4rem; } }
  .new__step__footer {
    position: relative;
    width: 100%;
    background: white; }
    .new__step__footer label > span {
      font-size: 1em; }
    .new__step__footer__container {
      padding: 2em 0 1em;
      justify-content: center; }
      @media (min-width: 768px) {
        .new__step__footer__container {
          padding: 2em 2.5em 1em 2.5em; } }

.new__spacer {
  border: none;
  border-top: 1px solid #f1f1f1;
  height: 2px;
  width: 100%;
  margin: 2em 0; }

.new__button {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px; }
  .new__button__address {
    width: 100%; }
  .new__button p {
    font-size: 17px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    text-align: left;
    width: 100%;
    padding: 0; }
    .new__button p span {
      font-size: 15px;
      font-weight: 500; }

.step-delivery__button-next {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2em 0 0.5em 0; }
  .step-delivery__button-next button {
    width: 100% !important;
    font-size: 18px !important;
    text-transform: capitalize; }
    .step-delivery__button-next button span {
      margin: 0 .2em; }
    @media (min-width: 768px) {
      .step-delivery__button-next button {
        font-size: 0.9rem; } }

.step-delivery__checkbox {
  display: flex;
  justify-content: center;
  align-items: center; }
  .step-delivery__checkbox label {
    margin: 0; }
    .step-delivery__checkbox label span {
      padding-top: 0;
      padding-left: 0;
      padding-bottom: 0; }
  .step-delivery__checkbox .Mui-checked .MuiSvgIcon-root {
    fill: #1e4acc !important; }

.coupon {
  font-size: 1em;
  font-weight: 400;
  margin: 1.8em 0; }
  .coupon-label {
    color: #1d1d1f;
    font-weight: 700; }
  .coupon .grid--small-form {
    margin: 0.5em 0;
    grid-column-gap: 1em;
    grid-column-gap: 0.7em;
    -webkit-column-gap: 0.7em;
            column-gap: 0.7em; }
    @media (min-width: 768px) {
      .coupon .grid--small-form {
        grid-template-columns: 65% 30%; } }
    .coupon .grid--small-form button {
      border: none !important; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.card_management {
  padding: 0 1em; }
  .card_management_header {
    display: flex;
    align-items: center;
    padding: 1em 0; }
    .card_management_header button {
      font-size: .9em;
      font-weight: 500;
      text-transform: none;
      border: 1px solid #1e4acc;
      box-shadow: none;
      padding: 5px 15px; }
      .card_management_header button > svg {
        fill: #1e4acc !important;
        margin-right: 0; }
        @media (min-width: 768px) {
          .card_management_header button > svg {
            margin-right: 7px; } }
      .card_management_header button:hover > svg, .card_management_header button:focus > svg {
        fill: #ffffff !important; }
      .card_management_header button > span {
        display: none;
        font-size: 1em;
        margin-right: 3px; }
        @media (min-width: 768px) {
          .card_management_header button > span {
            display: inline-block;
            font-size: 1.125em; } }
  .card_management_data li {
    display: grid;
    grid-template-columns: 1fr 48px 48px;
    background: #ffffff;
    grid-template-columns: 1fr 48px 48px;
    border: 1px solid #c2d1d9;
    border-radius: 8px;
    margin-bottom: 1em;
    padding: 1em; }
    @media (min-width: 768px) {
      .card_management_data li {
        border-radius: 12px;
        padding: 1em 1em 1em 1.5em; } }
    .card_management_data li .card_number {
      font-size: 1.1em !important;
      font-weight: 500;
      font-style: italic; }
    .card_management_data li > *:first-child {
      grid-row: 1/1;
      grid-column: 1/1;
      text-align: left;
      font-size: .9em;
      color: #707a85; }
    .card_management_data li > *:nth-child(2) {
      grid-row: 2/2;
      grid-column: 1/1;
      text-align: left;
      font-size: 1em;
      color: #2a2a2a; }
    .card_management_data li > *:nth-child(3) {
      grid-row: 1/3;
      grid-column: 2/2;
      align-self: center;
      justify-self: center; }
    .card_management_data li > *:nth-child(4) {
      grid-row: 1/3;
      grid-column: 3/3;
      align-self: center;
      justify-self: center; }
    .card_management_data li svg.MuiSvgIcon-root {
      fill: #1e4acc !important; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

body {
  color: #1d1d1f;
  font-size: 1.2rem;
  line-height: 1.6em;
  background: #ffffff; }

h1 {
  line-height: 1.3em;
  font-size: 2rem;
  color: #1d1d1f; }

p {
  margin-bottom: 1.3em; }

#preloader {
  background: rgba(0, 0, 0, 0.5) !important; }

.button-link {
  background: transparent;
  border: none;
  text-transform: inherit;
  line-height: 0;
  padding: 0; }
  .button-link:hover {
    color: #1d1d1f; }
  .button-link:focus {
    outline: none;
    box-shadow: none; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.availability-validation .account-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  max-width: 100%;
  padding: 2.3em 1em 0 1em;
  margin: 0 auto; }

@media (max-width: 767px) {
  .availability-validation .account-container {
    padding: 4.5rem 10px 10px; } }

.availability-validation .fixed-block {
  flex: 0 0 auto; }

.availability-validation .expanded-block {
  flex: 1 1 auto; }

.availability-validation .basic-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  left: auto;
  right: auto;
  height: auto;
  text-align: center;
  border: 1px solid #d3dce6;
  background: white;
  box-shadow: none;
  padding: 1.85rem;
  margin: 0; }

.availability-validation .basic-card.small {
  max-width: 320px;
  width: auto; }

.availability-validation .basic-card.medium {
  max-width: 540px;
  width: auto; }

.availability-validation .basic-card.large {
  max-width: 750px;
  width: auto; }

.availability-validation .basic-card.full {
  width: 80%; }
  @media (min-width: 768px) {
    .availability-validation .basic-card.full {
      width: 90%; } }

.availability-validation .basic-card img.card-icon {
  align-self: center;
  width: 80px;
  height: auto;
  max-height: 80px;
  margin: 1rem 0;
  padding: 0; }

.availability-validation .basic-card h4 {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin-top: 0;
  margin-bottom: 12px; }

.availability-validation .basic-card p {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.125rem;
  margin: 0; }

.availability-validation .basic-card .button--primary {
  min-width: 142px; }

.availability-validation .basic-card .resume {
  font-size: 0.85rem;
  /* 15px; */
  padding-top: 10px; }

.availability-validation .basic-card .resume table {
  width: 100%; }

.availability-validation .basic-card .resume tr {
  vertical-align: top; }

.availability-validation .basic-card .resume tr td {
  text-align: left; }

.availability-validation .basic-card .resume tr td:first-child {
  font-size: 12px; }

.availability-validation .d-flex {
  display: flex; }

.availability-validation .flex-column {
  flex-direction: column; }

.availability-validation .justfy-content-center {
  justify-content: center; }

.availability-validation .mt-50 {
  margin-top: 50px; }

.availability-validation .mb-20 {
  margin-bottom: 20px; }

.availability-validation .mt-20 {
  margin-top: 20px; }

.availability-validation .Flex {
  display: flex;
  align-items: flex-start; }

.availability-validation .FlexColumn {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%; }

.availability-validation .offerRow {
  display: flex;
  align-items: center; }
  .availability-validation .offerRow .icon-list {
    width: .3em; }

.availability-validation .info-text {
  font-size: 1.1em; }

.availability-validation .info-text_2 {
  font-size: 1.1em;
  margin-top: 1em; }

.availability-validation .dot {
  color: #707a85;
  margin-left: .2em;
  margin-right: .2em;
  font-size: 2em; }
  @media (min-width: 768px) {
    .availability-validation .dot {
      margin-left: .1em;
      margin-right: .1em; } }

.availability-validation .title {
  margin-left: .5em;
  font-weight: 700; }

.availability-validation .variant {
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1em;
  font-weight: 200;
  color: #1d1d1f; }

.availability-validation .details {
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1em;
  font-weight: 200;
  color: #a1aeb7; }

:root {
  --color-primary: #1e4acc;
  --color-ace: var(--color-primary);
  --color-feel--ace: var(--color-primary);
  --color-feel--hot: #ba5541;
  --color-feel--sun: #da9843;
  --color-feel--weed: #849631; }

.mobile--disabled {
  display: inherit !important; }

.mobile--on {
  display: none !important; }

@media (min-width: 1200px) {
  .mobile--disabled {
    display: none !important; }
  .mobile--on {
    display: inherit !important; } }

.desktop--disabled {
  display: inherit !important; }

.desktop--on {
  display: none !important; }

@media (min-width: 1200px) {
  .desktop--disabled {
    display: none !important; }
  .desktop--on {
    display: inherit !important; } }

[f-mid-mid] {
  display: flex;
  justify-content: center;
  align-items: center; }

[f-start-mid] {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

[f-start-end] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

[margins-paddings-to-zero] {
  margin: 0;
  padding: 0; }

[margin-bottom-zero] {
  margin-bottom: 0; }

[color-white] {
  color: white; }

[color-black] {
  color: black; }

[color-inherit] {
  color: inherit; }

[hover]:hover {
  cursor: pointer; }

[text-center] {
  text-align: center; }

[text-left] {
  text-align: left; }

[text-right] {
  text-align: right; }

[text-capitalize] {
  text-transform: capitalize; }

[text-uppercase] {
  text-transform: uppercase; }

[text-lowercase] {
  text-transform: lowercase; }

[relative] {
  position: relative; }

[absolute] {
  position: absolute; }

[top] {
  top: 0; }

[right] {
  right: 0; }

[bottom] {
  bottom: 0; }

[left] {
  left: 0; }

.grid--two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.grid--three-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.grid--four-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.grid--five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr); }

.grid--six-columns {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.grid--seven-columns {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }

.grid--eight-columns {
  display: grid;
  grid-template-columns: repeat(8, 1fr); }

.grid--nine-columns {
  display: grid;
  grid-template-columns: repeat(9, 1fr); }

.grid--ten-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr); }

.grid--eleven-columns {
  display: grid;
  grid-template-columns: repeat(11, 1fr); }

.grid--twelve-columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid--thirdteen-columns {
  display: grid;
  grid-template-columns: repeat(13, 1fr); }

.grid--fourteen-columns {
  display: grid;
  grid-template-columns: repeat(14, 1fr); }

.grid--sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em; }
  @media (min-width: 1200px) {
    .grid--sidebar {
      grid-template-columns: 67% 33%; } }

.grid--small-form {
  display: grid;
  grid-template-columns: 65% 30%; }

.grid--form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8em;
  gap: 0.8em; }
  .grid--form .colspan-2 {
    grid-column-start: 1;
    grid-column-end: 3; }

.margin-auto {
  margin: auto; }

.display-block {
  display: block !important; }

.center-all {
  justify-self: center;
  align-self: center; }

.text-center {
  text-align: center !important; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

[padding-vertical] {
  padding-top: 5px;
  padding-bottom: 5px; }

[padding-horizontal] {
  padding-left: 5px;
  padding-right: 5px; }

[padding] {
  padding: 5px; }

[padding-top] {
  padding-top: 5px; }

[padding-right] {
  padding-right: 5px; }

[padding-bottom] {
  padding-bottom: 5px; }

[padding-left] {
  padding-left: 5px; }

[padding-2] {
  padding: 10px; }

[padding-vertical-2] {
  padding-top: 10px;
  padding-bottom: 10px; }

[margin-vertical] {
  margin-top: 5px;
  margin-bottom: 5px; }

[margin-horizontal] {
  margin-left: 5px;
  margin-right: 5px; }

[margin] {
  margin: 5px; }

[margin-top] {
  margin-top: 5px; }

[margin-right] {
  margin-right: 5px; }

[margin-bottom] {
  margin-bottom: 5px; }

[margin-left] {
  margin-left: 5px; }

[margin-2] {
  margin: 10px; }

[margin-vertical-2] {
  margin-top: 10px;
  margin-bottom: 10px; }

[margin-horizontal-2] {
  margin-left: 10px;
  margin-right: 10px; }

[margin-top-2] {
  margin-top: 10px; }

[margin-right-2] {
  margin-right: 10px; }

[margin-bottom-2] {
  margin-bottom: 10px; }

[margin-left-2] {
  margin-left: 10px; }

html {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto; }

html,
body {
  height: 100%;
  font-family: "San Francisco"; }

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app,
.app__inner {
  height: 100%; }

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: white; }

.full-height, #main {
  min-height: calc(100vh - 80px - 64px); }

@media (min-width: 1200px) {
  #main {
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto; } }

body {
  font-size: 0.9rem; }

.container {
  margin-left: 3rem;
  margin: 0 auto;
  width: 100%;
  /*@media (min-width: 768px) {
    width: 90%;
  }*/ }
  .container--half {
    text-align: center;
    width: 50%; }
  .container--half-2 {
    text-align: center;
    width: 60%; }
  .container.med-container {
    width: 1140px; }
  .container.small-container {
    width: 750px; }

.link-more-right {
  float: right;
  text-decoration: none;
  padding: 1em;
  color: #2c2c2c !important;
  text-transform: uppercase; }
  .link-more-right:hover {
    cursor: pointer; }

.link-more {
  text-decoration: underline;
  padding-right: 0.5em; }

.main-title {
  order: 0;
  flex: 1 1 auto;
  font-size: 1.7em;
  line-height: 1.75rem;
  border-bottom: none;
  text-align: left;
  width: auto;
  white-space: normal;
  margin: 1rem 1rem 1rem 0;
  font-weight: 600; }
  .main-title .underline {
    background: #1e4acc;
    width: 25px;
    height: 2px;
    display: block;
    margin: 10px auto 0;
    margin-left: 0; }

.section {
  font-size: 0.7rem;
  color: #1d1d1f;
  padding: 2em 2em; }
  @media (min-width: 1200px) {
    .section {
      font-size: 0.8rem; } }
  .section-divider {
    border-top: 1px solid #c2d1d9;
    width: 100%;
    height: 1px; }
  .section h2 {
    font-size: 1.5em;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: left; }

h1 .divider,
h2 .divider,
h3 .divider,
h4 .divider {
  width: 50px;
  margin: 0.5em auto;
  border-bottom: 2px solid #1d1d1f; }
  h1 .divider--left,
  h2 .divider--left,
  h3 .divider--left,
  h4 .divider--left {
    margin-left: 0; }

[font-nunito],
.font-nunito {
  font-family: "San Francisco"; }

* {
  font-family: "San Francisco";
  max-width: 100vw;
  letter-spacing: 0; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

a:visited,
a:focus {
  color: inherit; }

*:focus {
  outline: 0; }

.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%; }
  .icon--mini, .icon--big {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    line-height: 1em;
    text-align: center;
    border-radius: 50%; }
  .icon--big {
    display: inline-block;
    width: 5em;
    height: 5em;
    min-width: 5em;
    min-height: 5em;
    line-height: 5em;
    text-align: center;
    border-radius: 50%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

.capitalize {
  text-transform: capitalize; }

.dropdown__switch:checked ~ .dropdown__body,
.dropdown--open ~ .dropdown__body {
  opacity: 1;
  height: auto; }

.dropdown__switch {
  visibility: hidden;
  opacity: 0;
  display: none; }

.dropdown__header {
  position: relative;
  z-index: 100;
  display: block; }
  .dropdown__header:hover {
    cursor: pointer; }

.dropdown__body {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden; }

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.radio {
  margin: 0.5rem; }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .radio input[type="radio"] + .radio-label:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: 0;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #424b5a;
      box-shadow: inset 0 0 0 4px #f4f4f4; }
    .radio input[type="radio"]:focus + .radio-label:before {
      outline: none;
      border-color: #424b5a; }
    .radio input[type="radio"]:disabled + .radio-label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: #b4b4b4;
      background: #b4b4b4; }
    .radio input[type="radio"] + .radio-label:empty:before {
      margin-right: 0; }

/*
    Read first https://jedwatson.github.io/react-select/
    To understand styling, https://react-select.com/styles#using-classnames
*/
.select--editable__control {
  color: #1d1d1f;
  border: 1px solid #c2d1d9 !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
  min-height: 39px !important; }

.select--editable__input input {
  height: 18px !important; }

.select--editable__value-container {
  padding: 0px 7px !important; }

.select--editable__menu-list {
  text-align: left; }

.menu {
  width: 100%; }
  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-bottom: 5px; }
    .menu__item > a {
      text-decoration: none; }

.search {
  padding: 5px;
  font-style: italic; }
  .search__text[type='text'] {
    margin-bottom: 0; }

button,
.button {
  max-width: 300px;
  display: inline-block;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  height: 38px;
  cursor: pointer; }

button--short,
.button--short {
  width: auto;
  padding: 2.5px 7.5px;
  text-transform: capitalize; }

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; }
  .button-group .button:first-child:last-child,
  .button-group button:first-child:last-child {
    margin-right: 0;
    margin-left: auto; }
  .button-group--double {
    justify-content: space-between; }
  .button-group button,
  .button-group .button {
    margin-right: 2.5px; }
  .button-group .button + .button,
  .button-group button + button {
    margin-right: 0;
    margin-left: 2.5px; }

.button-like {
  display: block;
  text-align: center;
  padding: 8px !important;
  text-decoration: none;
  text-transform: uppercase; }

.button--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1f;
  color: #ffffff;
  border: 1px solid #1d1d1f;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: capitalize !important;
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary.MuiSvgIcon-root {
    fill: #ffffff !important; }
  .button--primary:hover, .button--primary:focus {
    background: #0D35AB;
    border-color: #0D35AB; }
  .button--primary:disabled {
    background: lightgrey;
    border-color: lightgrey; }
  .button--primary-inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e4acc;
    color: #ffffff;
    border: 1px solid #1e4acc;
    border-radius: 4px;
    padding: 5px 10px;
    color: #1e4acc;
    background: #ffffff;
    border: 1px solid #1e4acc; }
    .button--primary-inverted > svg {
      fill: #1e4acc; }
    .button--primary-inverted:hover, .button--primary-inverted:focus {
      color: #ffffff;
      background: #0D35AB;
      border-color: #0D35AB; }
      .button--primary-inverted:hover > svg, .button--primary-inverted:focus > svg {
        fill: #ffffff !important; }
    .button--primary-inverted-rounded {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      width: 42px;
      background: #ffffff;
      color: #1e4acc;
      border: none;
      border-radius: 21px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px; }
      .button--primary-inverted-rounded img {
        fill: #1e4acc; }
      .button--primary-inverted-rounded:hover, .button--primary-inverted-rounded:focus {
        background: #0D35AB;
        color: #ffffff; }
        .button--primary-inverted-rounded:hover img, .button--primary-inverted-rounded:focus img {
          fill: #ffffff; }
      .button--primary-inverted-rounded span {
        padding: 0 0.3em; }
      @media (min-width: 768px) {
        .button--primary-inverted-rounded {
          width: auto; } }

.button--secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1f;
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  text-transform: capitalize !important; }
  .button--secondary--small {
    padding: 5px 20px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    background-color: #f1f1f1 !important; }
  .button--secondary:hover, .button--secondary:focus {
    color: #ffffff !important;
    background-color: #1e4acc !important; }

.button--primary--blue {
  background: #1e4acc;
  border-color: #1e4acc; }
  .button--primary--blue:disabled {
    background: lightgrey;
    border-color: lightgrey; }

.button-like.button--primary {
  background: #1e4acc;
  border-color: #1e4acc;
  color: #f8f8f8 !important; }
  .button-like.button--primary:hover, .button-like.button--primary:focus {
    background: #1d1d1f;
    border-color: #1d1d1f; }

.button--outline {
  background: transparent;
  color: #1d1d1f;
  border-radius: 4px;
  border: 1px solid #1d1d1f;
  text-transform: capitalize !important; }
  .button--outline:hover, .button--outline:focus {
    background: #f2f5f7; }

.button--transparent {
  background: transparent;
  color: #1d1d1f;
  border: none;
  text-transform: capitalize !important; }
  .button--transparent:hover, .button--transparent:focus {
    background: #f2f5f7; }
    .button--transparent:hover img, .button--transparent:focus img {
      color: #1d1d1f; }

.button--round {
  border-radius: 50%; }

.button--large {
  height: 52px; }

.button--medium {
  height: 39px; }

.button--small {
  padding: 0;
  height: 16px;
  font-size: 12px; }

.button--max {
  width: 100%;
  max-width: unset; }

.max-w-260 {
  max-width: 260px; }

.max-w-380 {
  max-width: 380px; }

.button--big {
  padding: 12px 16px !important;
  font-size: 18px !important;
  width: 100%;
  min-height: 56px;
  line-height: 24px; }

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="password"] {
  padding: 0 7.5px;
  color: #1d1d1f;
  background-color: #ffffff;
  border-radius: 0;
  height: 39px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em; }
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="time"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }
  input[type="text"][input--block],
  input[type="date"][input--block],
  input[type="time"][input--block],
  input[type="number"][input--block],
  input[type="email"][input--block],
  input[type="password"][input--block] {
    display: block;
    width: 100%; }
  input[type="text"].placeholder,
  input[type="date"].placeholder,
  input[type="time"].placeholder,
  input[type="number"].placeholder,
  input[type="email"].placeholder,
  input[type="password"].placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="time"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="time"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder {
    color: #c2d1d9; }
  input[type="text"]::placeholder,
  input[type="date"]::placeholder,
  input[type="time"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    color: #c2d1d9; }

textarea {
  margin-bottom: 5px;
  padding: 5px 7.5px;
  color: #1d1d1f;
  border: 1px solid #c2d1d9;
  width: 100%;
  box-sizing: border-box; }
  textarea.placeholder {
    color: #c2d1d9; }
  textarea:-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::-webkit-input-placeholder {
    color: #c2d1d9; }
  textarea::-ms-input-placeholder {
    color: #c2d1d9; }
  textarea::placeholder {
    color: #c2d1d9; }

.MuiTypography-body1 {
  font-size: 1em;
  font-family: "San Francisco";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em; }

.label-input {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; }
  .label-input--block {
    display: flex; }
  .label-input > span {
    order: 0;
    padding-bottom: 1.25px;
    font-weight: 700;
    font-size: 0.8em;
    font-family: "San Francisco"; }
  .label-input input[type="text"],
  .label-input input[type="date"],
  .label-input input[type="time"],
  .label-input input[type="number"],
  .label-input input[type="email"],
  .label-input input[type="password"],
  .label-input textarea {
    order: 1;
    padding-top: 2.5px; }
    .label-input input[type="text"]:focus ~ span,
    .label-input input[type="date"]:focus ~ span,
    .label-input input[type="time"]:focus ~ span,
    .label-input input[type="number"]:focus ~ span,
    .label-input input[type="email"]:focus ~ span,
    .label-input input[type="password"]:focus ~ span,
    .label-input textarea:focus ~ span {
      color: #1e4acc;
      color: var(--color-primary); }

.input-flat {
  border: 1px solid #c2d1d9; }

.input--icon {
  padding-left: 35px !important; }

.input--gps {
  background: url(/images/icons/atom/atoms-icons-system-location.svg) no-repeat scroll 7px 7px; }

.input--mail {
  background: url(/images/icons/atom/atoms-icons-system-mail.svg) no-repeat scroll 7px 7px; }

.spacer {
  border: none;
  border-top: 1px solid #c2d1d9;
  height: 2px;
  width: 100%;
  margin: 1.8em 0; }

.label-icon {
  display: flex; }
  .label-icon > *:first-child {
    margin-right: 5px; }

.label-error {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: red; }

.label-success {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: green; }

.label-info {
  font-size: .8em;
  line-height: 1.8;
  font-style: italic;
  margin: .1em .5em;
  color: blue; }

.subtitle {
  font-weight: 500 !important;
  border-bottom: 2px solid blue;
  width: 1.5em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding: 0 0 0.3em;
  margin: 0 auto 1em !important; }

.Twilio-ChatItem-Avatar > img {
  width: 24px !important;
  height: 24px !important; }

.Twilio-MainContainer {
  z-index: 200 !important; }

.Twilio-EntryPoint {
  z-index: 200 !important; }

.MessageCanvasTrayContent > {
  margin: 0;
  padding: 10px; }

.MessageCanvasTrayContent > h6 {
  padding-left: 10px;
  padding-right: 10px; }

.MessageCanvasTrayContent > p {
  margin: 0 !important;
  padding-left: 10px;
  padding-right: 10px; }

.MessageCanvasTrayContent > a {
  margin: 0;
  padding: 10px 10px 20px 10px;
  color: white; }

.MessageCanvasTrayContent > a:visited {
  color: white; }

.MessageCanvasTrayContent > a:hover {
  color: white; }

.MessageCanvasTrayContent > a:active {
  color: white; }

.Twilio-EntryPoint-Tagline {
  text-transform: capitalize !important; }

.Twilio-DynamicForm:first-child {
  font-size: 15px !important; }

.Twilio-DynamicForm label {
  font-size: 13px !important; }

.Twilio-DynamicForm input {
  font-size: 14px; }

