@import "../../../../../styles/main";

.step-delivery {
  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      font-size: .9em;
      font-weight: 500;
      border: 1px solid $color-primary;
      box-shadow: none;
      padding: 5px 10px;

      > svg {
        fill: $color-primary !important;
        margin-right: 0;
      }

      &:hover,
      &:focus {
        > svg {
          fill: $color-white-two !important;
        }
      }

      > span {
        display: none;
        font-size: 1em;
        margin: 0 .2em;

        @media #{$tabletDesktop} {
          display: inline-block;
          font-size: 1.125em;
        }
      }
    }
  }
}

.creditcard-skeleton {
  // border: 2px solid $color-light-blue-gray;
  border-radius: 5px;
}

.payment-methods {
  /*
  display: grid;
  grid-template-columns: 1fr;
  @media #{$tabletDesktop} {
    grid-template-columns: repeat(2, 1fr);
  }
  gap: 0.8em;
  */

  .payment-row {
    cursor: pointer;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    min-height: 48px;
    height: auto;
    background-color: white;

    padding: 0.5em 0 0.5em 1em;
    margin: 0.65em auto;
    position: relative;
    transition: opacity 300ms,
      width 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275), border-color 300ms,
      border-radius 300ms;
    border: 1px solid $color-light-blue-gray;
    border-radius: 5px;

    @media #{$tabletDesktop} {
      height: 58px;
      padding: 0;
      padding-left: 1em;
    }

    img {
      width: 48px;
      height: auto;
      &.card-icon {
        margin-right: 1em;
      }
      &:last-child {
        background-color: #fafafa;
        border-radius: 100px;
        fill: #fafafa;
        padding: 5px;
        transform: scale(0);
        transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
    }

    .button-link {
      display: flex;
      align-items: center;
    }

    &--active {
      border: 1px solid $color-primary;
      transition: opacity 300ms,
        width 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275), border-color 300ms,
        border-radius 300ms;

      img:last-child {
        fill: white;
        transform: scale(.5);
        width: 50px;
        background-color: $color-primary;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      flex-grow: 1;

      font-size: 15px;
      line-height: 1.35;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      
      @media #{$tabletDesktop} {
        font-size: 16px;
        line-height: 1.45;
      }

      & > *:last-child {
        font-size: 0.8em;
      }
    }
  }
}

.success-dialog {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding: 1em 0;
  }
  &__card {
    border: 1px solid lightgray;
    padding: 0.5em;
    border-radius: 5px;
    font-size: medium;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.mb-20 {
  margin-bottom: 20px;
}

.MuiDialog-paper {
  margin: 1em !important;

  &.MuiDialog-paperFullWidth,
  &.MuiDialog-paperScrollPaper {
    width: calc(100% - 1em);
  }
}

.MuiDialogTitle-root {
  right: 0;
  padding: 1.5em 1em 1em 1em !important;

  > h2 {
    > button {
      right: 0;

      @media #{$tabletDesktop} {
        right: 2em;
      }
    }
  }

  @media #{$tabletDesktop} {
    padding: 1.5em 2em 1em 2em !important;
  }

  > .MuiTypography-h6 {
    font-weight: 600;
  }
}

.makeStyles-dialog-8 {
  padding: 1em 1em 2em !important;

  @media #{$tabletDesktop} {
    padding: 1em 2em 2em !important;
  }
}

.MuiOutlinedInput-root fieldset {
  border-radius: 5px;
}