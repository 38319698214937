@import "../../../../../styles/main";

.offer-item {
  display: flex;
  width: 100%;
  height: 9rem;
  border: 1px solid $color-light-gray;
  border-radius: 8px;
  background-color: $color-white-two;
  cursor: pointer;
  margin: 0 auto;

  @media #{$tabletDesktop} {
    height: 9rem;
    width: 100;
  }

  .offer-image-box {
    text-align: center;
    overflow: hidden;
    display: flex;
    justify-content: center;
    height: 9rem;
    width: calc(35%);
    max-width: 9rem;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    position: relative;
    top: 0;
    left: 0;

    //* Estilos para ajustar tanto portrait como landscape images
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @media #{$tabletDesktop} {
      height: 9rem;
      width: 11rem;
    }

    img {
      height: 100%;
      width: auto;
    }

    .thumbnails-image {
      //* Estilos para ajustar tanto portrait como landscape images
      flex-shrink: 0;
      min-width: 100%;
      min-height: 100%;
    }

    .no-image {
      object-fit: cover;
      position: absolute;
    }
  }

  .offer-content {
    width: 100%;
    display: grid;
    grid-template-rows: 60% 40%;
    position: relative;
    top: 0;
    left: 0;

    .offer-detail {
      padding: 1em 1em 0 1em;
      text-align: left;
      display: grid;

      .offer-name {
        font-size: 1.2em;
        font-weight: $font-semi-bold;

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;
      }

      .offer-price {
        padding-top: 0.5em;
        font-size: 1em;
        color: $color-battleship-grey;
      }
    }

    .offer-actions {
      height: fit-content;
      padding: 0.5em 1em;
      text-align: right;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.shadow {
  border: none !important;
  //box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
}

.sold-out-offer {
  position: absolute;
}

.sold-out {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 4em;
  position: relative;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.5);
}

.hide {
  display: none;
}

.MuiDialogContent-dividers {
  padding: 0 !important;
  border-top: none !important;
  border-bottom: none !important;
}

.additions-dialog {
  // position: relative;
  // min-height: 100vh;
  .additions-header {
    font-size: $font-size-base;
    font-weight: $font-bold;
    text-align: center;
    align-items: center;
    color: $color-gunmetal;

    @media #{$tabletDesktop} {
      font-size: $font-size;
    }

    .additions-image {
      img {
        // height: 20em;
        max-width: 100%;
        width: 100%;
        max-height: 60vh;
        height: auto;
      }

      .close {
        position: absolute;
        top: 1%;
        right: 3%;
        padding: 0.5em;
        background: $color-light-gray;

        @media #{$desktop} {
          top: 2%;
          right: 4%;
        }

        @media #{$tablet} {
          top: 2%;
          right: 3%;
        }

        img {
          width: 16px;
        }
      }
    }

    .offer-name {
      margin: 0.5em 0 !important;
      color: $color-gunmetal;
    }

    .offer-description {
      margin: 0.5em 0 !important;
      color: $color-battleship-grey;
    }

    .offer-price {
      margin: 0 !important;
      color: $color-battleship-grey;
    }
  }

  .additions-section-title {
    background: #f5f5f5;

    h3 {
      padding: 0.8em 1em;
      margin: 0;
      color: $color-gunmetal;
    }
  }

  .additions-content {
    padding: 0 0.5em 1em 0.5em;

    .additions-items {
      margin: 0.2em 0;
      display: flex;
      align-items: center;
      padding: 0.2em 0.5em;
      border-bottom: 1px solid #eaeaea;
      background: $color-white-two !important;

      .info {
        min-height: 2.4em;
        align-items: center;

        .offer {
          color: $color-gunmetal;
          margin: 0 0.5em;
          text-transform: capitalize;
        }

        .count {
          color: $color-battleship-grey;
          margin-right: 0.5em;
        }
      }

      .actions {
        display: flex;
        align-items: center;
      }

      .check {
        color: $color-active;
      }

      .delete {
        padding-left: 0.5em;
        border-left: 1px solid #eaeaea;
        color: $color-cherry-red;
      }
    }
  }

  .offer-total-cost {
    padding: 1em;
    color: $color-gunmetal;
  }
}

.additions-footer {
  // position: sticky;
  // bottom: 0;
  padding: 0.5em;
  border-top: 1px solid $color-light-blue-gray;
  align-items: center;

  button {
    padding: 0.5em 3em;
  }
}
