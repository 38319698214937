@import "../../../../../styles/main";

.business-card-list {
  display: grid;
  grid-row-gap: 1em;
  row-gap: 1em;
  grid-template-columns: 1fr;
  grid-column-gap: 0;
  -webkit-column-gap: 0;
  column-gap: 0;

  @media #{$tabletDesktop} {
    grid-template-columns: repeat(2, calc(50% - .85em));
    grid-column-gap: 1em;
    -webkit-column-gap: 1em;
    column-gap: 1em;
    justify-content: center;
  }

  @media #{$desktop} {
    grid-template-columns: repeat(3, calc(33.33% - .85em));
  }
}