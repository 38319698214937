@import "../../../styles/main";

$max-width: 200px;
$max-width-2: $max-width * 1.5;
$height: 34px;
$height-2: 46px;

.bu {
  -webkit-transition: flex-grow 500ms;
  -moz-transition: flex-grow 500ms;
  transition: flex-grow 500ms;
}

.bt {
  max-width: $max-width;
  @media #{$tabletDesktop} {
    max-width: $max-width-2;
  }
}

.bs {
  -webkit-box-flex: 0;
  flex-grow: 0;
}

.af {
  position: relative;
}

.d8 {
  -webkit-box-flex: 1;
  flex-grow: 1;
}

.modal-select__container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  width: 100%;
  height: 370px;
  & > *:first-child {
    max-width: 300px;
  }
}

.button-address {
  display: flex;
  justify-content: left;
  align-items: center;
  border: none;
  font-size: 1.15em;
  &.btn-custom {
    background: #f6f6f6 !important;
    color: #1d1d1f;
    height: 46px;
    &:hover,
    &:focus,
    &.active {
      color: #1d1d1f;
    }
  }

  max-width: none !important;
  @media #{$tabletDesktop} {
    //$max-width-2;
  }

  height: $height;
  @media #{$tabletDesktop} {
    height: $height-2;
  }
  width: 100%;
  letter-spacing: normal !important;

  border-radius: 23px !important;

  background: transparent !important;
  padding: 0.35em 0.5em;
  @media #{$tabletDesktop} {
    padding: 0px 12px !important;
    background: #f6f6f6 !important;
  }

  img {
    margin-right: 10px;
  }

  input {
    height: 20px !important;
    width: 200px !important;
  }

  .input-container {
    display: flex;
    flex: 1;
  }

  div {
    display: flex;
    align-items: center;

    background: transparent;

    text-overflow: ellipsis;
    line-height: 24px;
    text-transform: none !important;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
  }
}
.MuiFilledInput-root {
  .button-address {
    background: #f6f6f6 !important;
    color: #1d1d1f;

    .css-6q0nyr-Svg {
      display: none;
    }
  }
}
