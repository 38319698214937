@import "../../../styles/main";

.cart-resume {
  font-size: 0.9rem;
  //border-top: 10px solid white;

  .cart-resume-title {
    display: grid;
    grid-template-columns: 88% 12%;
    align-items: center;
    background: $color-light-gray;

    h1 {
      display: block;
      padding: 0.6em;
      font-size: 1.3em;
      font-weight: $font-bold;
      color: $color-black;
      text-align: center;
      margin: 0;
    }

    .edit-purchase {
      width: 90%;
      height: 70%;
      padding-top: 0.6em;
      display: block;
      align-items: center;
      text-align: center;
      color: $color-gunmetal;
      border: 0.8px solid $color-light-blue-gray;

      &:hover {
        cursor: pointer;
        color: $color-white-two;
        background: $color-active;
      }
    }
  }

  &__container {
    padding: 0 0 1em 0;
    
    @media #{$tabletDesktop} {
      padding: 0 2.5em 1em 2.5em;
    }
    background: white;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 1em 0;

    span {
      vertical-align: text-bottom;
    }

    &__info {
      display: flex;
      flex-grow: 1;

      & > * {
        display: inline-block;
        margin-right: 0.5em;
      }

      &__count {
        color: $color-black;
        background-color: #f1f1f1;
        font-weight: $font-bold;
        margin-right: 0.5em;
        padding: 0px 8px;
        height: 23px;

        @media #{$tabletDesktop} {
          margin-right: 1em;
        }
      }

      .title {
        font-weight: $font-bold;
        font-size: 17px;
      }

      .details {
        font-size: 0.8em;
        font-weight: $font-extra-light;
        color: $color-light-gray-blue;

        @media #{$tabletDesktop} {
          font-size: 0.9em;
        }
      }
    }

    &__price {
      font-size: 17px;
    }
  }

  .subtotal,
  .cost,
  .total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 17px;
    margin: 0.5em 0;
  }

  .subtotal,
  .cost {
    span:first-child {
      font-size: 17px;
    }
  }

  .total {
    font-weight: $font-bold;
    padding-top: 0.5em;
    font-size: 17px;
  }

  .coupon {
    font-size: 1em;
    font-weight: $font-regular;
    margin: 1.8em 0;

    &-label {
      color: $color-black;
      font-weight: $font-bold;
    }

    .grid--small-form {
      margin: 0.5em 0;
      grid-column-gap: 1em;
      column-gap: 0.7em;

      @media #{$tabletDesktop} {
        grid-template-columns: 65% 30%;
      }
    }
  }
}

.checkout-order {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 1em;
  background-color: $color-white-two;
  box-shadow: 0 5px 15px 15px rgba(0, 0, 0, 0.1);

  & > button {
    position: relative;
    font-size: 1.2em;
    text-transform: none;

    @media #{$tablet} {
      width: 100% !important;
    }

    span {
      vertical-align: middle;
    }

    & > *:nth-child(1) {
      position: absolute;
      top: 6px;
      left: 10px;
      background: $color-fern-green;
      border-radius: 3px;
      padding: 4px 7px 0 7px;
    }

    & > *:nth-child(3) {
      position: absolute;
      top: 10px;
      right: 10px;
      font-weight: bold;
    }
  }
}

.checkout-order-dialog {
  &__header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: $color-white-two;
    box-shadow: 0 5px 15px 15px rgba(0, 0, 0, 0.1);

    @media #{$tablet} {
      box-shadow: none !important;
    }

    span {
      color: $color-black;
      font-weight: $font-bold;
    }

    &_close {
      position: absolute;
      right: 1em;
      line-height: 0;
      transform: translateX(30%);
    }
  }

  &__content {
    background: $color-white;
    padding: 1em !important;
    margin: 0 !important;

    @media #{$tablet} {
      padding: 1em 3em !important;
    }
  }

  &__actions {
    box-shadow: 0 5px 15px 15px rgba(0, 0, 0, 0.1) !important;
    background: $color-white;
    padding: 1em !important;
    flex-direction: column;

    @media #{$tablet} {
      padding: 1em 3em !important;
      box-shadow: none !important;
    }

    &_coupon {
      //margin: 0 1.5em;
      width: 100% !important;
      text-align: left !important;
      font-size: 1em;
      font-weight: $font-regular;
      grid-column-gap: 2em;
      grid-template-columns: 52% 30%;

      @media #{$tabletDesktop} {
        width: fit-content !important;
        margin: 0 2.5em;
        grid-template-columns: 70% 30%;
      }

      .coupon-label {
        color: $color-black;
        font-weight: $font-bold;
      }

      .grid--small-form {
        margin: 0.5em 0;
        grid-column-gap: 1em;
        column-gap: 0.7em;

        @media #{$tabletDesktop} {
          grid-template-columns: 70% 30%;
        }
      }
    }

    &_resume {
      width: 85% !important;
      display: grid;
      grid-template-columns: 10% 90%;
      grid-column-gap: 1em;

      @media #{$desktop} {
        width: 100% !important;
        grid-template-columns: 35% 57% 8%;
      }

      .subtotal,
      .cost,
      .total {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .subtotal,
      .cost {
        span:first-child {
          font-size: 1em;
        }
      }

      .total {
        font-weight: $font-bold;
        padding-top: 0.5em;
        font-size: 1.05em;
      }

      .spacer {
        margin: 1.5em 0 0.5em 0 !important;
      }
    }

    &_pay-button {
      margin: 1.5em !important;
      justify-content: center;
      text-align: center;
      display: flex;

      button {
        width: 70%;
        font-size: 1.2em;

        @media #{$tabletDesktop} {
          width: 60%;
          font-size: $font-size-base;
        }
      }

      a {
        width: 70%;

        @media #{$tabletDesktop} {
          width: 60%;
        }
      }
    }

    &_buttons {
      width: 100%;
      margin: 1em 0;
      display: flex;
      justify-content: center;
      gap: 0.8em;
      text-align: center !important;

      @media #{$tablet} {
        width: 75%;
        grid-template-columns: 1fr 1fr;
      }

      button {
        width: fit-content;
        padding: 1em;
      }
    }
  }
}

.checkout-stepper {
  width: 100% !important;
  background: $color-white !important;
  padding: 2em 1em !important;
  margin-top: 2em !important;

  @media #{$tabletDesktop} {
    background: $color-white-two !important;
  }
}

.steps {
  font-size: 0.9rem;
  padding: 1em;
  width: calc(100% - 2em);
  display: flex;
  justify-content: center;

  @media #{$desktop} {
    padding: 2em 1em;
    width: calc(100% - 2em);
  }

  > div {
    width: 100% !important;
  }

  &__header {
    margin-left: 1.5em;

    h1 {
      font-size: 1.2em;
      font-weight: $font-semi-bold;
      color: $color-gunmetal-two;
      margin-bottom: 0.6em;
    }

    .label-icon {
      font-size: 1em;
      color: $color-gunmetal-two;
    }

    .details {
      font-size: 1em;
      font-weight: $font-medium;
      color: $color-gunmetal-two;
      margin-right: 1em;
      margin-bottom: 0.3em !important;

      span {
        display: flex;
        align-items: center;

        img {
          width: 1.2em;
          margin-right: 0.5em;
        }
      }

      h3 {
        margin: 0;
        text-align: left;
        color: $color-light-blue-gray;
      }

      &_last {
        margin-bottom: 1em !important;
      }
    }
  }

  .step {
    border: none;
    //max-width: 700px;
    //min-width: 700px;
    width: 100%;
    //padding: 1em;

    @media #{$onlyMobile} {
      min-width: unset;
    }

    &__section {
      padding: 3% 0;
      border-bottom: 1px solid $color-light-blue-gray;

      h3 {
        font-size: 1em;
        font-weight: $font-semi-bold;
        color: $color-gunmetal-two;

        a {
          float: right;
          color: $color-sick-green;
          font-size: 0.8em;
        }
      }

      input {
        border-radius: 0.3em;
        border: 1px solid $color-light-blue-gray;
      }

      .textarea {
        border-radius: 0.3em;
      }

      .grid--form {
        padding: 1em 0 0;
      }

      .field-group {
        color: $color-battleship-grey;

        .label,
        .hint {
          font-size: 0.8em;
          line-height: 1.8;
          font-style: italic;
        }

        .hint_alert {
          font-size: 0.8em;
          line-height: 1.8;
          font-style: italic;

          &_success {
            color: $color-sick-green;
          }

          &_error {
            color: red;
          }
        }
      }
    }
  }
}

.button-wrapper {
  padding: 6% 25% 0;

  &--double {
    display: flex;
    justify-content: space-evenly;
    padding: 2% 6%;

    & > button {
      max-width: 50%;
    }
  }

  &--sticky {
    position: sticky;
    bottom: 0;
    background: white;
    box-shadow: 0 -5px 5px -5px #333;
    padding: 3em;
  }

  &--flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    max-width: unset;
    margin: auto;
    padding: 0;

    > button {

      &:nth-child(1) {
        width: 100%;
        margin-right: .35em;
      }

      &:nth-child(2) {
        width: 100%;
        margin-left: .35em;
      }
    }
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.braintree-heading:first-child {
  transform: none !important;
  visibility: visible !important;
  font-size: 1em;
  font-weight: $font-medium;
  color: $color-gunmetal-two;
}

.braintree-heading:nth-child(2) {
  font-size: 0.8em;
  font-weight: $font-medium;
  color: $color-sick-green;
  text-decoration: none;
}

.cart-resume-sidebar {
  width: calc(100% - 1em);
  border-radius: 12px;
  -webkit-box-shadow:inset 0px 0px 0px 1px $color-light-blue-gray;
  -moz-box-shadow:inset 0px 0px 0px 1px $color-light-blue-gray;
  box-shadow:inset 0px 0px 0px 1px $color-light-blue-gray;
  margin-top: 4.85em;

  .cart-resume {
    &__container {
      background: transparent;
      padding: 1em 1.5em 0 1.5em;
    }

    &__item {
      &__info {
        .title {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }
}
