@import "../../../../../styles/main";

.business-group-by-product-type {
  margin: 30px 0 50px;

  .hide {
    display: none;
  }

  .container-fluid {
    margin: 0 1em;

    @media #{$tablet} {
      padding-right: 0;
      padding-left: 0;
    }

    @media #{$desktop} {
      padding-right: 1.25em;
      padding-left: 1.25em;
      margin: 0;
      width: 100% !important;
    }

    .carousel-title-bar {
      display: flex;
      align-items: flex-end;
      margin: 1em 0;

      @media #{$desktop} {
        margin: 2em 0 1em 0;
      }

      .tag-container {
        display: flex;

        .restaurant-tag {
          margin: 1em .5em 0 0;

          img {
            height: 1.8em;
          }
        }
      }

      .restaurant-name {
        width: 100%;
        text-align: left;
        display: flex;
        align-items: baseline;

        h2 {
          //text-transform: uppercase;
          //font-size: 1.3em;
          font-size: 21px;
          font-weight: $font-bold;
          letter-spacing: -.005em;
          text-transform: none;
          margin: 0 !important;

          @media #{$tablet} {
            //font-size: 1.3em;
            font-size: 21px;
          }

          @media #{$desktop} {
            //font-size: 1.3em;
            font-size: 24px;
          }
        }


        .more-business {
          display: flex;
          align-items: center;
          font-weight: $font-medium;
          //text-transform: capitalize;
          color: $color-primary;
          margin: 0 1em;
          cursor: pointer;

          img {
            margin-right: 3px;
          }
        }

      }

      .restaurant-detail {
        display: flex;
        align-items: center;
        margin-bottom: 0.5em;
        grid-template-columns: 80% 20% !important;

        .restaurant-info {
          width: 100%;
          display: flex;
          font-size: 13px; //1em;

          @media #{$tabletDesktop} {
            display: flex;
            width: 100%;
          }
        }
      }

      .carousel-swiper-buttons {
        width: 100%;
        text-align: right;

        .swiper-button-next,
        .swiper-button-prev {
          display: none;
        }

        @media #{$desktop} {
          .swiper-button-prev,
          .swiper-button-next {
            display: inline-grid;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 50%;
            padding: 0.5em;
            color: $color-gunmetal;
          }
          .swiper-button-prev {
            margin-right: 0.5em;
          }
        }
      }
    }

    .swiper-container {
      gap: 12px;
      width: 100%;
      height: auto;
      padding-bottom: 1em;
      overflow: hidden;

      .swiper-wrapper {
        //margin: 1.5em;

        @media #{$desktop} {
          //margin: 0;
        }

        .swiper-slide {
          text-align: left;
          font-size: 18px;
          background: $color-white-two;
          width: 20%;
          height: auto !important;
          cursor: pointer !important;
          display: inline-block; /* flex; */
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          -webkit-justify-content: flex-start;
          justify-content: start;
          -webkit-box-align: start;
          -ms-flex-align: start;
          -webkit-align-items: flex-start;
          align-items: start;
          //box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
          //box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
          -webkit-box-shadow: 0 2px 6px 0 rgba(0,0,0,0.2);
          box-shadow: 0 2px 6px 0 rgba(0,0,0,0.2);
          border-radius: 8px;
          min-width: 277.5px;
          //margin-right: 30px;
          //margin-top: 1px;
          
          .card {
            &-image-container {
              height: 9em;
              display: flex;
              flex-direction: column;
              justify-content: center;
              box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

              img {
                width: auto;
                height: 9em;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
              }
            }

            &-details-container {
              margin: 0.5em 0;

              .label-title {
                display: flex;
                justify-content: space-between; //flex-start;
                align-items: center;

                h3 {
                  font-family: inherit;
                  //font-size: 0.8em;
                  font-size: 15px;
                  font-weight: $font-extra-bold;
                  line-height: 1em;
                  color: $color-black;
                  margin: 0.5em 0.6em;
                }

                .rating-star {
                  display: flex;
                  align-items: flex-start;
                  margin: 0 .5em;

                  span {
                    font-size: .8em;
                  }

                  img {
                    margin-left: .2em;
                    width: 1.1em;
                  }
                }
              }

              .label-content {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                font-family: inherit;
                //font-size: 0.8em;
                font-size: 13px;
                font-weight: 400;
                line-height: 1.6;
                color: #565656;
                margin: 0 0.8em 0.5em;

                .key {
                  font-family: inherit;
                  font-weight: $font-regular; //$font-semi-bold;
                  color: $color-battleship-grey;
                }

                .value {
                  font-family: inherit;
                  font-weight: $font-regular; //$font-extra-light;
                  color: $color-battleship-grey;
                  margin: 0 ;
                }

                .bar {
                  margin: 0 0.2em;
                }
              }
            }
            &:hover {
              &-details-container {
                .label-title {
                  h3 {
                    color: blue;
                  }  
                }
              }        
            }
          }
        }

        .swiper-slide.showAll {
          background: transparent !important;
          box-shadow: none !important;
          font-size: 16px;
          font-weight: $font-bold;
          color: $color-active;
          //width: 65%;
          text-align: left;

          .showAllLink {
            display: -webkit-flex; /* Safari */
            display: flex;
            -webkit-align-items: center; /* Safari 7.0+ */
            align-items: center;
            padding: 40% 20% 0;

            &:hover {
              cursor: pointer;
            }
          }

          svg {
            height: 1em;
            vertical-align: middle;
            display: inline-block;

            .icon {
              width: 24px;
              color: #2c2c2c;
              padding-top: 2px;
              padding-left: 10px;
              margin: 0 !important;
            }
          }
        }
      }
    }
  }
}