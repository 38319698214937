@import "styles/main.scss";

body {
  color: $color-black;
  font-size: 1.2rem;
  line-height: 1.6em;
  background: $color-white-two;
}

h1 {
  line-height: 1.3em;
  font-size: 2rem;
  color: $color-black;
}

p {
  margin-bottom: 1.3em;
}

#preloader {
  background: rgba(0, 0, 0, 0.5) !important;
}

.button-link {
  background: transparent;
  border: none;
  text-transform: inherit;
  line-height: 0;
  padding: 0;
  &:hover {
    color: $color-active;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
